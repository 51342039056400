import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./myOrder.css";
import { useLocation, useNavigate } from "react-router";
import { getAddress, getCart } from "../../Services/AllAPIs";
import { Backdrop, Box, CircularProgress, Radio } from "@mui/material";
import axios from "axios";
import { apis } from "../../Auth/Apis";
import { addAddress } from "../../Services/AllAPIs";
import { ArrowForwardIos } from "@mui/icons-material";

export default function CheckOutAddress(props) {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChangeToggle = (event) => {
    setSelectedAdd(event);
    setSelectedValue(event._id);
  };

  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [addressLen, setAddressLen] = useState();
  const [cartItems, setCartItems] = useState([]);
  const [selectedAdd, setSelectedAdd] = useState({});
  const [btnDisable, setBtnDisable] = useState(true);
  // const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const location = useLocation();
  const [houseNum, setHouseNum] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const [username, setUserName] = useState("");
  const [mobilNumber, setMobileNumber] = useState("");

  const cheackDetail = (e) => {
    if (
      houseNum?.length !== 0 &&
      city?.length !== 0 &&
      state?.length !== 0 &&
      pincode?.length !== 0 &&
      country?.length !== 0 &&
      username?.length !== 0 &&
      mobilNumber?.length !== 0
    ) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  };

  const handleSaveAddress = (e) => {
    // setLoader(true);
    e.preventDefault();
    const enteredPin = pincode.trim();
    const isValidPin = /^\d{6}$/.test(enteredPin);
    const isValidnumber = /^\d{10}$/.test(mobilNumber);
    if (isValidPin && isValidnumber) {
      addAddress({
        house_number: houseNum,
        city,
        state,
        pincode,
        country,
        name: username,
        mobile_number: mobilNumber,
      })
        .then((res) => {
          if (location.state === 1) {
            navigate("/checkOut");
          } else {
            navigate("/checkOut");
          }
          localStorage.setItem("userAddress", true);
          props.setUserAddress("true");
        })
        .catch((error) => console.log(error));
    } else {
      if (!isValidnumber) {
        setOpen(true);
        // alert("Please enter 10-digit mobile number");
      }
      if (!isValidPin) {
        setOpen1(true);
        //
        // alert("Please enter 6-digit pin code");
      }
    }
  };

  function getAddressList() {
    getAddress()
      .then((res) => {
        setAddresses(res.data);

        setAddressLen(res.data.length);
      })
      .catch((error) => console.log("error is", error));
  }

  async function buy() {
    var gg = 0;
    cartItems.map((res) => {
      gg += res.quantity * res.data[0].MRP;
    });

    let obj = {
      user: {
        userID: localStorage.getItem("userId"),
      },
      shippingDetails: {
        name: selectedAdd?.name,
        mobile_number: selectedAdd?.mobile_number,
        house_number: selectedAdd?.house_number,
        city: selectedAdd?.city,
        state: selectedAdd?.state,
        country: selectedAdd?.country,
        pincode: selectedAdd?.pincode,
      },
      orderDetails: {
        amount: gg * 100,
        currency: "INR",

        productArray: cartItems.map((res) => {
          return {
            product_id: res.product_id,
            quantity: res.quantity,
            date: new Date(),
          };
        }),
      },
    };

    try {
      let res = await axios.post(`${apis.baseUrl}payment/create-order`, obj);

      let amount = res.data.data.order.paymentDetails.amount;
      let order_id = res.data.data.order.paymentDetails.orderId;

      var options = {
        key: "rzp_test_ax5R0HtxrLmVyD",
        amount: amount,
        currency: "INR",
        name: "Kapoor Enterprise",
        description: "Kapoor Enterprise Transaction",
        order_id: order_id,
        callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
        handler: async function (response) {
          const data = {
            paymentId: response.razorpay_payment_id,
            paymentOrderId: response.razorpay_order_id,
            paymentSignature: response.razorpay_signature,
            user: {
              userID: localStorage.getItem("userId"),
            },
            paymentDetails: {
              ...res.data.data.order.paymentDetails,
              id: res.data.data.order._id,
            },
            subscriptionData: res.data.data.order.subscriptionData,
          };

          const result = await axios.post(
            `${apis.baseUrl}payment/verify`,
            data
          );

          const objj = {
            data: cartItems.map((res) => res.product_id),
          };

          const removefromCart = await axios.put(
            `${apis.baseUrl}user/removeToCart/${localStorage.getItem(
              "userId"
            )}`,
            objj
          );

          navigate("/");

          window.location.reload();
        },
        prefill: {
          name: "uxdlab",
          email: "uxdlab@gmail.com",
          contact: "23456787654",
        },
        notes: {
          address: "Razorpay Corporate office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (err) {
      console.log(err);
    }
  }

  const fetchCart = () => {
    getCart()
      .then((res) => {
        setCartItems(res.data);

        setLoader(false);
      })
      .catch((error) => console.log("error is", error));
  };

  useEffect(() => {
    props.setHeaderColor(false);
    getAddressList();
    window.scrollTo(0, 0);
    fetchCart();
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* main div */}
      {!loader ? (
        <div class=" mContainer container pb-5 ">
          {/* heading */}
          <span>
            <span
              onClick={() => navigate("/cart")}
              style={{ cursor: "pointer" }}
            >
              Cart
            </span>{" "}
            <ArrowForwardIos sx={{ fontSize: "16px" }} />
            <span className="">Checkout</span>
          </span>
          <div class=" pt-5 pb-3 checkout_heading">
            <h2>CHECKOUT</h2>
          </div>
          {/* flex div */}

          <form className="" onSubmit={handleSaveAddress} autocomplete="on">
            <div class="  row checkoutPage">
              {/* order summary div 1 */}
              <div class="order_summary col-lg-4  order-md-2 mb-4 ">
                <ul class="list-group mb-3 border border-dark">
                  <h5 class="d-flex justify-content-center align-items-center mt-3">
                    ORDER SUMMARY
                  </h5>
                  <div class="d-flex justify-content-center align-items-center px-4">
                    <hr class="w-100 my-2" />
                  </div>
                  {/* cart item details */}
                  {cartItems.map((item) => (
                    <li class="list-group-item d-flex justify-content-between px-2 ">
                      <div class="d-flex justify-content-center align-items-center">
                        <div class="d-flex justify-content-center align-items-center  rounded mx-3">
                          <img src={item?.data[0]?.image[0]}></img>
                        </div>
                        <div class="small_para">
                          <small class="my-0 d-block px-2 mb-2  small_para1">
                            {item?.data[0]?.product_name}
                          </small>
                          <small class="d-block px-2 mt-2 text-1">{`Quantity - ${item?.quantity}`}</small>
                        </div>
                      </div>
                      <div class="mx-3">
                        <h6>{`₹ ${item?.data[0]?.MRP * item?.quantity}`}</h6>
                      </div>
                    </li>
                  ))}

                  {/* <div class="d-flex justify-content-center align-items-center px-4">
                    <hr class="w-100 my-2" />
                  </div> */}

                  {/* <li class="list-group-item d-flex justify-content-between px-2">
                    <div class="mx-3">
                      <h6 class="mb-3">Subtotal</h6>
                    </div>
                    <div class="mx-3">
                      <h6 class="d-block mb-2">{`₹ ${localStorage.getItem(
                        "totalPrice"
                      )}`}</h6>
                    </div>
                  </li> */}
                  <div class="d-flex justify-content-center align-items-center px-4">
                    <hr class="w-100 my-2" />
                  </div>
                  <li class="list-group-item d-flex justify-content-between px-2 no-border">
                    <h5 class="mx-3">TOTAL</h5>
                    <h5 class="mx-3">
                      {" "}
                      {`₹ ${localStorage.getItem("totalPrice")}`}
                    </h5>
                  </li>
                  <div class="px-3 mb-4">
                    <button
                      class="btn w-100"
                      // disabled={selectedAdd.house_number == undefined}
                      disabled={btnDisable}
                      // onClick={(e) => {
                      //   if (addressLen !== 0) {
                      //     buy();
                      //   }
                      //   handleSaveAddress(e);
                      // }}
                      type="submit"
                    >
                      PLACE ORDER
                    </button>
                  </div>
                </ul>
              </div>

              <div class="col-lg-8 order-md-1  save_address">
                <div className="border border-dark rounded p-5">
                  {/* <div>
                  <h3>Saved Address</h3>
                </div> */}

                  {/* {addressLen === 0 ? (
                  <div className="pt-2">
                    <h5>No Address Yet!</h5>
                  </div>
                ) : (
                  ""
                )}
                */}
                  {/* 
                {addressLen !== 0 ? (
                  <>
                    {addresses.map((item) => (
                      <>
                        <div className=" w-200 ">
                          <div className="d-flex  pt-3 pb-2 ">
                            <div className=" my-auto ps-3">
                              <Radio
                                checked={selectedValue === item?._id}
                                onChange={() => handleChangeToggle(item)}
                                value={item?._id}
                                name="radio-buttons"
                              />
                            </div>
                            <div className=" ps-4">
                              <h6>{`${item?.house_number} ,${item?.city}`}</h6>
                              <h6>{`${item?.state} ,${item?.pincode}, ${item?.country} `}</h6>
                            </div>
                          </div>
                        </div>
                        <br></br>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )} */}

                  {/* <div className=" d-flex justify-content-xl-end justify-content-center  justify-content-md-end justify-content-sm-center justify-content-col-center  ">
                  <button
                    onClick={() => navigate("/new-address", { state: 1 })}
                    className="bttn"
                  >
                    ADD NEW ADDRESS
                  </button>
                </div> */}
                  {/* <form class="needs-validation mb-5" novalidate>
                  <div class="row bg-body d-flex justify-content-around">
                    <div class="col-md-5 mb-3">
                      <label for="inputName" class="mb-2">
                        Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputName"
                        placeholder="Enter User Name"
                        required
                      />
                      <div class="invalid-feedback">
                        Valid city name is required.
                      </div>
                    </div>
                    <div class="col-md-5 mb-3">
                      <label for="state" class="mb-2">
                        STATE
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="state"
                        placeholder="Enter the state name"
                        required
                      />
                      <div class="invalid-feedback">
                        Valid state name is required.
                      </div>
                    </div>
                  </div>
                  <div class="row bg-body d-flex justify-content-center">
                    <div class="col-md-11 mb-3">
                      <label for="delivery_address" class="mb-2">
                        DELIVERY ADDRESS
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="delivery_address"
                        placeholder="No, Street, District"
                        required
                      />
                      <div class="invalid-feedback">
                        Valid delivery address is required.
                      </div>
                    </div>
                  </div>

                  <div class="row bg-body d-flex justify-content-around">
                    <div class="col-md-5 mb-3">
                      <label for="city" class="mb-2">
                        CITY
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="city"
                        placeholder="Enter the city name"
                        required
                      />
                      <div class="invalid-feedback">
                        Valid city name is required.
                      </div>
                    </div>
                    <div class="col-md-5 mb-3">
                      <label for="state" class="mb-2">
                        STATE
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="state"
                        placeholder="Enter the state name"
                        required
                      />
                      <div class="invalid-feedback">
                        Valid state name is required.
                      </div>
                    </div>
                  </div>

                  <div class="row bg-body d-flex justify-content-around">
                    <div class="col-md-5 mb-3">
                      <label for="country" class="mb-2">
                        COUNTRY
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="country"
                        placeholder="Enter the country name"
                        required
                      />
                      <div class="invalid-feedback">
                        Valid country name is required.
                      </div>
                    </div>
                    <div class="col-md-5 mb-3">
                      <label for="pincode" class="mb-2">
                        PINCODE
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="pincode"
                        placeholder="Enter the area's 6 digit PIN code"
                        required
                      />
                      <div class="invalid-feedback">
                        Valid pincode is required.
                      </div>
                    </div>
                  </div>
                </form> */}

                  <div className="row mb-3">
                    <div class="form-group col-md-6 pe-md-3">
                      <label for="inputName" className="pb-1">
                        NAME
                      </label>
                      {/* <input
                        required
                        onChange={(e) => {
                          cheackDetail();
                          setUserName(e.target.value);
                        }}
                        value={username}
                        type="text"
                        className="form-control px-2 mb-2"
                        id="inputName"
                        placeholder="Enter User Name"
                        autocomplete="off"
                      /> */}
                      <input
                        type="text"
                        id="email"
                        // name="name"
                        autocomplete="off"
                        className="form-control px-2"
                        placeholder="Enter User Name"
                        onChange={(e) => {
                          cheackDetail();
                          setUserName(e.target.value);
                        }}
                        value={username}
                      ></input>
                    </div>
                    <div class="form-group col-md-6 pe-md-3">
                      <label for="inputNumber" className="pb-1">
                        Mobile Number
                      </label>
                      <input
                        required
                        type="number"
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 10))
                        }
                        value={mobilNumber}
                        onChange={(e) => {
                          cheackDetail();

                          setMobileNumber(e.target.value);
                          setOpen(false);
                        }}
                        className={
                          open
                            ? "form-control px-2 border border-danger"
                            : "form-control px-2"
                        }
                        id=""
                        placeholder="Enter Mobile Number"
                        autoComplete="new-password"
                      />
                      {open && (
                        <p className="text-danger fs-6">
                          {" "}
                          Please enter 10-digit mobile number
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label for="inputAddress" className="pb-1">
                      DELIVERY ADDRESS
                    </label>
                    <input
                      required
                      type="text"
                      value={houseNum}
                      onChange={(e) => {
                        cheackDetail();
                        setHouseNum(e.target.value);
                      }}
                      className="form-control px-2"
                      id="inputAddress"
                      placeholder="Enter Resident No, Street, District"
                      autoComplete="new-password6"
                    />
                  </div>
                  <div className="row mb-3">
                    <div class="form-group col-md-6 pe-md-3">
                      <label for="inputcity" className="pb-1">
                        CITY
                      </label>
                      <input
                        required
                        value={city}
                        onChange={(e) => {
                          cheackDetail();
                          setCity(e.target.value);
                        }}
                        type="text"
                        className="form-control px-2 mb-2"
                        id="inputcity"
                        placeholder="Enter City Name"
                        autoComplete="new-password4"
                      />
                    </div>
                    <div className="form-group col-md-6 ps-md-3">
                      <label for="inputState" className="pb-1">
                        STATE
                      </label>
                      <input
                        required
                        value={state}
                        onChange={(e) => {
                          cheackDetail();
                          setState(e.target.value);
                        }}
                        type="text"
                        className="form-control px-2"
                        id="inputState"
                        placeholder="Enter State Name"
                        autoComplete="new-password2"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="form-group col-md-6 pe-md-3">
                      <label for="inputCountry" className="pb-1">
                        COUNTRY
                      </label>
                      <input
                        required
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          cheackDetail();
                        }}
                        type="text"
                        className="form-control px-2 mb-2"
                        id="inputCountry"
                        placeholder="Enter Country"
                        autoComplete="new-password1"
                      />
                    </div>
                    <div className="form-group col-md-6 ps-md-3 mb-5">
                      <label for="inputPin" className="pb-1">
                        PIN CODE
                      </label>
                      <input
                        required
                        value={pincode}
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 6))
                        }
                        onChange={(e) => {
                          setPincode(e.target.value);
                          cheackDetail();
                        }}
                        type="number"
                        className={
                          open1
                            ? "form-control px-2 border border-danger"
                            : "form-control px-2"
                        }
                        id="inputPin"
                        placeholder="Enter PinCode"
                        autoComplete="new-password"
                      />
                      {open1 && (
                        <p className="text-danger fs-6">
                          {" "}
                          Please enter 6-digit pin code
                        </p>
                      )}

                      {/* <input
                        type="number"
                        id="email"
                        name="email"
                        autocomplete="off"
                        className="form-control px-2"
                        placeholder="Enter PinCode"
                        onChange={(e) => {
                          cheackDetail();
                          setPincode(e.target.value);
                        }}
                      ></input> */}
                    </div>
                  </div>
                  {/* <div
                            className={`d-flex justify-content-end ${styles.addAddressBts}`}
                          >
                            <div className="me-2">
                              <div className="">
                                <Link to="/address">
                                  {props.showButton ? (
                                    <button
                                      className={` ${styles.saveChanges2} px-3 `}
                                    >
                                      CANCEL
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </Link>
                              </div>
                            </div>
                            <div className="d-md-flex ms-2">
                              <div className="ps-md-3">
                                <button
                                  type="submit"
                                  className={` ${styles.saveChanges2} px-3 `}
                                >
                                  SAVE ADDRESS
                                </button>
                              </div>
                            </div>
                          </div> */}
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="customH"></div>
      )}
    </>
  );
}
