import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { editAddress, getAddress } from "../../Services/AllAPIs";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.css";

export default function CheckOutEditAddress(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  let address = useLocation();


  const [houseNum, setHouseNum] = useState(address.state.house_number);
  const [city, setCity] = useState(address.state.city);
  const [state, setState] = useState(address.state.state);
  const [pincode, setPincode] = useState(address.state.pincode);
  const [country, setCountry] = useState(address.state.country);
  const [addressId, setAddressId] = useState(address.state._id);
  const [username, setUserName] = useState(address.state.name);
  const [loader, setLoader] = useState(true);
  const [mobilNumber, setMobileNumber] = useState(address.state.mobile_number);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const handleEditAddress = (e) => {
    e.preventDefault();
    
    const enteredPin = pincode.trim();
    const isValidPin = /^\d{6}$/.test(enteredPin);
    const isValidnumber = /^\d{10}$/.test(mobilNumber);
    if ( isValidPin && isValidnumber) {
      editAddress({
        address_id: addressId,
        house_number: houseNum,
        city,
        state,
        pincode,
        country,
        name: username,
        mobile_number: mobilNumber,
      })
        .then((res) => {

          setLoader(false);
          navigate("/checkOut");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (!isValidnumber) {
        setOpen(true)
        // alert("Please enter 10-digit mobile number");

      } if (!isValidPin) {
        setOpen1(true)
        // 
        // alert("Please enter 6-digit pin code");
      }
    }
  };

  useEffect(() => {
    props.setHeaderColor(false);
    getAddress().then((res) => console.log);
  }, []);

  return (
    <>
      {/* <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
         
        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
      {loader ? (
        <div className=" fontFamily container-fluid cont ">
          <div className="row">
            <div className="col">
              <div className="px-5 py-4">
                <form className="m-5" onSubmit={handleEditAddress}>
                  <div className="row mb-3">
                    <div class="form-group col-md-6 pe-md-3">
                      <label for="inputName" className="pb-1">
                        NAME
                      </label>
                      <input
                        onChange={(e) => {
                          if (e.target.value === " ") {
                            e.target.value = "";
                          } else {
                            setUserName(e.target.value);
                          }
                        }}
                        type="text"
                        className="form-control px-2 mb-2"
                        id="inputName"
                        placeholder="Enter User Name"
                        defaultValue={address.state.name}
                        required
                      />
                    </div>
                    <div class="form-group col-md-6 pe-md-3">
                      <label for="inputNumber" className="pb-1">
                        Mobile Number
                      </label>
                      <input
                        required
                        type="number"
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 10))
                        }
                        onChange={(e) => {
                          setMobileNumber(e.target.value);
                          setOpen(false)
                        }}
                        className={open1 ? "form-control px-2 border border-danger" : "form-control px-2"}
                        id="inputNumber"
                        placeholder="Enter Mobile Number"
                        defaultValue={address.state.mobile_number}
                      />
                       {open && <p className="text-danger fs-6"> Please enter 10-digit mobile number</p>}

                    </div>
                  </div>
                  <div className="form-group mb-3 ">
                    <label for="inputAddress" className="pb-1">
                      DELIVERY ADDRESS
                    </label>
                    <input
                      required
                      type="text"
                      onChange={(e) => {
                        setHouseNum(e.target.value);
                      }}
                      className="form-control px-2"
                      id="inputAddress"
                      defaultValue={address.state.house_number}
                    />
                  </div>
                  <div className="row mb-3">
                    <div class="form-group col-md-6 pe-md-3">
                      <label for="inputcity" className="pb-1">
                        CITY
                      </label>
                      <input
                        required
                        onChange={(e) => setCity(e.target.value)}
                        type="text"
                        className="form-control px-2 mb-2"
                        id="inputcity"
                        defaultValue={address.state.city}
                      />
                    </div>
                    <div className="form-group col-md-6 ps-md-3">
                      <label for="inputState" className="pb-1">
                        STATE
                      </label>
                      <input
                        required
                        onChange={(e) => setState(e.target.value)}
                        type="text"
                        className="form-control px-2"
                        id="inputState"
                        defaultValue={address.state.state}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="form-group col-md-6 pe-md-3">
                      <label for="inputCountry" className="pb-1">
                        COUNTRY
                      </label>
                      <input
                        required
                        onChange={(e) => setCountry(e.target.value)}
                        type="text"
                        className="form-control px-2 mb-2"
                        id="inputCountry"
                        defaultValue={address.state.country}
                      />
                    </div>
                    <div className="form-group col-md-6 ps-md-3 mb-5">
                      <label for="inputPin" className="pb-1">
                        PIN CODE
                      </label>
                      <input
                        required
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 6))
                        }
                        onChange={(e) => {setPincode(e.target.value); setOpen1(false)}}
                        type="number"
                        className={open1 ? "form-control px-2 border border-danger" : "form-control px-2"}
                        id="inputPin"
                        defaultValue={address.state.pincode}
                      />
                       {open1 && <p className="text-danger fs-6">  Please enter 6-digit pin code</p>}

                    </div>
                  </div>
                  <div
                    className={`d-flex justify-content-end ${styles.editpagebtns}`}
                  >
                    <div className={`ms-2 ${styles.calcelbtn}`}>
                      <div className="">
                        {
                          <button type="button" onClick={()=>navigate("/checkOut")} className={` ${styles.saveChanges3} px-3 `}>
                            CANCEL
                          </button>
                        }
                      </div>
                    </div>
                    <div className=" ms-2">
                      <div className="">
                        <button
                          type=" submit"
                          className={` ${styles.saveChanges2} px-3`}
                          //  onClick={handleEditAddress}
                        >
                          SAVE ADDRESS
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
