import {
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import React, {Component} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { apis } from "../../Auth/Apis";
import "./Home.css";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axios from "axios";
import ProductAPI, { Context } from "../../Services/ProductAPI";
import { getAllPromos } from "../../Services/AllAPIs";
import Carousel from "react-bootstrap/Carousel";
import moment from "moment";
// import { Context } from "../../Services/ProductAPI";

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "grey",
        color: "",
        // padding: "0 10px 0 0",
      }}
      onClick={onClick}
    />
  );
}
export const Home = (props) => {
  sessionStorage.removeItem("filter");
  
  const [selectBrand, setSelectBrand] = useState([]);
  const [ss, setSs] = useState(0);
  const [aa, setAa] = useState(0);
  const [selectModel, setSelectModel] = useState([]);
  const [ModelId, setModelId] = useState("");
  const [allVehicle, setAllVehical] = useState([]);
  const [isbtnDisable, setIsbtnDisable] = useState(false);
  const [segmentId, setSegmentId] = useState("");
  const [stateDate, setStateData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [allModelId, setAllModelId] = useState([]);
  const [allPromo, setAllPromo] = useState([]);
  const [test, setTest] = useState("");
  const contextData = useContext(Context);
  const loginCheck = localStorage.getItem("IsLoggin");
  // const

  const homeData = [
    {
      link: "/brand",
      // img: "images/image22.png",
      // img: "images/2wheelerhome.png",
      img: "images/homeImage1.png",

      name: "2 Wheeler",
      description: "Pretium dictum est ac sed amet parturient.",
    },
    {
      link: "/brand",
      // img: "images/auto.png",
      img: "images/3wheelerhome1.png",
      name: "3 Wheeler",
      description: "Pretium dictum est ac sed amet parturient.",
    },
    {
      link: "/brand",
      // img: "images/car.png",
      img: "images/4wheelerhome1.png",
      name: "Car",
      description: "Pretium dictum est ac sed amet parturient.",
    },
    {
      link: "/brand",
      // img: "images/truck.png",
      img: "images/heavywheelerhome1.png",
      name: "Heavy Commercial Vehicles",
      description: "Pretium dictum est ac sed amet parturient.",
    },
    {
      link: "/brand",
      // img: "images/truck2.png",
      img: "images/lightwheelerhome7.jpg",
      name: "Light Commercial Vehicles",
      description: "Pretium dictum est ac sed amet parturient.",
    },
    {
      link: "/brand",
      // img: "images/trackter.png",
      img: "images/tractorhome1.png",
      name: "Tractor",
      description: "Pretium dictum est ac sed amet parturient.",
    },
    {
      link: "/brand",
      // img: "images/traffic.png",
      img: "images/otherhome1.png",
      name: "Others",
      description: "Pretium dictum est ac sed amet parturient.",
    },
  ];
  const homeData1 = [
    {
      link: "/brand",
      // img: "images/image22.png",
      // img: "images/2wheelerhome.png",
      img: "images/mobilehome1.png",

      name: "2 Wheeler",
      description: "Pretium dictum est ac sed amet parturient.",
    },
    {
      link: "/brand",
      // img: "images/auto.png",
      img: "images/mobilehome2.png",
      name: "3 Wheeler",
      description: "Pretium dictum est ac sed amet parturient.",
    },
    {
      link: "/brand",
      // img: "images/car.png",
      img: "images/mobilehome3.png",
      name: "Car",
      description: "Pretium dictum est ac sed amet parturient.",
    },
    {
      link: "/brand",
      // img: "images/truck.png",
      img: "images/mobilehome4.png",
      name: "Heavy Commercial Vehicles",
      description: "Pretium dictum est ac sed amet parturient.",
    },
    {
      link: "/brand",
      // img: "images/truck2.png",
      img: "images/mobilehome5.png",
      name: "Light Commercial Vehicles",
      description: "Pretium dictum est ac sed amet parturient.",
    },
    {
      link: "/brand",
      // img: "images/trackter.png",
      img: "images/mobilehome6.png",
      name: "Tractor",
      description: "Pretium dictum est ac sed amet parturient.",
    },
    {
      link: "/brand",
      // img: "images/traffic.png",
      img: "images/mobilehome7.png",
      name: "Others",
      description: "Pretium dictum est ac sed amet parturient.",
    },
  ];

  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:1100px)");
  const matches1 = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    sessionStorage.removeItem("search");
    window.scrollTo(0, 0);
    props.setHeaderColor(true);
    getAllVehicle();
    getAllPromo();
    contextData.getAllData();
    localStorage.removeItem("pop");
  }, []);

  const getAllVehicle = () => {
    axios
      .get(
        `${apis.baseUrl}vehicle/get/getAllVehicle`
      )
      .then((res) => {
        // console.log(res)
        setAllVehical(res.data.data);
        setLoader(false);
      })
      .catch((error) => console.log(error));
  };

  const getAllPromo = () => {
    axios
      .get(`${apis.baseUrl}promo/data/getPromoWeb`)
      .then((res) => {
        // console.log(res);
        setAllPromo(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function filterd(fil) {
    setSegmentId(fil);
    setSelectModel([]);
    setSelectBrand([]);
    setIsbtnDisable(false);
    setSelectBrand(
      contextData.allData.all.brandData.filter((item) =>
        item.segment_array.includes(fil)
      )
    );
    setSs(0);
    setAa(0);
    setTest("aa");
  }

  function filteredModel(fil) {
    setIsbtnDisable(false);
    setSelectModel([]);
    let arr = [];
    let data = contextData.allData.all.modelData.filter(
      (item) =>
        item.model_brand_array.includes(fil) &&
        item.model_segment_array.includes(segmentId)
    );
    data.forEach((res) => arr.push(res._id));
    // console.log(data)
    if (data.length !== 0) {
      setSelectModel([{ _id: arr, model_name: "All Models" }, ...data]);
    } else {
      setSelectModel([...data]);
    }
    let id = data.map((e) => e._id);
    setAllModelId(id);
    setAa(0);
    setTest("aa");
  }
  var settings = {
    dots: true,
    infinite: false,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    speed: 500,
    slidesToShow: 4,

    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 997,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1, // Update this value to 1
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* first image div */}
      <Grid
        container
        className="first_page"
        sx={{
          backgroundImage: `url("/images/image5.png")`,
        }}
      >
        <div className=" px-md-1 " style={{ width: "100%" }}>
          <div className="container px-md-5 pb-5">
            <Grid container className="filter_bg mb-5 pt-2 pb-4 px-4">
              <Typography
                className="res_text  py-2 text-Ivy "
                sx={{ textTransform: "capitalize" }}
              >
                Find the best spare part here
              </Typography>
              <Grid item md={11} xs={12}>
                <Grid container>
                  <Grid item md={4} xs={12} className="pr_4 pb_1 pb-sm-2">
                    <select
                      onChange={(e) => {
                        filterd(e.target.value.split(",")[0]);
                        setStateData([e.target.value.split(",")[1]]);
                      }}
                      className="select_style"
                    >
                      <option disabled selected className="option_style">
                        Select Segment Type
                      </option>
                      {contextData.allData.all?.segmentData.map(
                        (item, index) => (
                          <>
                            <option
                              className="option_style"
                              key={index}
                              value={item._id + "," + item.vehicle_name}
                            >
                              {item.vehicle_name}
                            </option>
                            <hr />
                          </>
                        )
                      )}
                    </select>
                  </Grid>
                  <Grid item md={4} xs={12} className="pr_4 pb_1 pb-sm-2">
                    <select
                      // onClick={()=>setSelectBrand(ss)}
                      onChange={(e) => {
                        filteredModel(e.target.value.split(",")[0]);
                        setSs(1);
                        setStateData([
                          stateDate[0],
                          e.target.value.split(",")[1],
                        ]);
                      }}
                      className="select_style"
                      placeholder="Select Company"
                      value={ss !== 1 ? "ss" : null}
                    >
                      <option
                        value="ss"
                        disabled
                        selected
                        className="option_style"
                      >
                        Select Brand
                      </option>

                      {selectBrand.map((brnd, index) => (
                        <>
                          <option
                            className="option_style"
                            value={brnd._id + "," + brnd.brand_name}
                            key={index}
                          >
                            {brnd.brand_name}
                          </option>
                          <hr />
                        </>
                      ))}
                    </select>
                  </Grid>
                  <Grid item md={4} xs={12} className="pr_4 pb_1 pb-sm-2">
                    <select
                      className="select_style"
                      onChange={(e) => {
                        setIsbtnDisable(true);
                        setTest(e.target.value);
                        // console.log(e.target.value);
                        let data = e.target.value.split(",");
                        // console.log(data)
                        if (data[data.length - 1] === "All Models") {
                          data.pop();
                          setModelId(data.join());
                          setAa(1);
                          // console.log('okok')
                        } else {
                          setModelId(data[0]);
                          setAa(1);
                          // console.log('nononono')
                          setStateData([stateDate[0], stateDate[1], data[1]]);
                        }
                      }}
                      // value={aa !== 1 ? "aa" : ""}
                      value={test}
                    >
                      <option
                        value="aa"
                        disabled
                        selected
                        className="option_style"
                      >
                        Select Model
                      </option>
                      {selectModel.length !== 0 && (
                        <>
                          {selectModel.map((mod, index) => (
                            <>
                              <option
                                className="option_style"
                                key={index}
                                value={mod._id + "," + mod.model_name}
                              >
                                {mod.model_name}
                              </option>
                              <hr />
                            </>
                          ))}
                        </>
                      )}
                    </select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1} xs={12} className="text-center">
                <Button
                  variant="contained"
                  disabled={!isbtnDisable}
                  onClick={() => {
                    localStorage.removeItem("pop");
                    navigate(`/products/${ModelId}`, { state: stateDate });
                  }}
                  className="search_btn"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </div>
          {/* <div className="text-center">
            <KeyboardDoubleArrowDownIcon
              sx={{ color: "white", height: "20px" }}
            />
          </div> */}
        </div>
        {/* <div className="mainImageSlider">
          <div className="imageSlider"></div>
        </div> */}
        {/* <div className="d-flex flex-column justify-content-between w-100 ">
          <div className="d-flex mt-5 pt-5 justify-content-between">
            <div className="w-50"></div>
            <div className={`top_text_box  w-50`}>
              <div>
                <p className="top_texts1"></p>
                <p className="top_texts2">
                 
                </p>
              </div>
              <div style={{ color: "white" }}>{contextData.allData.d}</div>
            </div>
          </div>
        </div> */}
      </Grid>
      {/* <div style={{ width: "100%", height: "80vh" }}>
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
          style={{ height: "40px" }}
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="d-block w-100"
                src="./images/image2.png"
                alt="First slide"
                style={{ height: "80vh", width: "100px" }}
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="./images/image3.png"
                alt="Second slide"
                style={{ height: "80vh", width: "100px" }}
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="./images/image4.png"
                alt="Third slide"
                style={{ height: "80vh", width: "100px" }}
              />
            </div>
            <a
              class="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div> */}
      <div className="container card">
        {/* <h2> Single Item</h2> */}

        <Slider {...settings}>
          {allPromo.map((item) => (
            <div>
              <div
                className="card"
                style={{
                  margin: "30px 10px 10px 10px",
                  background: "#f8f8f8",
                  border: "red",
                }}
              >
                {/* <img src="./images/image2.png" className="w-100" /> */}
                <div className="justify-content-center">
                  <div className="promoheader">
                    <div className="d-flex justify-content-center pt-3">
                      <h1>
                        <b className="promdiscount">
                          {item.discount_percentage}% OFF
                        </b>
                      </h1>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                      <h6 className="promodate px-2">
                        {moment(item.start_at).format("Do MMMM YYYY")}-
                        {moment(item.expire_at).format("Do MMMM YYYY")}
                      </h6>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-2 ">
                    <h5 className="promoname text-capitalize  cart-promo-texts  ">
                      {item.promo_name}
                    </h5>
                  </div>
                  <div className="d-flex justify-content-center mt-2">
                    <h5 className="promoCode">Promo Code {item.promo_code}</h5>
                  </div>

                  <div className="d-flex justify-content-center mt-2">
                    <h6 className="minimumorder">
                      Minimum Order Of &nbsp;{" "}
                      <spa className="promo-code-price">
                        {" "}
                        &#8377;{item.minimum_order}
                      </spa>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {/* <div className="bikeImage"></div> */}
      {matches1 ? (
        <>
          {allVehicle.map((item, index) => {
            return (
              <div
                style={{
                  backgroundImage: `url(${
                    index < 7 ? homeData[index].img : ""
                  })`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  // padding: "120px",
                  // height:"100vh",
                  // border:"2px solid red"
                  paddingTop: "150px",
                }}
              >
                <div key={index} className="w-100 vh-100 d-flex">
                  <div
                    style={{ width: index % 2 !== 0 ? "65%" : "10%" }}
                    className="img_text_con"
                  ></div>
                  <div
                    className="d-flex flex-column"
                    style={{ height: "100%" }}
                  >
                    {/* <div className="first_div"></div> */}
                    <div style={{}}>
                      <h4 className="h2_txt">
                        <b>{item.vehicle_name}</b>
                      </h4>
                      <>
                        <b className="p_txt">{item.vehicle_description}</b>
                      </>
                      <button
                        variant="subtitle2"
                        mt={2}
                        className="explore_btn"
                        onClick={() => {
                          // if (loginCheck === "false") {
                          //   localStorage.setItem("pop", 1)
                          //   window.location.reload()

                          // } else {
                          localStorage.removeItem("pop");

                          navigate(`/brand/${item._id}`);
                          // }
                        }}
                      >
                        {" "}
                        Explore Now{" "}
                        <KeyboardArrowRightIcon className="text-orang" />
                      </button>

                      <div className="home_data">
                        <div className="div_emoji">
                          <img
                            src="images/emoji1.png"
                            alt=""
                            className="emoji"
                          />
                        </div>
                        <div className="div_emoji">
                          <img
                            src="images/emoji2.png"
                            alt=""
                            className="emoji"
                          />
                        </div>
                        <div className="div_emoji">
                          <img
                            src="images/emoji3.png"
                            alt=""
                            className="emoji"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {index <= allVehicle.length - 2 ? (
                  <div className="text-center">
                    <KeyboardDoubleArrowDownIcon
                      sx={{ color: "#000000", height: "20px" }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </>
      ) : (
        ""
      )}
      {matches1 ? (
        ""
      ) : (
        <>
          {allVehicle.map((item, index) => {
            return (
              <div
                style={{
                  backgroundImage: `url(${
                    index < 7 ? homeData1[index].img : homeData1[0].img
                  })`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  paddingTop: "70px",
                  paddingBottom: "30px",
                }}
              >
                <div key={index} className="w-100 vh-20 d-flex">
                  <div
                    style={{ width: index % 2 !== 0 ? "65%" : "10%" }}
                    className="img_text_con"
                  ></div>
                  <div
                    className="d-flex flex-column"
                    // style={{ height: "100%" }}
                  >
                    {/* <div className="first_div"></div> */}
                    <div style={{ paddingLeft: "20px" }}>
                      <h6 className="h2_txt1">
                        <b>{item.vehicle_name}</b>
                      </h6>
                      <h6 className="p_txt1">{item.vehicle_description}</h6>
                      <button
                        variant="subtitle2"
                        mt={2}
                        className="explore_btn1"
                        onClick={() => {
                          // if (loginCheck === "false") {
                          //   localStorage.setItem("pop", 1)
                          //   window.location.reload()

                          // } else {
                          localStorage.removeItem("pop");

                          navigate(`/brand/${item._id}`);
                          // }
                        }}
                      >
                        {" "}
                        Explore Now{" "}
                        <KeyboardArrowRightIcon className="text-orang" />
                      </button>

                      <div className="home_data">
                        <div className="div_emoji1">
                          <img
                            src="images/emoji1.png"
                            alt=""
                            className="emoji1"
                          />
                        </div>
                        <div className="div_emoji1">
                          <img
                            src="images/emoji2.png"
                            alt=""
                            className="emoji1"
                          />
                        </div>
                        <div className="div_emoji1">
                          <img
                            src="images/emoji3.png"
                            alt=""
                            className="emoji1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {index <= allVehicle.length - 1 ? (
                  <div className="text-center">
                    <KeyboardDoubleArrowDownIcon
                      sx={{ color: "#000000", height: "20px" }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
