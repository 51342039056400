import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {
  Typography,
  Grid,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import styles from "./styles.module.css";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import useMediaQuery from "@mui/material/useMediaQuery";
import { contactus } from "../../Services/AllAPIs";
import BusinessIcon from "@mui/icons-material/Business";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
export const ContactUs = (props) => {
  const matches = useMediaQuery("(min-width:600px)");

  const breadcrumbs = [
    <Link
      key="1"
      underline="none"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography className="navigation" onClick={() => navigate("/")}>
        Home
      </Typography>
    </Link>,
    <Link
      underline="none"
      key="2"
      style={{ textDecoration: "none", cursor: "auto" }}
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      <Typography className="navigation">
        <b style={{ fontWeight: "500", color: "black" }}>Contact Us</b>
      </Typography>
    </Link>,
  ];

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messagePassed, setMessagePassed] = useState(false);
  const [nameError, setNameError] = useState();
  const [emailError, setEmailError] = useState();
  const [messageError, setMessageError] = useState(false);
  const [number, setNumber] = useState();
  const [numberError, setNumberError] = useState(false);
  const [loader, setLoader] = useState(false);
  console.log(messageError);
  function isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const ContcatUs = (e) => {
    e.preventDefault();
    setLoader(true);
  
    let data = {
      name,
      email,
      message,
      number,
    };
  

    const isInvalidNumber = !number || number.length < 10;

  
    if (
      name.trim() !== "" &&
      email.trim() !== "" &&
      message.trim() !== "" &&
      !isInvalidNumber &&
      !numberError &&
      isValidEmail(email)
    ) {
      contactus(data)
        .then((res) => {
          setLoader(false);
          setMessagePassed(true);
          setName("");
          setEmail("");
          setMessage("");
          setNumber("");
        })
        .catch(() => setLoader(false));
    } else {
      setNameError(name.trim() === "" ? "Please enter name" : "");
      setEmailError(email.trim() === "" ? "Please Enter email" : "");
      setNumberError(isInvalidNumber ? "Please enter a valid phone number" : "");
      setMessageError(message.trim() === "");
  
      setLoader(false);
    }
  
    setTimeout(() => {
      setMessagePassed(false);
    }, 3000);
  };
  
  
  

  useEffect(() => {
    props.setHeaderColor(false);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loader ? (
        <div className="container">
          <Grid className="">
            <Grid container mt={4}>
              <Grid item md={6}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {breadcrumbs}
                </Breadcrumbs>
              </Grid>
            </Grid>

            <Grid
              container
              className=""
              mt={4}
              mb={11}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item md={6} className="w-100">
                <Grid container>
                  <Grid item md={12}>
                    <p className="Aboutus">Contact us</p>
                  </Grid>
                </Grid>
                <Grid container mb={2}>
                  <Grid item md={12} className="w-100">
                    <Typography variant="body2">
                      <b>FULL NAME</b>
                    </Typography>
                    <TextField
                      required
                      type="text"
                      name="name"
                      value={name}
                      error={nameError}
                      id="outlined-basic"
                      label="Enter your name"
                      variant="outlined"
                      size="small"
                      className="w-100 mt-1"
                      // onChange={(e) => {
                      //   setName(e.target.value);
                      // }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.trim() === "") {
                          setNameError("Please enter name");
                        } else {
                          setNameError("");
                        }
                        setName(inputValue);
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item md={12} className="w-100">
                  <Typography variant="body2">
                    <b>Phone Number</b>
                  </Typography>
                  <TextField
                    required
                    type="number"
                    name="number"
                    value={number}
                    error={numberError}
                    id="outlined-basic"
                    label="Enter your Phone Number"
                    variant="outlined"
                    size="small"
                    className={`w-100 mt-1 `}
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      if (!/^\d*$/.test(inputValue)) {
                        return;
                      }

                      const truncatedValue = inputValue.slice(0, 10);

                      setNumber(truncatedValue);

                      setNumberError(
                        inputValue.length !== truncatedValue.length
                      );
                    }}
                  />
                </Grid>

                <Grid container mt={2}>
                  <Grid item md={12} className="w-100">
                    <Typography variant="body2">
                      <b>EMAIL ADDRESS</b>
                    </Typography>
                    <TextField
                      required
                      type="text"
                      name="email"
                      value={email}
                      error={emailError}
                      id="outlined-basic"
                      label="Enter your email address"
                      variant="outlined"
                      size="small"
                      className="w-100 mt-1"
                      // onChange={(e) => {
                      //   setEmail(e.target.value);
                      // }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.trim() === "") {
                          setEmailError("Please Enter email");
                        } else {
                          setEmailError("");
                        }
                        setEmail(inputValue);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container mt={2}>
                  <Grid item md={12} className="w-100">
                    <Typography variant="body2">
                      <b>MESSAGE</b>
                    </Typography>
                    <textarea
                      required
                      label="Please enter your message"
                      placeholder="Enter your message here..."
                      className={`textarea ${
                        messageError ? "border border-danger" : ""
                      }`}
                      name="massage"
                      value={message}
                      // error={}
                      // onChange={(e) => {
                      //   setMessage(e.target.value);
                      // }}
                      onChange={(e) => {
                        if (message === "") {
                          setMessageError(true);
                        } else {
                          setMessageError(false);
                        }
                        if (e.target.value === " ") {
                          e.target.value = "";
                        } else {
                          setMessage(e.target.value);
                        }
                      }}
                    ></textarea>
                  </Grid>
                </Grid>
                <Grid container mt={4}>
                  <Grid item md={12}>
                    <Button
                      variant="contained"
                      className="w-100 send-btn"
                      // type="submit"
                      onClick={ContcatUs}
                    >
                      SEND MESSAGE
                    </Button>
                  </Grid>
                </Grid>
                <div>
                  {messagePassed ? (
                    <span className="fs-12 " style={{ color: "green" }}>
                      <h5>Message has been sent</h5>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <Grid container mt={2}>
                  <Grid item md={12}>
                    <Typography>
                      <b>We will revert back to your message just in time.</b>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5} mt={5} className="imgdiv pt-sm-0 pt-2">
                <p mb={3}>
                  Give us a call or drop by anytime, we endeavour to answer all
                  inquiries within 24 hours on business days. We will be happy
                  to answer your questions.
                </p>

                <div className={styles.addressContainer}>
                  <div className={styles.addressMian}>
                    <span className={styles.icon}>
                      {" "}
                      <BusinessIcon fontSize="large"></BusinessIcon>{" "}
                    </span>
                    <div>Address: K J 63, Kavi Nagar Ghaziabad</div>
                  </div>
                  <div className={styles.addressMian}>
                    <span className={styles.icon}>
                      {" "}
                      <LocalPhoneIcon fontSize="large"></LocalPhoneIcon>{" "}
                    </span>
                    <div>
                      Landline No -0120-4134716 Mobile -9910103587, 9599951308
                    </div>
                  </div>
                  <div className={styles.addressMian}>
                    <span className={styles.icon}>
                      {" "}
                      <EmailIcon fontSize="large"></EmailIcon>{" "}
                    </span>
                    <div>Kapoorenterprises1864@gmail.com</div>
                  </div>

                  {/* <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src="./images/Group1.png"
                      alt="contactImage"
                      className={styles.imG}
                    />
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
