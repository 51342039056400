import React, { Component, ReactDOM } from 'react';
// import Button from 'react-bootstrap/Button';
import './Form.css'
class Otpinput extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: '', otp1: "", otp2: "", otp3: "", otp4: "", disable: true,checkNum:0 };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkNumber = 0
    }

    handleChange(value1, e) {
        const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
        this.setState({ [value1]: e.target.value });
        this.setState({checkNum:+(e.target.value)})
      }
            console.log(this.state)
        
    }

    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        // console.log(this.state);
        let otpV = `${this.state.otp1}${this.state.otp2}${this.state.otp3}${this.state.otp4}`
        // console.log(otpV)
        this.props.OtpValue(otpV)
        this.props.VerifyUserOTP(event, otpV)
    }

    inputfocus = (elmnt) => {
        const re = /^[0-9\b]+$/;
        elmnt.preventDefault()
        // console.log(elmnt.key)
        // alert(elmnt.key !== ' ')
        // console.log(elmnt.keyCode)
        if (elmnt.keyCode === "Delete" || elmnt.key === "Backspace" || elmnt.keyCode === 8) {
            const next = elmnt.target.tabIndex - 2;
            // alert(next)
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
                if (this.state.otp1 !== '' && this.state.otp2 !== '' && this.state.otp3 !== '' && this.state.otp4 !== '') {
                    this.setState({ disable: false })
                } else {
                    this.setState({ disable: true })
                }
            }else{
                if (this.state.otp1 !== '' && this.state.otp2 !== '' && this.state.otp3 !== '' && this.state.otp4 !== '') {
                    this.setState({ disable: false })
                } else {
                    this.setState({ disable: true })
                }
            }
        }
        else if (elmnt.key !== ' ' && re.test(this.state.checkNum) && elmnt.keyCode !== 8){
            // alert('okokok')
            const next = elmnt.target.tabIndex;
            if (next < 4) {
                elmnt.target.form.elements[next].focus()
                if (this.state.otp1 !== '' && this.state.otp2 !== '' && this.state.otp3 !== '' && this.state.otp4 !== '') {
                    this.setState({ disable: false })
                } else {
                    this.setState({ disable: true })
                }
            } else {
                if (this.state.otp1 !== '' && this.state.otp2 !== '' && this.state.otp3 !== '' && this.state.otp4 !== '') {
                    this.setState({ disable: false })
                } else {
                    this.setState({ disable: true })
                }
            }
        }

    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="otpContainer">

                    <input
                        name="otp1"
                        type="number"
                        autoComplete="off"
                        placeholder="__"
                        className="otpStyle form-control otp text-center t-bold"
                        value={this.state.otp1}
                        // onKeyPress={this.keyPressed}
                        onChange={e => {
                            if(e.target.value == ' '){
                                e.target.value = ''
                            }else{
                                if(e.target.value.length <= 1){
                                    this.handleChange("otp1", e)
                                }else{
                                    return false
                                }
                            }
                        }}
                        tabIndex="1" maxLength="1" onKeyUp={e => this.inputfocus(e)}

                    />
                    <input
                        name="otp2"
                        type="number"
                        autoComplete="off"
                        placeholder="__"
                        className="otpStyle form-control otp text-center t-bold"
                        value={this.state.otp2}
                        onChange={e => {
                            if(e.target.value == ' '){
                                e.target.value = ''
                            }else{
                                if(e.target.value.length <= 1){
                                    this.handleChange("otp2", e)
                                }else{
                                    return false
                                }
                            }
                        }}
                        tabIndex="2" maxLength="1" onKeyUp={e => this.inputfocus(e)}

                    />
                    <input
                        name="otp3"
                        type="number"
                        autoComplete="off"
                        placeholder="__"
                        className="otpStyle form-control otp text-center t-bold"
                        value={this.state.otp3}
                        onChange={e => {
                            if(e.target.value == ' '){
                                e.target.value = ''
                            }else{
                                if(e.target.value.length <= 1){
                                    this.handleChange("otp3", e)
                                }else{
                                    return false
                                }
                            }
                        }}
                        tabIndex="3" maxLength="1" onKeyUp={e => this.inputfocus(e)}

                    />
                    <input
                        name="otp4"
                        type="number"
                        autoComplete="off"
                        placeholder="__"
                        className="otpStyle form-control otp text-center t-bold"
                        value={this.state.otp4}
                        onChange={e => {
                            if(e.target.value == ' '){
                                e.target.value = ''
                            }else{
                                if(e.target.value.length <= 1){
                                    this.handleChange("otp4", e)
                                }else{
                                    return false
                                }
                            }
                        }}
                        tabIndex="4" maxLength="1" onKeyUp={e => this.inputfocus(e)}
                    />
                </div>
                <div class="my-3">
                    <button
                        type="submit"
                        class="btn w-100"
                        disabled={this.state.disable}
                    >
                        Sign Up
                    </button>
                </div>
            </form>
        );
    }
}


export default Otpinput;