import axios from 'axios'
import React, { Component, createContext } from 'react'
import { apis } from '../Auth/Apis'
export const Context = createContext()

export class ProductAPI extends Component {

    constructor() {
        super()
        this.state = {
            allData: {},
            getAllData: this.getAllData.bind(this)
        }
    }

    getAllData=async()=>{
        if(this.state.allData.all==undefined){

            try{
                console.log('api calling calling calling')
                let res=await axios.get(`${apis.baseUrl}${apis.getAllData}`)
                console.log(res.data.data)
                this.setState({...this.state,allData:{...this.state.allData,all:res.data.data}})
            }
            catch(err){
                console.log(err)
            }
        }
        
    }


    render() {

        return (
            <>
                <Context.Provider value={this.state}>{this.props.children}</Context.Provider>
            </>
        )
    }
}

export default ProductAPI
