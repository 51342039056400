import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Typography, Grid, Backdrop, CircularProgress } from "@mui/material";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router";
import { termCondition } from "../../Services/AllAPIs";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
export const TermCondition = (props) => {
  const [data, setData] = useState();
  const [loader, setLoader] = useState(true);

  const termAndCondition = () => {
    termCondition()
      .then((res) => {
        setLoader(false);
        // console.log(res.data.data);
        setData(res.data.data[0]);
      })
      .catch((error) => setLoader(false));
  };

  useEffect(() => {
    termAndCondition();
    window.scrollTo(0, 0);
    props.setHeaderColor(false);
  }, []);

  function convertIntoHtml(ht) {
    return { __html: ht };
  }

  const breadcrumbs = [
    <Link
      key="1"
      underline="none"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography className="navigation" onClick={() => navigate("/")}>
        Home
      </Typography>
    </Link>,
    <Link
      style={{ textDecoration: "none", cursor: "auto" }}
      underline="none"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      <Typography className="navigation">
        <b style={{ fontWeight: "500", color: "black" }}>
          Terms and Conditions
        </b>
      </Typography>
    </Link>,
  ];


  const navigate = useNavigate();
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loader ? (
        <Grid className="container">
          <Grid container mt={4}>
            <Grid item md={6}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid
            container
            className=""
            mt={3}
            mb={8}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item md={6} className="w-100">
              <p className="Aboutus mb-0">TERMS AND CONDITIONS</p>
              {/* <Typography mt={0} align="justify" className="txt">
                ABOUT US
              </Typography> */}
              <div dangerouslySetInnerHTML={convertIntoHtml(data?.term_condition)}></div>
            </Grid>
            <Grid item md={5} className="imgdiv" mt={8}>
              <img
                src="./images/termCondition.jpg"
                alt="aboutImage"
                className="aboutImg"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div className="customH"></div>
      )}
    </>
  );
};
