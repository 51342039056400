import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Typography, Grid, Backdrop, CircularProgress } from "@mui/material";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router";
import { Aboutus } from "../../Services/AllAPIs";


function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export const About = (props) => {
  const [data, setData] = useState();
  const [loader, setLoader] = useState(true);

  const aboutUS = () => {
    Aboutus()
      .then((res) => {
        // console.log(res.data.data);
        setData(res.data.data[0]);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const componentStyles = {
    hover: "red",
  };
  function convertIntoHtml(ht) {
    return { __html: ht };
  }

  useEffect(() => {
    aboutUS();
    window.scrollTo(0, 0);
    props.setHeaderColor(false);
  }, []);

  const breadcrumbs = [
    <Link
      key="1"
      underline="none"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography className="navigation" onClick={() => navigate("/")}>
        Home
      </Typography>
    </Link>,
    <Link
      underline="none"
      key="2"
      style={{ textDecoration: "none", cursor: "auto" }}
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      <Typography
        className="navigation "
        style={{ fontWeight: "500", color: "black" }}
      >
        <b className="links">About Us</b>
      </Typography>
    </Link>,
  ];

  const navigate = useNavigate();
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid className="" px={10}>
        <Grid container mt={4}>
          <Grid item md={6}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Grid container mt={3} mb={15}>
          <Grid item md={6} className="w-100">
            <p className="Aboutus">About us</p>
            <Typography mt={3} align="justify" dangerouslySetInnerHTML={convertIntoHtml(data?.about_us)} className="txt">

            </Typography>
          </Grid>
          <Grid item md={1}></Grid>
          <Grid item md={5} className="imgdiv" mt={5}>
            <img
              src="./images/aboutImg1.png"
              alt="aboutImage"
              className="aboutImg"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
