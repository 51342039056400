import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  Typography,
} from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Filter from "./Filter";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import style from "./style.module.css";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  applyFilt,
  getOnlyProducts,
  getSegmentBrandModel,
  getonlyCategoryManufacturerDatas,
  getAllVehicle,
  applyFilt2,
} from "../../Services/AllAPIs";
import { Context } from "../../Services/ProductAPI";
import { removeAllFilter } from "../../Redux-toolkit/Slices/ProductFilterSlice";

export default function Products(props) {
  window.onload = () => sessionStorage.removeItem("filter");

  const navigate = useNavigate();
  const location = useLocation();
  const contextData = useContext(Context);
  const [Products, setProducts] = useState(["data"]);
  const [allProduct, setAllProduct] = useState([]);
  const [sorry, setSorry] = useState(false);
  const [filterDataaa, setfilterDataaa] = useState([]);


  
  const [loader, setLoader] = useState(true);
  let reduxFilterData = useSelector((e) => {
    let data = e.ProductFilter;
    let products = {
      segment: allProduct[0]?.segment_name,
      brand: allProduct[0]?.brand_name,
      model: allProduct[0]?.model_name,
    };
    return { ...data, ...products };
  });
  const [categoryId, setCategoryId] = useState(reduxFilterData.categoryId);
  const [manufacturerId, setManufacturerId] = useState(
    reduxFilterData.manufacturerId
  );

  const [details, setDetails] = useState("a");
  const { id } = useParams();
  var ids = id.split(",");

  let productDataa =
    location.state !== null && location.state.array ? location.state.array : [];

  const [segmentData, setSegmentData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [test,setTest] = useState([])

  const [bread, setBread] = useState({
    segment: "",
    brand: "",
    model: "",
  });
  const [toggleIcon, setToggleIcon] = useState(false);

  const [filterData, setFilterData] = useState([]);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let dispatch = useDispatch();
  const getProducts = async () => {
    setLoader(true);

    const ids = id.split(",");
    if (id === "noData") {
    
       setProducts([]);
      setAllProduct([]);
      window.scrollTo(0, 0);
      props.setHeaderColor(false);
      setLoader(false);
    } else {
      await getOnlyProducts({ id: ids })
        .then((res) => {
          if (res.data) {
         
            let data = [];
            res.data.forEach((e) => {
              data = [...data, ...e];
            }); 
            if(productDataa.length!==0){

              data= data.filter(
                (item1) => productDataa.some((item2) => item1._id === item2._id)
               );
            }
            if(res.data.length ===0)setSorry(true)
          //  console.log(productDataa.length !== 0 ? productDataa : data)
            setProducts( data);
            setAllProduct(data);
            setfilterDataaa(data)
           

          }
          window.scrollTo(0, 0);
          props.setHeaderColor(false);
          setLoader(false);
        })
        .catch((error) => console.log(error));
     
    }
  };

  useEffect(() => {
    getSegmentBrandModel().then((res) => {
      setSegmentData(res.data.data.segmentData);
      setBrandData(res.data.data.brandData);
      setModelData(res.data.data.modelData);
    });
    // if (allProduct.length !== 0) {
    //   console.log({id:allProduct[0].product_model_aaray})
    //   getonlyCategoryManufacturerDatas(
    //     {id:allProduct[0].product_model_aaray}
    //   ).then((res) => {
    //     let a = res.data;
    //     a.brandName = a.brandName.toString();
    //     a.segmentName = a.segmentName.toString();
    //     a.model_name = a.model_name.toString();
    //     setDetails(a);
    //   });
    // }
  }, [allProduct]);

  useEffect(() => {
    setLoader(true);
    getProducts();

    // applyFilter(1, "preLoad");
  }, []);
 
  useEffect(() => {
    let fill = segmentData.map((e) => {
      let data = [];
      brandData.forEach((s) => {
        if (s.segment_array.indexOf(e._id) > -1) {
          let data2 = [];
          modelData.forEach((m) => {
            if (
              m.model_brand_array.indexOf(s._id) > -1 &&
              m.model_segment_array.indexOf(e._id) > -1
            ) {
              data2.push({ title: m.model_name, id: m._id });
            }
          });
          data.push({ title: s.brand_name, model: data2, mId: s._id });
        }
      });
      let result = {
        segment: e.vehicle_name,
        key: false,
        brand: data,
        sId: e._id,
      };
      return result;
    });

    setFilterData(fill);
  }, [modelData]);

  const applyFilter = (a, mode) => {
    let idss = allProduct.map((e) => e._id);
    setLoader(true);
    let data;
    if (a === 0) {
      data = {
        product_id: idss,
        category_id: [],
        manufacturer_id: [],
      };
      dispatch(removeAllFilter());
      setCategoryId([]);
      setManufacturerId([]);
    } else {
      let category_id = [];
      let manufacturer_id = [];

      if (mode == "preLoad") {
        category_id = [...reduxFilterData.categoryId];
        manufacturer_id = [...reduxFilterData.manufacturerId];
      } else {
        category_id = [...categoryId];
        manufacturer_id = [...manufacturerId];
      }

      data = {
        product_id: idss,
        category_id: category_id,
        manufacturer_id: manufacturer_id,
      };
    }

    applyFilt2(data)
      .then((res) => {
     
        if (res.data.length !== 0) {
          // let data = [];
          // if (ids.length > 1) {
            //   res.data.forEach((e) => {
          //     data = [...data, ...e];
          //   });
          // } else {
          //   data = res.data[0];
          // }

          setProducts(res.data);
       
          window.scrollTo(0, 0);
          props.setHeaderColor(false);
          
        } else {
          setProducts(allProduct);
          window.scrollTo(0, 0);
          props.setHeaderColor(false);
          
        }
        setLoader(false)
      })
      .catch((error) => console.log(error));
  };

  // const segmentDropDown = (e, id, ind) => {
  //   let div = document.getElementById("dropDiv")
  //   setFilterBrand(brandData.filter((item) => item.segment_array.includes(id)));
  //   if (flag) {
  //     // div.style.display = 'block';
  //     setFlag(false)
  //     e.currentTarget.children[1].style.transform = "rotate(180deg)";
  //     e.currentTarget.children[1].style.transition = "0.2s";
  //   }
  //   else {
  //     // div.style.display = 'none';
  //     setFlag(true)
  //     e.currentTarget.children[1].style.transform = "rotate(0deg)";
  //     e.currentTarget.children[1].style.transition = "0.2s";
  //   }

  // };

  const firstDropDown = (e, index, segment, brand, model, id) => {
    setBread({
      segment: segment ? segment : "",
      brand: brand ? brand : "",
      model: model ? model : "",
    });
    let data = [...filterData];
    data[index].key = !data[index].key;
    data.map((res, i) => {
      if (index !== i) {
        res.key = false;
      }
    });

    setFilterData([...data]);
    setToggleIcon(!toggleIcon);
    if (id !== undefined) {
      sessionStorage.removeItem("search");
      navigate(`/products/${id}`);
      window.location.reload();
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {
        !loader ? (
          <>
            {/* dropdown header */}
            <div className={`container-fluid p-0 m-0 ${style.top_filter1}`}>
              <div className={`${style.top_filter}`}>
                {/* <div className="container"> */}
                <ul className="d-flex m-0 p-0  justify-content-between">
                  {filterData.map((item, index) => (
                    <li
                      style={{ listStyle: "none", zIndex: "6" }}
                      className="me-4"
                      key={index}
                    >
                      <div
                        className={style.segment}
                        style={{
                          width: item.segment.length >= 20 ? "240px" : "140px",
                        }}
                        onClick={(e) => firstDropDown(e, index)}
                      >
                        {item.segment}
                        <KeyboardArrowUpIcon
                          sx={{
                            transform: item.key
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          }}
                        />
                      </div>
                      <div
                        className={`${style.sDrop} `}
                        style={{
                          width: item.segment.length >= 20 ? "240px" : "140px",
                        }}
                      >
                        {item.key
                          ? item.brand.map((res, i) => (
                              <Accordion
                                expanded={expanded === `panel${i + 1}`}
                                onChange={handleChange(`panel${i + 1}`)}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon
                                      className={`${style.model_text}`}
                                    />
                                  }
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                >
                                  <Typography
                                    className={`${style.model_text} pointer`}
                                    sx={{ width: "33%", flexShrink: 0 }}
                                  >
                                    {res.title}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {res.model
                                    ? res.model.map((res2, ind) => (
                                        <>
                                          <button
                                            className={`${style.model_text1} `}
                                            onClick={(e) => {
                                              firstDropDown(
                                                e,
                                                index,
                                                item.segment,
                                                res.title,
                                                res2.title,
                                                res2.id
                                              );
                                              setExpanded(false);
                                            }}
                                          >
                                            {res2.title}
                                          </button>
                                          <br />
                                        </>
                                      ))
                                    : ""}
                                </AccordionDetails>
                              </Accordion>
                            ))
                          : ""}
                      </div>
                    </li>
                  ))}
                </ul>
                {/* </div> */}
              </div>
            </div>
            <div className={`${style.hr}`}></div>

            {Products.length === 0  ? (
              <>
                <Box
                  style={{ minHeight: "70vh" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Box
                    sx={{
                      height: "fit-content",
                    }}
                  >
                    <Box class="d-flex justify-content-center pt-5 ">
                      <SentimentVeryDissatisfiedIcon
                        sx={{ fontSize: "160px", color: "#1b1b42" }}
                      />
                    </Box>
                    <Box class="d-flex justify-content-center">
                      {/* <ErrorOutlineIcon
                      sx={{ fontSize: "50px", color: "#1b1b42" }}
                    /> */}
                      <Box>
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "46px",
                            textAlign: "center",
                            fontWeight: "700",
                            color: "#2A2381",
                          }}
                        >
                          Sorry!
                        </Typography>
                      </Box>
                    </Box>

                    <Typography
                      // class={style.npfTextO}
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "28px",
                        textAlign: "center",
                        fontWeight: "600",
                        color: "#0E0E0E",
                      }}
                    >
                      No Product Found...
                    </Typography>
                    <Typography
                      // class={style.npfTextT}
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "24px",
                        textAlign: "center",
                        fontWeight: "300",
                        color: "#0E0E0E",
                      }}
                    >
                      You Can Try Our Different Product
                    </Typography>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <div className="container mt-4 pt-5 ">
                  <span className="">
                    <span
                      onClick={() => navigate("/")}
                      className={style.text_nav}
                    >
                      Home
                    </span>
                    <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />

                    {ids.length === 1 && (
                      <>
                        <span
                          onClick={() =>
                            navigate(
                              `/brand/${Products[0].product_segment_aaray[0]}`
                            )
                          }
                          className={style.text_nav}
                        >
                          {Products[0]?.segment_name}
                        </span>
                        <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
                        <span
                          onClick={() =>
                            navigate(
                              `/model/${Products[0].product_brand_aaray[0]}&&${Products[0].product_segment_aaray[0]}`
                            )
                          }
                          className={style.text_nav}
                        >
                          {Products[0]?.brand_name}
                        </span>
                        <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
                        <span className={style.text_nav}>
                          {Products[0]?.model_name}
                        </span>
                      </>
                    )}
                  </span>

                  <Grid container py={2}>
                    <span className="d-flex my-3">
                      <Typography variant="h5">
                        {productDataa.length === 0 ? (
                          <>
                            {ids.length === 1 ? (
                              <>
                                <b className={style.heading1}>
                                  {" "}
                                  {/* <b > */}
                                  {Products[0]?.brand_name.toUpperCase()} ({" "}
                                  {Products[0]?.model_name.toUpperCase()} )
                                  SPARE PARTS {/* </b> */}
                                </b>
                              </>
                            ) : (
                              <>
                                <b className={style.heading1}>
                                  {" "}
                                  {/* <b > */}
                                  {Products[0]?.brand_name.toUpperCase()} ({" "}
                                  {"All Model"} ) SPARE PARTS {/* </b> */}
                                </b>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <b className={style.heading1}>
                              {" "}
                              {/* <b > */}
                              {"SEARCH RESULTS"} SPARE PARTS {/* </b> */}
                            </b>
                          </>
                        )}

                        <b className=""> {Products.model_name}</b>
                      </Typography>{" "}
                      &nbsp;
                      <Typography
                        sx={{ marginTop: "4px", color: "black", width: "40%" }}
                      >
                        <small className="">({Products.length} Products)</small>
                      </Typography>
                    </span>
                    <Typography sx={{ textAlign: "justify", color: "black" }}>
                      {Products.model_description}
                    </Typography>
                  </Grid>
                  <Grid container spacing={1} py={3}>
                    <Grid item md={3} xs={12}>
                    
                      
                      <Filter
                        className=""
                        setCategoryId={setCategoryId}
                        setManufacturerId={setManufacturerId}
                        applyFilter={applyFilter}
                        categoryId={categoryId}
                        manufacturerId={manufacturerId}
                        product={allProduct}
                        filterDataaa={filterDataaa}
                       
                      />
                    
                    </Grid>
                    <Grid item md={9} sm={12} sx={12}>
                      <Grid container spacing={3}>
                        {Products.map((item, index) => {
                          return (
                            <Grid
                              item
                              lg={3}
                              md={4}
                              sm={6}
                              xs={6}
                              key={index}
                              // sx={{ border: "2px solid green" }}
                            >
                              {console.log(item)}
                              <div
                                className="card"
                                style={{
                                  width: "100%",
                                  padding: "5px 5px 0 5px",
                                  height: "100%",
                                  // border: "2px solid blue",
                                }}
                              >
                                <div
                                  className="border pb-3"
                                  // style={{ height: "100%" }}
                                >
                                  <img
                                    src={item.image[0]}
                                    style={{
                                      cursor: "pointer",
                                      // maxHeight: "160px",
                                      width: "100%",
                                      height: "20vh",
                                      // height: "",
                                      // width: "auto",
                                    }}
                                    onClick={() =>
                                      navigate(`/product/${item._id}`)
                                    }
                                    className="card-img-top"
                                    alt="..."
                                  />
                                </div>
                                <div className="pt-4 ">
                                  <h6
                                    className="card-title"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {item.product_name}
                                  </h6>
                                  <div>
                                    <b className="my-1">₹ {item.MRP}</b>
                                  </div>
                                </div>
                                {!item.is_active ? (
                                  <p className={style.outofstocktext}></p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
            {/* old code */}
            {/* <div className="container pt-3">
              <span>
                <span onClick={() => navigate("/")} className={style.text_nav}>
                  Home
                </span>
                <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
                <span
                  onClick={() =>
                    navigate(`/brand/${allProduct[0].product_segment_aaray[0]}`)
                  }
                  className={style.text_nav}
                >
                  {details.segmentName}
                </span>
                <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
                <span
                  onClick={() =>
                    navigate(
                      `/model/${allProduct[0].product_brand_aaray[0]}&&${allProduct[0].product_segment_aaray[0]}`
                    )
                  }
                  className={style.text_nav}
                >
                  {details.brandName}
                </span>
                <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
                <span className={style.text_nav}>{details.model_name}</span>
              </span>

              <Grid container py={2}>
                <span className="d-flex my-3">
                  <Typography variant="h5" className={style.heading}>
                    {details.brandName !== undefined ? (
                      <>
                        {" "}
                        <b>
                          {details.brandName.toUpperCase()} ({" "}
                          {details.model_name.toUpperCase()} ) SPARE PARTS{" "}
                        </b>
                      </>
                    ) : (
                      <></>
                    )}

                    <b> {Products.model_name}</b>
                  </Typography>{" "}
                  &nbsp;
                  <Typography sx={{ marginTop: "4px", color: "black" }}>
                    <small>({Products.length} Products)</small>
                  </Typography>
                </span>
                <Typography sx={{ textAlign: "justify", color: "black" }}>
                  {Products.model_description}
                </Typography>
              </Grid>
              <Grid container spacing={1} py={3}>
                <Grid item md={3} xs={12}>
                  <Filter
                    setCategoryId={setCategoryId}
                    setManufacturerId={setManufacturerId}
                    applyFilter={applyFilter}
                    categoryId={categoryId}
                    manufacturerId={manufacturerId}
                  />
                </Grid>
                <Grid item md={9} sm={12} sx={12}>
                  <Grid container spacing={3}>
                    {Products.map((item, index) => {
                      return (
                        <Grid
                          item
                          lg={3}
                          md={4}
                          sm={6}
                          xs={6}
                          key={index}
                          // sx={{ border: "2px solid green" }}
                        >
                          <div
                            className="card"
                            style={{
                              width: "100%",
                              padding: "5px 5px 0 5px",
                              height: "100%",
                              // border: "2px solid blue",
                            }}
                          >
                            <div
                              className="border pb-3"
                              // style={{ height: "100%" }}
                            >
                              <img
                                src={item.image[0]}
                                style={{
                                  cursor: "pointer",
                                  // maxHeight: "160px",
                                  width: "100%",
                                  height: "20vh",
                                  // height: "",
                                  // width: "auto",
                                }}
                                onClick={() => navigate(`/product/${item._id}`)}
                                className="card-img-top"
                                alt="..."
                              />
                            </div>
                            <div className="pt-4">
                              <h6 className="card-title">
                                {item.product_name}
                              </h6>
                              <div>
                                <b className="my-1">₹ {item.MRP}</b>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </div> */}
          </>
        ) : (
          <div className={style.customH1}></div>
        )
        // <div className={style.loader}>Loading...</div>
      }
    </>
  );
}
