import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { addAddress } from "../../Services/AllAPIs";
import { Backdrop, CircularProgress } from "@mui/material";

export default function CheckoutAnother(props) {
     const navigate = useNavigate();
     const [open, setOpen] = useState(false);
     const [open1, setOpen1] = useState(false);
    const location = useLocation();
 const [loader, setLoader] = useState(false);
    const [houseNum, setHouseNum] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincode] = useState();
    const [country, setCountry] = useState("");
    const [username, setUserName] = useState("");
    const [mobilNumber, setMobileNumber] = useState("");

     const handleSaveAddress = (e) => {
      setLoader(true);

       e.preventDefault();
       const enteredPin = pincode.trim();
       const isValidPin = /^\d{6}$/.test(enteredPin);
       const isValidnumber = /^\d{10}$/.test(mobilNumber);
       if ( isValidPin && isValidnumber) {
       addAddress({
         house_number: houseNum,
         city,
         state,
         pincode,
         country,
         name: username,
         mobile_number: mobilNumber,
       })
         .then((res) => {
  
              navigate("/checkOut");
        //    if (location.state === 1) {
        //      navigate("/checkOut");
        //    } else {
        //      navigate("/address");
        //    }
         })
         .catch((error) => console.log(error));
        }else{
      setLoader(false);

      if (!isValidnumber) {
        setOpen(true)
        // alert("Please enter 10-digit mobile number");

      } if (!isValidPin) {
        setOpen1(true)
        // 
        // alert("Please enter 6-digit pin code");
      }
        }
     };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        // onClick={handleClose}
      ></Backdrop>
      {!loader ? (
        <div className=" fontFamily container-fluid cont ">
          <div className="row">
            <div className="col">
              <div className="px-5 py-4">
                <form className="m-5" onSubmit={handleSaveAddress}>
                  <div className="row mb-3">
                    <div class="form-group col-md-6 pe-md-3">
                      <label for="inputName" className="pb-1">
                        NAME
                      </label>
                      <input
                        required
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                        type="text"
                        className="form-control px-2 mb-2"
                        id="inputName"
                        placeholder="Enter User Name"
                      />
                    </div>
                    <div class="form-group col-md-6 pe-md-3">
                      <label for="inputNumber" className="pb-1">
                        Mobile Number
                      </label>
                      <input
                        required
                        type="number"
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 10))
                        }
                        onChange={(e) => {
                          setMobileNumber(e.target.value);
                          setOpen(false);
                        }}
                        className={open ? "form-control px-2 border border-danger" : "form-control px-2"}
                        id="inputNumber"
                        placeholder="Enter Mobile Number"
                      />
                      {open && <p className="text-danger fs-6"> Please enter 10-digit mobile number</p>}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label for="inputAddress" className="pb-1">
                      DELIVERY ADDRESS
                    </label>
                    <input
                      required
                      type="text"
                      onChange={(e) => {
                        setHouseNum(e.target.value);
                      }}
                      className="form-control px-2"
                      id="inputAddress"
                      placeholder="Enter Resident No, Street, District"
                    />
                  </div>
                  <div className="row mb-3">
                    <div class="form-group col-md-6 pe-md-3">
                      <label for="inputcity" className="pb-1">
                        CITY
                      </label>
                      <input
                        required
                        onChange={(e) => setCity(e.target.value)}
                        type="text"
                        className="form-control px-2 mb-2"
                        id="inputcity"
                        placeholder="Enter City Name"
                      />
                    </div>
                    <div className="form-group col-md-6 ps-md-3">
                      <label for="inputState" className="pb-1">
                        STATE
                      </label>
                      <input
                        required
                        onChange={(e) => setState(e.target.value)}
                        type="text"
                        className="form-control px-2"
                        id="inputState"
                        placeholder="Enter State Name"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="form-group col-md-6 pe-md-3">
                      <label for="inputCountry" className="pb-1">
                        COUNTRY
                      </label>
                      <input
                        required
                        onChange={(e) => setCountry(e.target.value)}
                        type="text"
                        className="form-control px-2 mb-2"
                        id="inputCountry"
                        placeholder="Enter Country"
                      />
                    </div>
                    <div className="form-group col-md-6 ps-md-3 mb-5">
                      <label for="inputPin" className="pb-1">
                        PIN CODE
                      </label>
                      <input
                        required
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 6))
                        }
                        onChange={(e) => {setPincode(e.target.value); setOpen1(false)}}
                        type="number"
                        className={open1 ? "form-control px-2 border border-danger" : "form-control px-2"}
                        id="inputPin"
                        placeholder="Enter PinCode"
                      />
                       {open1 && <p className="text-danger fs-6"> Please enter 6-digit pin code</p>}
                    </div>
                  </div>
                  <div
                    className={`d-flex justify-content-end ${styles.addAddressBts}`}
                  >
                    <div className="me-2">
                      <div className="">
                        <Link to="/address">
                          {props.showButton ? (
                            <button type="button" className={` ${styles.saveChanges2} px-3 `}>
                              CANCEL
                            </button>
                          ) : (
                            ""
                          )}
                        </Link>
                      </div>
                    </div>
                    <div className="d-md-flex ms-2">
                      <div className="ps-md-3">
                        <button
                          type="submit"
                          className={` ${styles.saveChanges2} px-3 `}
                        >
                          SAVE ADDRESS
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="customH"></div>
      )}
    </>
  );
}
