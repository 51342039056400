import { useRef } from "react"
import { Navigate, Outlet } from "react-router"

export const CheckLoginAuth = () => {

    let isActive = localStorage.getItem('IsLoggin')
    let id = localStorage.getItem('userId')
    let isUserLoggedIn = useRef()


    if (isActive == 'true' && id) {
        isUserLoggedIn.current = true
    }
    else {
        isUserLoggedIn.current = false
    }

    return (
        <>
            {isUserLoggedIn.current ?
                <Outlet /> :
                <Navigate to='/' />}
        </>
    )
}