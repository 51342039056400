import React, { useEffect, useState } from "react";
import "./Newaddress.css";
import Sidebar from "../Sidebarr/Sidebar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { addAddress } from "../../Services/AllAPIs";
import styles from "./Address.module.css";
import { Backdrop, CircularProgress } from "@mui/material";

const Newaddress = (props) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const obj = {
    bg: "#f1f1f1",
    text: "#2A2381",
  };
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [houseNum, setHouseNum] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState();
  const [country, setCountry] = useState("");
  const [username, setUserName] = useState("");
  const [mobilNumber, setMobileNumber] = useState("");
  const [userAdd, setUserAdd] = useState(props.userAddress);
  const [formSubmitted, setFormSubmitted] = useState(false);
  // const [pincodes, setPincodes] = useState();

  // console.log(localStorage.getItem("adssLen"), "adssLenadssLen");

  const handleSaveAddress = (e) => {
    // setLoader(true);
    const enteredPin = pincode.trim();
    const isValidPin = /^\d{6}$/.test(enteredPin);
    const isValidnumber = /^\d{10}$/.test(mobilNumber);

    e.preventDefault();
    if (isValidPin && isValidnumber) {
      addAddress({
        house_number: houseNum,
        city,
        state,
        pincode,
        country,
        name: username,
        mobile_number: mobilNumber,
      })
        .then((res) => {
          // console.log("res from add new address", res);
          if (location.state === 1) {
            navigate("/checkOut");
          } else {
            navigate("/address");
          }
          setLoader(false);
          props.setUserAddress("true");
          localStorage.setItem("userAddress", true);
        })
        .catch((error) => setLoader(false));
    } else {
      if (!isValidnumber) {
        setOpen(true);
        // alert("Please enter 10-digit mobile number");
      }
      if (!isValidPin) {
        setOpen1(true);
        //
        // alert("Please enter 6-digit pin code");
      }
    }
  };

  useEffect(() => {
    props.setHeaderColor(false);
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Sidebar two={obj} userAddress={userAdd}>
        <div className=" fontFamily container-fluid cont">
          <div className="row">
            <div className="col">
              <div className="px-md-5 px-2 py-4">
                <form className="" onSubmit={handleSaveAddress}>
                  <div className="row mb-3">
                    <div class="form-group col-md-6 pe-md-3">
                      <label for="inputName" className="pb-1">
                        NAME
                      </label>
                      <input
                        required
                        onChange={(e) => {
                          if (e.target.value == " ") {
                            e.target.value = "";
                          } else {
                            setUserName(e.target.value);
                          }
                        }}
                        type="text"
                        className="form-control px-2 mb-2"
                        id="inputName"
                        placeholder="Enter User Name"
                      />
                    </div>
                    <div class="form-group col-md-6 pe-md-3">
                      <label for="inputNumber" className="pb-1">
                        MOBILE NUMBER
                      </label>
                      <input
                        required
                        type="number"
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 10))
                        }
                        onChange={(e) => {
                          setOpen(false);

                          if (e.target.value == " ") {
                            e.target.value = "";
                          } else {
                            setMobileNumber(e.target.value);
                          }
                        }}
                        className={
                          open
                            ? "form-control px-2 border border-danger"
                            : "form-control px-2"
                        }
                        id="inputNumber"
                        placeholder="Enter Mobile Number"
                      />
                      {open && (
                        <p className="text-danger fs-6">
                          {" "}
                          Please enter 10-digit mobile number
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label for="inputAddress" className="pb-1">
                      DELIVERY ADDRESS
                    </label>
                    <input
                      required
                      type="text"
                      onChange={(e) => {
                        if (e.target.value == " ") {
                          e.target.value = "";
                        } else {
                          setHouseNum(e.target.value);
                        }
                      }}
                      className="form-control px-2"
                      id="inputAddress"
                      placeholder="Enter Resident No, Street, District"
                    />
                  </div>
                  <div className="row mb-3">
                    <div class="form-group col-md-6 pe-md-3">
                      <label for="inputcity" className="pb-1">
                        CITY
                      </label>
                      <input
                        required
                        onChange={(e) => {
                          if (e.target.value == " ") {
                            e.target.value = "";
                          } else {
                            setCity(e.target.value);
                          }
                        }}
                        type="text"
                        className="form-control px-2 mb-2"
                        id="inputcity"
                        placeholder="Enter City Name"
                      />
                    </div>
                    <div className="form-group col-md-6 ps-md-3">
                      <label for="inputState" className="pb-1">
                        STATE
                      </label>
                      <input
                        required
                        onChange={(e) => {
                          if (e.target.value == " ") {
                            e.target.value = "";
                          } else {
                            setState(e.target.value);
                          }
                        }}
                        type="text"
                        className="form-control px-2"
                        id="inputState"
                        placeholder="Enter State Name"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="form-group col-md-6 pe-md-3">
                      <label for="inputCountry" className="pb-1">
                        COUNTRY
                      </label>
                      <input
                        required
                        onChange={(e) => {
                          if (e.target.value == " ") {
                            e.target.value = "";
                          } else {
                            setCountry(e.target.value);
                          }
                        }}
                        type="text"
                        className="form-control px-2 mb-2"
                        id="inputCountry"
                        placeholder="Enter Country"
                      />
                    </div>
                    <div className="form-group col-md-6 ps-md-3 mb-5">
                      <label for="inputPin" className="pb-1">
                        PIN CODE
                      </label>
                      <input
                        required
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 6))
                        }
                        onChange={(e) => {
                          setOpen1(false);
                          if (e.target.value == " ") {
                            e.target.value = "";
                          } else {
                            setPincode(e.target.value);
                          }
                        }}
                        type="number"
                        className={
                          open1
                            ? "form-control px-2 border border-danger"
                            : "form-control px-2"
                        }
                        // id="inputPin"
                        placeholder="Enter PinCode"
                      />
                      {open1 && (
                        <p className="text-danger fs-6">
                          {" "}
                          Please enter 6-digit pin code
                        </p>
                      )}
                    </div>
                  </div>

                  {/* <div
                    className={`row w-100 `}
                  >
                    <div className="col-md-7 col-sm-5 col-0"></div>
                    <div className="col-md-2 col-sm-3 d-flex justify-content-start justify-content-md-end justify-content-sm-end p-0 col-5">
                      <div className="">
                        <Link to="/address"> */}
                  {/* {localStorage.getItem("adssLen") &&
                            localStorage.getItem("adssLen") > "0" ? ( */}
                  {/* <button className={` ${styles.saveChanges2} px-2 `}>
                            CANCEL
                          </button> */}
                  {/* ) : (
                            ""
                          )} */}
                  {/* </Link>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4  d-flex justify-content-end p-0 col-7 border border-danger">
                      <div className="">
                        <button
                          type="submit"
                          className={` ${styles.saveChanges2}  `}
                        >
                          SAVE ADDRESS
                        </button>
                      </div>
                    </div>
                  </div> */}

                  <div className="d-flex justify-content-end">
                    <div className="me-2">
                      <Link to="/address">
                        {localStorage.getItem("adssLen") &&
                        localStorage.getItem("adssLen") > "0" ? (
                          <button
                            type="button"
                            className={` ${styles.saveChanges2} px-2 me-3`}
                          >
                            CANCEL
                          </button>
                        ) : (
                          ""
                        )}
                      </Link>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className={` ${styles.saveChanges2}  `}
                      >
                        SAVE ADDRESS
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default Newaddress;
