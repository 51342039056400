import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import style from "./style.module.css";
import { useNavigate, useParams } from "react-router";
import { getSegmentModels } from "../../Services/AllAPIs";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

export default function Model(props) {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [model, setModel] = useState([]);
  const [brandname, setBrandname] = useState([]);
  const [tag, setTag] = useState({});

  const { id } = useParams();

  const getModels = () => {
    getSegmentModels(id.split("&&"))
      .then((res) => {
        if (res.data.data.length !== 0) {
          console.log(res);
          setModel(res.data.data[0].Models);
          setBrandname(res.data.data[0]);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    getModels();
    setTag(JSON.parse(localStorage.getItem("data")));
    window.scrollTo(0, 0);
    props.setHeaderColor(false);
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loader ? (
        <div className="container pt-3 pb-5 " style={{ minHeight: '70vh' }}>
          <span>
            {" "}
            <span className={style.text_nav} onClick={() => navigate("/")}>
              Home
            </span>{" "}
            <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />{" "}
            <span
              className={style.text_nav}
              onClick={() => {
                if (model.length !== 0) {
                  navigate(`/brand/${model[0]?.model_segment_array[0]}`);
                } else {
                  navigate(`/brand/${tag.sId}`);
                }
              }}
            >
              {brandname.SegmentName ? brandname.SegmentName : tag.segment}
            </span>{" "}
            <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
            <span className={style.top_breadum}>
              {brandname.brand_name ? brandname.brand_name : tag.brand}{" "}
            </span>
          </span>
          {model.length !== 0 ? (
            <>
              <Grid container py={2}>
                <span className="d-flex my-3">
                  <Typography variant="h5">
                    <b className={style.heading1}>
                      SELECT YOUR {brandname.SegmentName?.toUpperCase()} MODEL
                    </b>
                  </Typography>
                </span>
              </Grid>

              <Grid container spacing={3}>
                {model.map((item, index) => {
                  return (
                    <Grid
                      item
                      md={3}
                      sm={4}
                      xs={6}
                      key={index}
                      onClick={() =>
                        navigate(`/products/${item._id}`, {
                          state: [
                            brandname.SegmentName,
                            brandname.brand_name,
                            item.model_name,
                          ],
                        })
                      }
                    >
                      <div
                        className="border w-100 py-4"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          cursor: "pointer",
                          height: "100%",
                        }}
                      >
                        <img
                          src={item.model_icon}
                          className="w-75 h-75"
                          style={{ margin: "0 auto" }}
                        />
                        <span
                          className="my-2 text-center"
                          style={{ textTransform: " capitalize" }}
                        >
                          <b>{item.model_name}</b>
                        </span>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          ) : (
            <>
              <Box
                sx={{
                  height: "400px",
                }}
              >
                <Box class="d-flex justify-content-center pt-5 ">
                  <SentimentVeryDissatisfiedIcon
                    sx={{ fontSize: "160px", color: "#1b1b42" }}
                  />
                </Box>
                <Box class="d-flex justify-content-center">
                  {/* <ErrorOutlineIcon
                  sx={{ fontSize: "50px", color: "#1b1b42" }}
                /> */}
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "46px",
                        textAlign: "center",
                        fontWeight: "700",
                        color: "#2A2381",
                      }}
                    >
                      Sorry!
                    </Typography>
                  </Box>
                </Box>

                <Typography
                  // class={style.npfTextO}
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "28px",
                    textAlign: "center",
                    fontWeight: "600",
                    color: "#0E0E0E",
                  }}
                >
                  No Model Found...
                </Typography>
                <Typography
                  // class={style.npfTextT}
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "24px",
                    textAlign: "center",
                    fontWeight: "300",
                    color: "#0E0E0E",
                  }}
                >
                  You Can Try Our Different Brand
                </Typography>
              </Box>
            </>
          )}
        </div>
      ) : (
        <div className={style.customH}></div>
      )}
    </>
  );
}
