import React, { useRef } from "react";
import styles from "./AccountDetail.module.css";
import Sidebar from "../Sidebarr/Sidebar";
import Snackbar from "@mui/material/Snackbar";
import {
  updateUserDetail,
  userDetails,
  getProfileGst,
} from "../../Services/AllAPIs";
import { useEffect } from "react";
import { useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { Button } from "react-bootstrap";
import SnackbarContent from "@mui/material/SnackbarContent";

function AccountDetails(props) {
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(true);
  const [userAdd, setUserAdd] = useState(props.userAddress);
  const [name, setName] = useState();
  const [gstnum, setGstNum] = useState();
  const [email, setEmail] = useState();
  const [error, setError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [open, setOpen] = useState(false);
  const [snackbarState, setSnackbarState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const handleInputChange = (event) => {
    const { value } = event.target;
    setEmail(value);

    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(value);
    setIsValidEmail(isValid);
  };

  const { vertical, horizontal, open } = snackbarState;

  const handleClick = (newState) => () => {
    setSnackbarState({ ...newState, open: true });
    setTimeout(() => {
      setSnackbarState({ ...newState, open: false });
    }, 2000);
  };

  const handleClose = (event, reason) => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const updateProfile = (e) => {
    e.preventDefault();
    if (!isValidEmail) {
      setLoader(false);
      return;
    }
    setLoader(true);
    setError(false);

    getProfileGst({
      user_profile: "",
      fullName: name,
      gstNumber: gstnum,
      email: email,
    })
      .then((res) => {
        console.log(res);
        userGetbyId();
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoader(false);
      });

    // const updatedField = name ? "Profile Name" : "GST Number";
    // const message = `${updatedField} Updated Successfully`;

    handleClick({ vertical: "top", horizontal: "right" })();
  };

  const userGetbyId = () => {
    setLoader(true);
    userDetails(localStorage.getItem("userId"))
      .then((res) => {
        console.log(res.data.data);
        setLoader(false);
        setData(res.data.data);
      })
      .catch((error) => setLoader(false));
  };

  // const UpdateUserDetails = () => {
  //   updateUserDetail(localStorage.getItem("userId"))
  //     .then((res) => {
  //       console.log(res.data);
  //     })
  //     .catch((error) => console.log(error));
  // };

  useEffect(() => {
    userGetbyId();
    window.scrollTo(0, 0);
    props.setHeaderColor(false);
    // UpdateUserDetails();
    // console.log(props.userAddress)
  }, []);

  const obj = {
    bg: "#f1f1f1",
    text: "#2A2381",
  };
  return (
    <Sidebar one={obj} userAddress={userAdd}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loader ? (
        <div
          className={`${styles.cont} 
        flex-fill px-5 mb-5`}
        >
          <form onSubmit={updateProfile}>
            <div className={`${styles.Firstpadd} d-md-flex gap-5`}>
              <div className="w-md-50 w-100 mb-4">
                <label>NAME</label>
                <input
                  className="form-control"
                  onChange={(e) => {
                    setName(e.target.value.trim());
                  }}
                  defaultValue={data.fullName}
                />
              </div>
              <div className={`w-md-50 w-100 ${styles.emailinput}`}>
                <label>EMAIL ID</label>
                <input
                  className="form-control"
                  // placeholder={data?.email}
                  // disabled
                  type="email"
                  required
                  defaultValue={data.email}
                  onChange={handleInputChange}
                />
                {isValidEmail ? (
                  ""
                ) : (
                  <p style={{ color: "red" }}>Please enter a valid email</p>
                )}
              </div>
            </div>
            <div className="d-md-flex gap-5 mt-4">
              <div className="w-md-50 w-100  mb-4">
                <label>PHONE NUMBER</label>
                <input
                  className="form-control"
                  placeholder={data?.mobile_number}
                  disabled
                />
              </div>
              <div className={`w-md-50 w-100 ${styles.gstinput}`}>
                <label>GST NUMBER</label>
                <input
                  className="form-control"
                  // placeholder={data?.gstNumber}
                  defaultValue={data.gstNumber}
                  onChange={(e) => {
                    setGstNum(e.target.value.trim());
                  }}
                />
              </div>
            </div>
            {/* <div className={`${styles.divBtn} d-flex justify-content-end`}>
        <button>SAVE CHANGES</button>
      </div> */}

            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={open}
              key={vertical + horizontal}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              {error ? (
                <SnackbarContent
                  message="Email Already Exist"
                  style={{ backgroundColor: "#0069ff" }}
                />
              ) : (
                <SnackbarContent
                  message="Profile Updated Successfully"
                  style={{ backgroundColor: "#0069ff" }}
                />
              )}
            </Snackbar>
            <div className="d-flex justify-content-end mt-4">
              <Button type="submit">Update</Button>
            </div>
          </form>
        </div>
      ) : (
        <div className="customH"></div>
      )}
    </Sidebar>
  );
}

export default AccountDetails;
