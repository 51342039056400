import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import style from "./style.module.css";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router";
import MenuIcon from "@mui/icons-material/Menu";
import { signoutUser } from "../../Services/AllAPIs";

export default function Sidebar({ children, one, two, three, userAddress }) {
  const matches = useMediaQuery("(min-width:1027px)");
  const [toggleTop, setToggleTop] = useState(false);

  const navigate = useNavigate();
  const [addList, setAddList] = useState(localStorage.getItem("userAddress"));
  const signOut = () => {
    signoutUser(localStorage.getItem("userId"))
      .then((res) => {
        console.log(res);
        localStorage.removeItem("userId");
        localStorage.setItem("IsLoggin", false);
        navigate("/");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // console.log(localStorage.getItem("userAddress"))
    console.log(userAddress);
  }, []);

  const customTextStyle = {
    fontFamily: " ",
    fontSize: "20px ",
  };

  return (
    <>
      {/* main div */}
      <div
        className="container-fluid pt-3 "
        style={{ padding: matches ? "5%" : "", minHeight: "60vh" }}
      >
        {/* header div */}
        <div className="container-fluid ">
          <span>
            <span
              onClick={() => navigate("/")}
              className={style.cursor_pointer}
            >
              Home
            </span>{" "}
            <ArrowForwardIosIcon sx={{ fontSize: "16px" }} /> My Account{" "}
          </span>
          <div className="d-flex justify-content-between pt-3 pb-4">
            <div>
              <h3 className={`${style.myAccount}`}>MY ACCOUNT</h3>
            </div>
          </div>
        </div>
        {!matches ? (
          <div className="ps-3 py-2">
            <span
              className=""
              onClick={() => setToggleTop(!toggleTop)}
              style={{
                borderRadius: "10px",
                border: "1px solid black",
                padding: "8px 10px 10px 10px",
              }}
            >
              <MenuIcon />
            </span>
          </div>
        ) : (
          ""
        )}
        {/* deatils div */}
        <div className="container-fluid d-flex mt-2 relative ">
          <div
            className=" "
            style={{
              position: "absolute",
              height: toggleTop ? "300px" : "0",
              transition: "0.3s",
              overflow: "hidden",
              zIndex: "99",
            }}
          >
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <ListItem
                sx={{
                  cursor: "pointer",
                  background: one !== undefined ? one.bg : "",
                }}
                onClick={() => navigate("/accountDetails")}
              >
                <ListItemIcon>
                  <PermContactCalendarOutlinedIcon
                    sx={{ color: one !== undefined ? one.text : "" }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    fontWeight: "800",
                    color: one !== undefined ? one.text : "",
                  }}
                  primary="Profile"
                />
                {/* <h1 className={`${style.profilepage}`}>Profile</h1> */}
              </ListItem>

              <ListItem
                sx={{
                  cursor: "pointer",
                  background: two !== undefined ? two.bg : "",
                }}
                onClick={() =>
                  navigate(userAddress == "true" ? "/address" : "/new-address")
                }
              >
                <ListItemIcon>
                  <LocationOnIcon
                    sx={{ color: two !== undefined ? two.text : "" }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    fontWeight: "800",
                    color: two !== undefined ? two.text : "",
                  }}
                  primary="Address"
                />
              </ListItem>

              <ListItem
                sx={{
                  cursor: "pointer",
                  background: three !== undefined ? three.bg : "",
                }}
                onClick={() => {
                  console.log(userAddress);
                  navigate("/myorder");
                }}
              >
                <ListItemIcon>
                  <ShoppingBagOutlinedIcon
                    sx={{ color: three !== undefined ? three.text : "" }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    fontWeight: "800",
                    color: three !== undefined ? three.text : "",
                  }}
                  primary="Order History"
                />
              </ListItem>
            </List>
          </div>
          {matches ? (
            <div className="w-25">
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                <ListItem
                  sx={{
                    cursor: "pointer",
                    background: one !== undefined ? one.bg : "",
                    // border:"2px solid red",
                    // width: "20%",
                  }}
                  onClick={() => navigate("/accountDetails")}
                >
                  <ListItemIcon>
                    <PermContactCalendarOutlinedIcon
                      sx={{ color: one !== undefined ? one.text : "" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={customTextStyle}
                    sx={{
                      fontWeight: "800",
                      color: one !== undefined ? one.text : "",
                    }}
                    primary="Profile"
                  />
                  {/* <h6 className={`${style.profile_Name}`}>Profile</h6> */}
                </ListItem>

                <ListItem
                  sx={{
                    cursor: "pointer",
                    background: two !== undefined ? two.bg : "",
                    // width: "20%",
                  }}
                  onClick={() =>
                    navigate(
                      userAddress === "true" ? "/address" : "/new-address"
                    )
                  }
                >
                  <ListItemIcon>
                    <LocationOnIcon
                      sx={{ color: two !== undefined ? two.text : "" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontWeight: "800",
                      color: two !== undefined ? two.text : "",
                    }}
                    primary="Addresses"
                  />
                  {/* <h6 className={`${style.profile_Name}`}>Address</h6> */}
                </ListItem>

                <ListItem
                  sx={{
                    cursor: "pointer",
                    background: three !== undefined ? three.bg : "",
                    // width: "68%",
                    // border:"2px solid red"
                  }}
                  // onClick={() => navigate("/myorder")}
                  onClick={() => {
                    console.log(userAddress);
                    navigate("/myorder");
                  }}
                >
                  <ListItemIcon>
                    <ShoppingBagOutlinedIcon
                      sx={{ color: three !== undefined ? three.text : "" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className={style.data}
                    sx={{
                      fontWeight: "800",
                      color: three !== undefined ? three.text : "",
                      fontSize: "20px", // Add this line to set font size
                    }}
                    primary="Order History"
                  />
                </ListItem>
              </List>
            </div>
          ) : (
            ""
          )}
          <div className={`${matches ? "w-75" : "w-100"} m-0 p-0`}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
}
