import { createSlice } from '@reduxjs/toolkit'

export const ProductFilterSlice = createSlice({
    name: 'ProductFilter',
    initialState: {
        categoryId: [],
        manufacturerId: []
    },
    reducers: {
        addManufactureCategoryId: (state, action) => {
            return { ...action.payload }
        },
        removeAllFilter: () => {
            return ({
                categoryId: [],
                manufacturerId: []
            })
        }
    }

})
export const { addManufactureCategoryId, removeAllFilter } = ProductFilterSlice.actions
