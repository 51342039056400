import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "@mui/material";
import "./style.css";
import { apis } from "../../Auth/Apis";
import { useNavigate } from "react-router";
import axios from "axios";

export const Footer = () => {
  const navigate = useNavigate();
  const [allVehicle, setAllVehical] = useState([]);

  useEffect(() => {
    getAllVehicle();
  }, []);

  const getAllVehicle = () => {
    axios
      .get(`${apis.baseUrl}vehicle/get/getAllVehicle`)
      .then((res) => {
        // console.log(res.data.data);
        setAllVehical(res.data.data);
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <Box className="main">
        <Box className="main_heading">
          <div className="container">
            <Grid container>
              <Grid
                item
                md={4}
                sm={4}
                xs={7}
                className="d-flex justify-content-center"
              >
                <div>
                  <Grid container pt={4}>
                    <p className="sub_heading ms-1">Our Products</p>
                  </Grid>
                  {allVehicle.map((item, index) => (
                    <Grid key={index} container mb={-2}>
                      <Grid item>
                        <KeyboardDoubleArrowRightIcon className="h_14" />
                      </Grid>
                      <Grid item>
                        <p
                          // className="pointer" onClick={() => {
                          //   navigate(`/brand/${item._id}`)
                          // }}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.vehicle_name}
                        </p>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </Grid>
              <Grid
                item
                md={4}
                sm={4}
                className="d-flex justify-content-center"
              >
                <div>
                  <Grid container pt={4} ml={1}>
                    <p className="sub_heading">Resources</p>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <KeyboardDoubleArrowRightIcon className="h_14" />
                    </Grid>
                    <Grid item>
                      <p
                        className="custom-text"
                        onClick={() => navigate("/privacy")}
                      >
                        Policies
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container mt={-3}>
                    <Grid item>
                      <KeyboardDoubleArrowRightIcon className="h_14" />
                    </Grid>
                    <Grid item>
                      <p
                        className="custom-text"
                        onClick={() => navigate("/termCondition")}
                      >
                        Terms & Conditions
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container ml={1}>
                    <Grid item>
                      <p className="sub_heading">Quick Links</p>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <KeyboardDoubleArrowRightIcon className="h_14" />
                    </Grid>
                    <Grid item>
                      <p
                        className="custom-text"
                        onClick={() => navigate("/contactUs")}
                      >
                        Contact Us
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container mt={-3}>
                    <Grid item>
                      <KeyboardDoubleArrowRightIcon className="h_14" />
                    </Grid>
                    <Grid item>
                      <p
                        onClick={() => navigate("/about")}
                        className="custom-text"
                      >
                        About Us
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid
                item
                md={4}
                sm={4}
                xs={6}
                className="d-flex justify-content-center"
              >
                <div>
                  <Grid container pt={4}>
                    <p className="sub_heading ms-1">Follow Us On</p>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      {/* <FacebookIcon /> */}
                      <img
                        src="../images/facebook.png"
                        className="h_14"
                        style={{ marginRight: "5px" }}
                      />
                    </Grid>
                    <Grid item>
                      <p className="pointer">
                        <a
                          href={
                            "https://www.facebook.com/profile.php?id=100092832802203"
                          }
                          className="social custom-text"
                          target="_blank"
                          style={{ textDecoration: "none", fontSize: "13px" }}
                        >
                          Kapoorspareshub
                        </a>
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container mt={-1}>
                    <Grid item>
                      <img
                        src="../images/instagram.png"
                        className="h_14"
                        style={{ marginRight: "5px" }}
                      />
                    </Grid>
                    <Grid item>
                      <p className="custom-text">
                        <a
                          href={
                            "https://www.instagram.com/kapoorspareshub_2013/"
                          }
                          className="social custom-text"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          Kapoorspareshub
                        </a>
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container mt={-1}>
                    <Grid item>
                      <img
                        src="../images/linkedin.png"
                        className="h_14"
                        style={{ marginRight: "5px" }}
                      />
                    </Grid>
                    <Grid item>
                      <p className="custom-text">
                        <a
                          href={
                            "https://www.linkedin.com/in/kapoor-enterprises-4943a9170/"
                          }
                          className="social custom-text"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          Kapoorspareshub
                        </a>
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container mt={-1}>
                    <Grid item>
                      <img
                        src="../images/twitter.png"
                        className="h_14"
                        style={{ marginRight: "5px" }}
                      />
                    </Grid>
                    <Grid item>
                      <p className="custom-text">
                        <a
                          href={"https://twitter.com/kapoor_hub"}
                          className="social custom-text"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          Kapoorspareshub
                        </a>
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
        <div className="container px-md-5">
          <Grid container className="px-md-5">
            <Grid item md={12} xs={12} sm={12}>
              <hr style={{ width: "100%", border: "1px solid white " }} />
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "-15px",
                  marginLeft: "15px",
                }}
              >
                Copyright @ 2023 Kapoor Spares-Hub. All Rights Reserved.
              </p>
            </Grid>
          </Grid>
          <Grid container mt={2}></Grid>
        </div>
      </Box>
    </>
  );
};
