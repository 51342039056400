import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TuneIcon from "@mui/icons-material/Tune";
import { useMediaQuery } from "@mui/material";
import { useParams } from "react-router";
import { getonlyCategoryManufacturerDatas } from "../../Services/AllAPIs";
import { indigo } from "@mui/material/colors";
import { useSelector, useDispatch } from "react-redux";
import {
  addManufactureCategoryId,
  removeAllFilter,
} from "../../Redux-toolkit/Slices/ProductFilterSlice";
export default function Filter({
  setCategoryId,
  setManufacturerId,
  applyFilter,
  categoryId,
  manufacturerId,
  product,
  filterDataaa,
  
}) {
  const matches = useMediaQuery("(min-width:500px)");
  const [open, setOpen] = useState(false);
  const [category, setCategoryData] = useState([]);
  const [manufacture, setManufactureData] = useState([]);
  const [checkedItems, setCheckedItems] = useState(
    sessionStorage.getItem("filter")
      ? sessionStorage.getItem("filter").split(",")
      : []
  );
  const { id } = useParams();

  let dispatch = useDispatch();
  let reduxFilterData = useSelector((e) => e.ProductFilter);
  const filterObjectData = (key, arr) => {
    if (arr) {
      var resArr = [];
      arr.forEach((item) => {
        var i = resArr.findIndex((x) => x[key] == item[key]);
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
      return resArr;
    } else {
      return [];
    }
  };
  const getonlyCategoryManufactur = () => {
   
    var ids = id.split(",");

    
    if (id !== "noData") {
      let productDatas= filterDataaa.filter(
        (item1) => product.some((item2) => item1._id === item2._id)
       );
      var category1 = productDatas.map((e) => e.product_category_aaray[0]);
      var manuf1 = productDatas.map((e) => e.product_manufacture_aaray[0]);
      
      getonlyCategoryManufacturerDatas({ id: ids })
        .then((res) => {
          if (res.data) {
            let category = res.data.map((e) => e.Category_data);
            let manuf = res.data.map((e) => e.Manufacturer_data);
            let data1 = [];
            category.forEach((e) => {
              data1 = [...data1, ...e];
            });
            let data = [];
            manuf.forEach((e) => {
              data = [...data, ...e];
            });
            
            
            if (productDatas.length !== 0) {
              data1 = data1.filter((e) => category1.includes(e._id));
              data = data.filter((e) => manuf1.includes(e._id));
              // data1 = filterObjectData("category_name",data1)
              // data =filterObjectData("manufacturer_name",data);
            }
            // console.log(([...new Map(data.map(item =>
            //   [item["category_name"], item])).values()]))

            setCategoryData([
              ...new Map(
                data1.map((item) => [item["category_name"], item])
              ).values(),
            ]);
            setManufactureData([
              ...new Map(
                data.map((item) => [item["manufacturer_name"], item])
              ).values(),
            ]);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    getonlyCategoryManufactur();
  }, [product]);

  const add = (e, idd) => {
    let arr = [...categoryId];
    if (e.target.checked) {
      arr.push(e.target.value);
      setCategoryId(arr);
    } else {
      let newArr = categoryId.filter((item) => item !== idd);
      setCategoryId(newArr);
    }
  };

  const add2 = (e, idd) => {
    let arr = [...manufacturerId];
    if (e.target.checked) {
      arr.push(e.target.value);
      setManufacturerId(arr);
    } else {
      let newArr = manufacturerId.filter((item) => item !== idd);
      setManufacturerId(newArr);
    }
  };

  return (
    <div className={matches ? "me-2" : "me-0"}>
      {/* filter div */}
      <div
        className=" border  py-3"
        style={{
          width: "100%",
          borderRadius: "8px 8px 0 0",
          marginBottom: "2px",
        }}
      >
        <div className={style.filterBtn}>
          <Typography>
            <b className="text-dark">FILTERS</b>
          </Typography>
        </div>
        <div className={style.btns}>
          {/* <button className={style.button}>Sort By <KeyboardArrowDownIcon/> </button> */}
          <button className={style.button} onClick={() => setOpen(!open)}>
            <TuneIcon sx={{ color: "#DA7B02" }} /> Filter
          </button>
        </div>
      </div>
      {/* filter details div */}
      <div
        className="border "
        style={{
          width: "100%",
          overflow: "hidden",
          transition: "0.3s",
          borderRadius: "0 0 8px 8px",
          height: open || matches ? "100%" : "0",
        }}
      >
        <Typography p={1} className={style.text_center}>
          <b>CATEGORY</b>
        </Typography>

        <div
          className={`w-100 d-flex justify-content-center px-3 ${style.categories}`}
        >
          <div className={`w-75 ${style.categoryName}`}>
            {category.map((item, index) => {
              return (
                <div
                  // className={`${style.categoryList} `}
                  style={{ textTransform: "capitalize" }}
                >
                  <FormControlLabel
                    key={index}
                    // className={style.catetgoryData}
                    value={item._id}
                    onChange={(e) => {
                      add(e, item._id);
                      const newCheckedItems = [...checkedItems];
                      if (e.target.checked) {
                        newCheckedItems.push(item.category_name);
                      } else {
                        newCheckedItems.splice(
                          newCheckedItems.indexOf(item.category_name),
                          1
                        );
                      }
                      setCheckedItems([...new Set(newCheckedItems)]);
                    }}
                    control={
                      <Checkbox
                        // defaultChecked={checkedItems.includes(
                        //   item.category_name
                        // )}
                        defaultChecked={reduxFilterData.categoryId.includes(
                          item._id
                        )}
                        sx={{
                          // color: indigo[800],
                          "&.Mui-checked": {
                            color: indigo[600],
                          },
                        }}
                        className={style.categoryCheck}
                      />
                    }
                    label={item.category_name}
                  />
                  <br />
                </div>
              );
            })}
          </div>
        </div>
        <hr />
        <Typography p={1} className={style.text_center}>
          <b>MANUFACTURER</b>
        </Typography>
        {/* manufacturer div */}
        <div
          className={`w-100 d-flex justify-content-center px-3 ${style.manufactures}`}
        >
          <div className={`w-75 ${style.manufactureName}`}>
            {manufacture.map((item, index) => {
              return (
                <div
                  // className={style.manufactureList}
                  style={{ textTransform: "capitalize" }}
                >
                  <FormControlLabel
                    // className={style.manufacturedata}
                    key={index}
                    value={item._id}
                    onChange={(e) => {
                      add2(e, item._id);
                      const newCheckedItems = [...checkedItems];
                      if (e.target.checked) {
                        newCheckedItems.push(item.manufacturer_name);
                      } else {
                        newCheckedItems.splice(
                          newCheckedItems.indexOf(item.manufacturer_name),
                          1
                        );
                      }
                      setCheckedItems([...new Set(newCheckedItems)]);
                    }}
                    control={
                      <Checkbox
                        // defaultChecked={checkedItems.includes(
                        //   item.manufacturer_name
                        // )}
                        defaultChecked={reduxFilterData.manufacturerId.includes(
                          item._id
                        )}
                        sx={{
                          // color: indigo[800],
                          "&.Mui-checked": {
                            color: indigo[600],
                          },
                        }}
                      />
                    }
                    label={item.manufacturer_name}
                  />
                  <br />
                </div>
              );
            })}
          </div>
        </div>
        <hr />

        {/* button div */}
        <div className="ps-3 pe-3 pb-4 pt-2">
          <button
            className="btn  w-100 text-white"
            style={{ background: "#2A2381" }}
            onClick={() => {
              applyFilter(1, "first");
              sessionStorage.removeItem("filter");

              sessionStorage.setItem("filter", checkedItems);

              dispatch(
                addManufactureCategoryId({
                  manufacturerId: [...manufacturerId],
                  categoryId: [...categoryId],
                })
              );
            }}
          >
            Apply Filter
          </button>

          <div>
            <button
              className="btn w-100  mt-2"
              style={{ outlineStyle: "auto", outlineColor: "#2A2381" }}
              onClick={() => {
                applyFilter(0, "ok");

                sessionStorage.removeItem("filter");
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
