import './App.css';
import { Router } from './Router';
import 'bootstrap/dist/css/bootstrap.min.css'


function App() {
  return (
    <Router />
  );
}

export default App;
