import axios from "axios";
import { apis } from "../Auth/Apis";

export const getAllProducts = async () => {
  return await axios.get(`${apis.baseUrl}${apis.product.getAll}`);
};
export const getAllVehicle = async () => {
  return await axios.get(`${apis.baseUrl}${apis.vehicle.getAll}`);
};
export const getOnlyProducts = async (id) => {
  return await axios.post(`${apis.baseUrl}${apis.getOnlyProduct}`,id);
};
export const addOtp = async (data) => {
  return await axios.post(`${apis.baseUrl}${apis.addOtp}`,data);
};
export const addshippingAddress = async (data) => {
  return await axios.post(`${apis.baseUrl}${apis.shippingAddress}`,data);
};
export const getSingleProduct = async (id) => {
  return await axios.get(`${apis.baseUrl}${apis.getSingleProducts}${id}`);
};

export const getSegmentBrands = async (id) => {
  return await axios.get(`${apis.baseUrl}${apis.getSegmentBrand}${id}`);
};

export const getSegmentModels = async (id) => {
  return await axios.get(
    `${apis.baseUrl}${apis.getSegmentModel}${id[0]}?segment=${id[1]}`
  );
};

export const getonlyCategoryManufacturerDatas = async (id) => {
  return await axios.post(
    `${apis.baseUrl}${apis.getonlyCategoryManufacturerD}`,id
  );
};

export const applyFilt = async (data) => {
  return await axios.post(`${apis.baseUrl}${apis.applyFil}`, data);
};
export const applyFilt2 = async (data) => {
  return await axios.post(`${apis.baseUrl}${apis.applyFil2}`, data);
};
export const getBymobileNumber = async (data) => {
  return await axios.post(`${apis.baseUrl}${apis.getbymobileNumber}`, data);
};
export const userOTPP = async (data) => {
  return await axios.post(`${apis.baseUrl}${apis.userOTP}`, data);
};

export const OtpVerify = async (data) => {
  return await axios.post(`${apis.baseUrl}${apis.verifyOtp}`, data);
};

export const signUpUser = async (data) => {
  return await axios.post(`${apis.baseUrl}${apis.signUp}`, data);
};

export const Userlogin = async (data) => {
  return await axios.post(`${apis.baseUrl}${apis.login}`, data);
};

export const userDetails = async (id) => {
  return await axios.get(`${apis.baseUrl}${apis.userDetail}${id}`);
};

export const signoutUser = async (id) => {
  return await axios.post(`${apis.baseUrl}${apis.signout}${id}`);
};

export const updateUserDetail = async (id) => {
  return await axios.put(`${apis.baseUrl}${apis.updateUser}${id}`);
};

export const Aboutus = async () => {
  return await axios.get(`${apis.baseUrl}${apis.aboutUs}`);
};

export const contactus = async (data) => {
  return await axios.post(`${apis.baseUrl}${apis.contact}`, data);
};

export const privacyPolicy = async () => {
  return await axios.get(`${apis.baseUrl}${apis.privacy}`);
};

export const termCondition = async () => {
  return await axios.get(`${apis.baseUrl}${apis.term}`);
};

export const addToCart = async (data) => {
  return await axios.put(
    `${apis.baseUrl}${apis.cart.add}/${localStorage.getItem("userId")}`,
    data
  );
};
export const getCart = async () => {
  return await axios.get(
    `${apis.baseUrl}${apis.cart.get}/${localStorage.getItem("userId")}`
  );
};
export const editProductQuantity = async (data) => {
  return await axios.put(
    `${apis.baseUrl}${apis.cart.productQuantity}/${localStorage.getItem(
      "userId"
    )}`,

    data
  );
};
export const removeFromCart = async (data) => {
  return await axios.put(
    `${apis.baseUrl}${apis.cart.removeFromCart}/${localStorage.getItem(
      "userId"
    )}`,
    data
  );
};

export const getCartLen= async ()=>{
  return await axios.get(`${apis.baseUrl}${apis.cart.getCartLen}/${localStorage.getItem("userId")}`)

}

export const getOrderHistory = async () => {
  return await axios.get(`${apis.baseUrl}${apis.orderHistory.get}/${localStorage.getItem("userId")}
    `);
};

export const forgetPassword = async (data) => {
  return await axios.post(`${apis.baseUrl}${apis.forget}`,data);
};

export const changePass = async (data) => {
  return await axios.post(`${apis.baseUrl}${apis.changePass}`,data);
};

export const addAddress = async (data) => {
  return await axios.post(
    `${apis.baseUrl}${apis.address.add}/${localStorage.getItem("userId")}`,
    data
  );
};
export const getAddress= async ()=>{
  return await axios.get(`${apis.baseUrl}${apis.address.get}/${localStorage.getItem("userId")}`)

}
export const deleteAddress = async (data) => {
  return await axios.post(
    `${apis.baseUrl}${apis.address.delete}/${localStorage.getItem(
      "userId"
    )}`,
    data
  );
};
export const editAddress = async (data) => {
  return await axios.post(
    `${apis.baseUrl}${apis.address.edit}/${localStorage.getItem(
      "userId"
    )}`,
    data
  );
};

export const getSegmentBrandModel = async () =>{
  return await axios.get(`${apis.baseUrl}${apis.getAllData}`)
}
 

export const getProfileGst = async (data) =>{
  return await axios.put(`${apis.baseUrl}${apis.getProfileupdate}${localStorage.getItem("userId")}`,data)
}

export const searchData = async () =>{
  return await axios.get(`${apis.baseUrl}${apis.items.searchData}`)
}
