import { useContext, useEffect, useRef, useState } from "react";
import "./ForgotPassword.css"
import React from 'react'

import { useParams, useNavigate } from "react-router"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { changePass } from "../../Services/AllAPIs";
import { Dialog, Grid } from "@mui/material";
import { Context } from "../../Services/ProductAPI";
function ForgotPassword(props) {
  const { email, token, id } = useParams();
  const navigate = useNavigate()
  const contextData = useContext(Context);
  const [show, setShow] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isVisible1, setVisible1] = useState(false);
  const [btn, setbtn] = useState(true)
  const [checkEmail, setCheckEmail] = useState(false)

  const credentials = useRef({
     pass:'',
     cPass:''
  })

  const toggle = () => {
    setVisible(!isVisible);
  };
  const toggle1 = () => {
    setVisible1(!isVisible1);
  };

  const checkPass = (e)=>{
    if(credentials.current.pass.length >= 8 && credentials.current.cPass.length >= 8){
      setbtn(false)
    }
    else{
      setbtn(true)
    }
  }
  const changePassAPI = async (e) => {
    e.preventDefault()
    if (credentials.current.pass === credentials.current.cPass) {
      let body = {
        token,
        id,
        password: credentials.current.pass,
        email,
        role:"mechanic"
      }
      console.log(body,"body")
      await changePass(body).then(() => { navigate("/confirmation") }).catch(e => { console.log(e); alert(" Token is expair ") })
    } else {
      setShow(true)
    }
  }


  const passCheck = ()=>{
    if(credentials.current.pass.length >= 8){
      setCheckEmail(false)
    }else{
      setCheckEmail(true)
    }
  }
  const passCheckOn = ()=>{
    if(credentials.current.pass.length >= 8){
      setCheckEmail(false)
    }
  }

  useEffect(()=>{
    props.setHeaderColor(true)
  },[])

  return (
    <>
      <Dialog maxWidth={"md"} m={0} p={5} fullWidth={true} open={true}>
        <div
          className="container-fluid"
          style={{
            overflowY:'hidden',
            background:
              "linear-gradient(90.22deg, #DA7B02 -1.57%, #EEBB2E 99.81%)",
            zIndex: "999999999999999",
          }}
        >
         
          <div class="container-fluid main_container d-grid" style={{ placeItems: "center" }}>
            <div class=" col-sm-8 col-12">
              <form class="form_width">
                <div class="form_container">
                  <h2 class="text-center forget_heading text-white display-6 mb-5">
                    Reset Password?
                  </h2>
                  {/* <div class="text-center para mb-5">
                    <p>Confirm your mail ID</p>
                  </div> */}
                  <div class="row d-flex justify-content-center ">
                    <div class="form-group logininput2 ">
                      <input
                        type={isVisible ? "text" : "password"}
                        // defaultValue={pass}
                        class="form-control py-2 pass_feild"
                        placeholder="New Password"
                        // maxLength={8}
                        onBlur={passCheck}
                        onChange={(e) => { 
                          credentials.current.pass = e.target.value
                          setShow(false)
                          checkPass(e) 
                          passCheckOn()
                          }}
                        required="required"
                      />
                      <span class="icon2" onClick={toggle}>
                        {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </span>
                    </div>
                  </div>
                    <div>{checkEmail?<span className="fs-12 red">*Minimum lenght of Password should be 8 character</span>:<spna className='fs-12 trans'>test</spna>}</div>
                  <div class="row d-flex mb-3  justify-content-center">
                    <div class="form-group logininput2 ">
                      <input
                        type={isVisible1 ? "text" : "password"}
                        // defaultValue={cPass}

                        // maxLength={8}
                        class="form-control  py-2 pass_feild"
                        placeholder="Confirm Password"
                        onChange={(e) => { 
                          credentials.current.cPass = e.target.value
                          setShow(false)
                          checkPass(e)
                         }}
                        required="required"
                      />
                      <span class="icon2" onClick={toggle1}>
                        {isVisible1 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </span>
                    </div>
                  </div>
                  {show ? (<>
                   
                    <div style={{ textAlign: "center", color: "red" }}>
                      <h5>Password does not match</h5>
                    </div>
                   
                  </>) : (<></>)}

                  <div class="row d-flex justify-content-center">
                    <div class="form-group d-flex justify-content-center mb-3">
                      <button 
                      class="btn btn-1 mb-5 w-100" 
                      disabled={btn}
                      onClick={(e) => changePassAPI(e)}>

                        Change Password

                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>
      <Grid
        container
        className="first_page  "
        sx={{
          background: `url(${"/images/image2.png"})`,
        }}
      >
        
      </Grid>
    </>
  )
}

export default ForgotPassword