import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Backdrop, Box, CircularProgress, Grid, Typography } from "@mui/material";
import style from "./style.module.css";
import { useNavigate, useParams } from "react-router";
import { getSegmentBrands } from "../../Services/AllAPIs";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

export default function Brand(props) {
  const navigate = useNavigate();
  const [vehicle, setVehicle] = useState([]);
  const [vehiclename, setVehiclename] = useState([]);
  const [loader, setLoader] = useState(true);
  const { id } = useParams();

  const vehicleData = () => {
    getSegmentBrands(id)
      .then((res) => {
        console.log(res.data.data[0]);
        setVehiclename(res.data.data[0]);
        setVehicle(res.data.data[0].Brands);
        setLoader(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    vehicleData();
    window.scrollTo(0, 0);
    props.setHeaderColor(false)
  }, [id]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loader ? (
        <div className={`container pt-3 pb-5 ${style.mainContainer}`}>
          <span>
            {" "}
            <span onClick={() => navigate("/")} className={style.text_nav}>
              Home
            </span>{" "}
            <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />{" "}
            <span style={{ textTransform: "capitalize" }}>
              {" "}
              {vehiclename.vehicle_name}
            </span>
          </span>
          {vehicle.length !== 0 ?(
            <>
             <Grid container py={2}>
            <span className="d-flex my-3">
              <Typography variant="h5">
                <b className={style.heading}>
                  SELECT YOUR {vehiclename.vehicle_name} COMPANY
                </b>
              </Typography>
            </span>
          </Grid>

          <Grid container spacing={3}>
            {vehicle.map((item, index) => {
              return (
                <Grid
                  item
                  md={3}
                  xs={6}
                  sm={4}
                  key={index}
                  onClick={() => {
                    navigate(`/model/${item._id}&&${id}`);
                    localStorage.setItem(
                      "data",
                      JSON.stringify({
                        segment: vehiclename.vehicle_name,
                        sId: id,
                        brand: item.brand_name,
                      })
                    );
                  }}
                >
                  <div
                    className="border py-4"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                      height: "270px",
                    }}
                  >
                    <img
                      src={item.brand_image}
                      className="w-75 h-75"
                      style={{ margin: "0 auto" }}
                    />
                    <span className="my-3 text-center" style={{textTransform:"uppercase"}}>
                      <b>{item.brand_name}</b>
                    </span>
                  </div>
                </Grid>
              );
            })}
          </Grid>
            </>
          ):(
            <>
             <Box
                sx={{
                  height: "60vh",
                }}
              >
                <Box class="d-flex justify-content-center pt-5 ">
                  <SentimentVeryDissatisfiedIcon
                    sx={{ fontSize: "160px", color: "#1b1b42" }}
                  />
                </Box>
                <Box class="d-flex justify-content-center">
                  {/* <ErrorOutlineIcon
                  sx={{ fontSize: "50px", color: "#1b1b42" }}
                /> */}
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "46px",
                        textAlign: "center",
                        fontWeight: "700",
                        color: "#2A2381",
                      }}
                    >
                      Sorry!
                    </Typography>
                  </Box>
                </Box>

                <Typography
                  // class={style.npfTextO}
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "28px",
                    textAlign: "center",
                    fontWeight: "600",
                    color: "#0E0E0E",
                  }}
                >
                  No Brand Found...
                </Typography>
               
              </Box>
            </>
          )}
         
        </div>
      ) : (
        <div className={style.customH}></div>
      )}
    </>
  );
}
