import React, { useState, useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./Style.css";
// import dicount from "";
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router";
import RemoveShoppingCartOutlinedIcon from "@mui/icons-material/RemoveShoppingCartOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import {
  getCart,
  editProductQuantity,
  removeFromCart,
} from "../../Services/AllAPIs";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Link } from "react-router-dom";
import axios from "axios";
import { Button } from "react-bootstrap";
import { apis } from "../../Auth/Apis";
export default function Cart(props) {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:768px)");
  const matches1 = useMediaQuery("(min-width:768px)");

  if (localStorage.getItem("IsLoggin") !== "true") navigate("/");

  const [cartLen, setCartLen] = useState(0);

  const [isQntyChanged, setIsQntyChanged] = useState(false);
  const [total, setTotal] = useState(0);
  const [couponInpValue, setCouponInpValue] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [coupon, setAllCoupon] = useState([]);
  const [applyCoupon, setApplyCoupon] = useState();
  // console.log("cart item in state", applyCoupon);

  // const demo=[{}]
  // console.log(demo.length)
  // answer is 1

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function percentage(num) {
    if (applyCoupon) {
      if (num > applyCoupon.minimum_order) {
        let per = num * (applyCoupon.discount_percentage / 100);
        return num - per;
      } else {
        return num;
      }
    } else {
      return num;
    }
  }

  const promoApplyhandel = (e) => {
    // console.log(couponInpValue)
    let dd = coupon.filter(
      (res) => res.promo_code === couponInpValue && res.minimum_order <= total
    );
    // console.log(dd)
    setApplyCoupon(dd[0]);
    setCouponInpValue("");
    // console.log(e.target.value);
  };

  const handlePromo = (e) => {
    setCouponInpValue(e.target.value);
    // console.log(total)

    let dd = coupon.filter(
      (res) => res.promo_code === e.target.value && res.minimum_order <= total
    );
    // console.log(dd)
    // console.log(coupon, "data");
    if (dd.length > 0) {
      document.getElementById("promo-btn").style.color = "#DA7B02";
    } else {
      document.getElementById("promo-btn").style.color = "black";
    }
  };

  const getAllCoupon = () => {
    axios
      .get(`${apis.baseUrl}promo/data/getPromoWeb`)
      .then((res) => {
        // console.log(res);
        setAllCoupon(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchCart = () => {
    getCart()
      .then((res) => {
        // console.log("cart Items from backend ", res);
        setCartItems(res.data);
        console.log(res.data);
       // console.log("quantity"+res.quantity);
        if (applyCoupon) {
          let num = 0;
          res.data.forEach((r) => {
            console.log("quantity"+r.quantity);
            num += r.data[0].MRP * r.quantity;
          });
          if (applyCoupon.minimum_order <= num) {
          } else {
            setApplyCoupon();
          }
        }

        // console.log("cart Items in state in fetch api", cartItems);

        setLoader(false);
      })
      .catch((error) => console.log("error is", error));
  };

  const increaseQuantity = (data) => {
    // console.log(data.quantity + 1);
    editProductQuantity({
      product_id: data.productId,
      quantity: data.quantity + 1,
    })
      .then((res) => {
        // console.log("increased quantity ", res);
        setIsQntyChanged(true);
      })
      .catch((error) => console.log("error", error));
  };
  // console.log(localStorage.getItem("userId"));

  const decreaseQuantity = (data) => {
    editProductQuantity({
      product_id: data.productId,
      quantity: data.quantity - 1,
    })
      .then((res) => {
        // console.log("decreased quantity ", res);
        setIsQntyChanged(true);
      })
      .catch((error) => console.log("error", error));
  };

  const deleteFromCart = (dataa) => {
    // console.log("deleted before api call");

    removeFromCart({
      data: [dataa],
    })
      .then((res) => {
        // console.log("deleted from ", res);
        props.changeCartLen((prevVal) => prevVal - 1);
        setIsQntyChanged(true);
      })
      .catch((error) => console.log("error is", error));
  };

  function checkcouponAfterApplied(coupon) {
    console.log(coupon);
    console.log(total);
  }

  async function getAppliedCoupon() {
    let couponId = localStorage.getItem("couponMongoId");
    if (couponId) {
      try {
        let res3 = await axios.get(apis.baseUrl + "promo/" + couponId);
        console.log(res3.data.data);
        setApplyCoupon(res3.data.data);
        checkcouponAfterApplied(res3.data.data);
      } catch (er) {
        console.log(er);
      }
    }
  }

  useEffect(() => {
    fetchCart();
    // console.log(typeof localStorage.getItem("userAddress"));
    // window.scrollTo(0, 0);

    props.setHeaderColor(false);
    return () => {
      setIsQntyChanged(false);
    };
  }, [isQntyChanged]);

  useEffect(() => {
    getAppliedCoupon();
  }, []);

  useEffect(() => {
    calculateTotal();
    getAllCoupon();
  }, [cartItems]);

  useEffect(() => {
    calculateCartLen();
  }, [cartItems]);

  function calculateCartLen() {
    if (cartItems.length === 0) {
      // console.log("returned from log in calculate  cart len");
      return;
    } else {
      const cartLenTotal = cartItems.reduce(
        (acc, item) => acc + item?.quantity,
        0
      );
      setCartLen(cartLenTotal);
      // console.log(cartLen, "total cart lenth");
    }
  }

  function calculateTotal() {
    // for no cart items in cart
    if (total === 0 && cartItems.length === 0) {
      // console.log("returned from log in calculate total");
      return;
    } else {
      const calculatedTotal = cartItems.reduce(
        (acc, item) => acc + item?.quantity * item?.data[0]?.MRP,
        0
      );
      setTotal(calculatedTotal);
      // console.log(calculatedTotal, "total");
    }
  }
  // console.log(props.userAddress)

  sessionStorage.setItem("cartLength", cartLen);
  if (cartItems.length === 0) {
    return (
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* main div */}
        {!loader ? (
          <div className="container pt-3 pb-4" style={{ minHeight: "70vh" }}>
            {/* navbar div */}
            <span className="px-1">
              <span
                className=" "
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
              >
                Home
              </span>{" "}
              <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
              <span className="">Your Cart</span>
            </span>

            <Box className="pt-5 pb-3 d-flex justify-content-center">
              <RemoveShoppingCartOutlinedIcon
                sx={{ fontSize: "10vw" }}
              ></RemoveShoppingCartOutlinedIcon>
            </Box>
            <Box className="d-flex justify-content-center">
              <p className="text fs-4 ">
                Your Cart is
                <span className="text fs-4 " style={{ color: "red" }}>
                  {" "}
                  Empty!
                </span>
              </p>
            </Box>
            <Box className=" mx-auto btnn" sx={{ width: "15%" }}>
              <button
                className=" text btn goto-home-btn "
                onClick={() => navigate("/")}
              >
                Go to Home
              </button>
            </Box>
            <Box></Box>
          </div>
        ) : (
          <div className="customH"></div>
        )}
      </>
    );
  }
  if (cartItems.length !== 0) {
    return (
      <>
        {/* main div */}
        {!loader ? (
          <>
            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth={"md"}
              fullWidth={true}
            >
              <div>
                <div
                  className="d-flex justify-content-center"
                  style={{
                    padding: "10px 10px 0 10px",
                  }}
                >
                  <div className="coupon_heading">
                    <p>Coupons</p>
                  </div>

                  <div className="close_icon">
                    {/* <img src="images/closecoupon.png" /> */}
                    <HighlightOffIcon
                      onClick={handleClose}
                      className="pointer"
                    />
                  </div>
                </div>
                <hr style={{ margin: "0 200px 0 180px" }} />
              </div>
              <div
                className="container "
                style={{
                  justifyContent: "space-evenly",
                  borderRadius: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "10px",
                  marginTop: "4px",
                  background: "rgba(255, 255, 255, 1",
                }}
              >
                {coupon.map((item) => (
                  <>
                    {/* {console.log(item, "data item")} */}
                    <div
                      className="coupon_card"
                      style={{
                        borderRadius: "20px",
                        background:
                          total <= item.minimum_order
                            ? "rgb(230, 229, 229)"
                            : "white",
                      }}
                    >
                      <div className="discount-icon-title-div">
                        <img
                          src="/images/discount1.png"
                          alt=""
                          style={{ width: "20px", height: "20px" }}
                        />
                        <h6
                          className=""
                          style={{ textAlign: "center", marginBottom: "0px" }}
                        >
                          <b>
                            {item.discount_percentage}%
                            <span
                              style={{ fontSize: "14px", fontWeight: "600" }}
                            >
                              &nbsp; Off on min. order
                            </span>{" "}
                            <br /> &#8377;{item.minimum_order}
                          </b>
                        </h6>
                      </div>

                      <div className="apply-btn">
                        {total <= item.minimum_order ? (
                          <div style={{ height: "30px" }}></div>
                        ) : (
                          <button
                            className="coupon_btn mt-2"
                            disabled={total <= item.minimum_order}
                            onClick={() => {
                              setApplyCoupon(item);
                              setOpen(false);
                            }}
                          >
                            <b> APPLY</b>
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </Dialog>
            <div
              className="px-lg-5 px-md-3 px-1 py-3"
              style={{ minHeight: "70vh" }}
            >
              {/* breadcrumb div */}
              <span className="px-1  ">
                <span
                  className=""
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                >
                  Home
                </span>{" "}
                <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
                <span className="">Your Cart</span>
              </span>
              <h2 className="py-3 px-1 heading1">Your Cart</h2>
              <div className="row m-0">
                {/* {matches1 ? ( */}
                <div className="col-lg-8 col-12 p-sm-4 p-0">
                  <div className="container-fluid">
                    {cartItems.map((item, index) => (
                      <div className="row bottom-border py-1" key={index}>
                        <div className="col-md-3 col-4 p-2">
                          <div className="img_border p-2 ">
                            <img
                              onClick={() =>
                                navigate(`/product/${item.product_id}`)
                              }
                              style={{ cursor: "pointer" }}
                              src={item?.data[0]?.image[0]}
                              alt=""
                              className="img"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-8  py-md-3">
                          <h6 className=" text-capitalize">
                            {item?.data[0]?.product_name}
                          </h6>
                          {/* <span className="text">Brand: MGP</span> */}
                          {/* <br /> */}
                          <span className="text">
                            {`KE Reference No:${item?.data[0]?.ke_partNumber}
                        `}
                          </span>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4 col-12  px-1 py-3">
                          <div className="d-flex justify-content-between align-items-center  mrpWid ">
                            <span className="text me-sm-0 me-md-2 me-lg-0 ">
                              <b>MRP</b>
                            </span>
                            {/* old code */}
                            {/* <span className="text33 me-3 me-sm-3 me-md-0 me-lg-4">
                         ₹ {item?.data[0]?.MRP}
                          </span> */}
                            <span className="text33  me-3 me-sm-3 me-md-0 me-lg-4  ">
                              ₹ {item?.data[0]?.MRP}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between justify-content-md-between  justify-content-sm-between">
                            {/* <span className="text d-flex align-items-center"> */}
                            <span className="quantiy me-sm-0 me-md-2 me-lg-0 ">
                              <b>Quantity</b>
                            </span>
                            {/* </span> */}
                            <span className="quality_border d-flex align-items-center p-0 m-0">
                              {/* dynamic */}
                              {item?.quantity === 1 ? (
                                <span className="text s1-box pointer mrp-btn spanBgCol">
                                  -
                                </span>
                              ) : (
                                <span
                                  className="text s1-box pointer mrp-btn "
                                  onClick={() => {
                                    item?.quantity === 1
                                      ? deleteFromCart(item?.product_id)
                                      : decreaseQuantity({
                                          productId: item?._id,
                                          quantity: item?.quantity,
                                        });
                                  }}
                                >
                                  -
                                </span>
                              )}
                              {/* old static */}
                              {/* <span
                              className="text s1-box pointer spanBgCol"
                              onClick={() => {
                                item?.quantity === 1
                                  ? // console.log("qty 1")
                                    deleteFromCart(item?.product_id)
                                  : decreaseQuantity({
                                      productId: item?._id,
                                      quantity: item?.quantity,
                                    });
                              }}
                            >
                              -
                            </span> */}
                              <span className="text s2-box">
                                {item?.quantity}
                              </span>
                              <span
                                className="text s3-box pointer mrp-btn-plus"
                                onClick={() => {
                                  increaseQuantity({
                                    productId: item?._id,
                                    quantity: item?.quantity,
                                  });
                                  console.log("clicked in increase");
                                }}
                              >
                                +
                              </span>
                            </span>
                          </div>
                          {/* dynamic */}
                          {/* {item?.quantity === 1 ? (
                          <div className="removeItemBtnDiv text-center mt-3 removeItemBtnDivDim ">
                            <button
                              class="removeItemBtn"
                              onClick={() => deleteFromCart(item?.product_id)}
                            >
                              Remove Item
                            </button>
                          </div>
                        ) : (
                          ""
                        )} */}

                          {/* static */}
                          <div className="removeItemBtnDiv removeItemBtnDivDim  text-center mt-3 ">
                            <button
                              class="removeItemBtn"
                              onClick={() => deleteFromCart(item?.product_id)}
                            >
                              Remove Item
                            </button>
                            {/* <h6>{`₹${item?.data[0]?.MRP * item?.quantity}`}</h6> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* // ) : (
                //   ""
                // )} */}

                {/* {matches1 ? (
                  ""
                ) : (
                  <Grid sx={{ border: '6px solid blue' }} item md={12} sm={12} className="border border-danger">
                    <div className="container-fluid py-2">
                      {cartItems.map((item, index) => (
                        <div className="row bottom-border  py-1" key={index}>
                          <div className="col-md-3 col-4 p-2">
                            <div className="img_border p-2 ">
                              <img
                                onClick={() =>
                                  navigate(`/product/${item.product_id}`)
                                }
                                style={{ cursor: "pointer" }}
                                src={item?.data[0]?.image[0]}
                                alt=""
                                className="img"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-8  py-md-3">
                            <h6 className=" text-capitalize">
                              {item?.data[0]?.product_name}
                            </h6>

                            <span className="text">
                              {`KE Referance No:${item?.data[0]?.ke_partNumber}
                        `}
                            </span>
                          </div>
                          <div className="col-md-1"></div>
                          <div className="col-md-4 col-12  px-1 py-3">
                            <div className="d-flex justify-content-between align-items-center  mrpWid ">
                              <span className="text me-sm-0 me-md-2 me-lg-0 ">
                                <b>MRP</b>
                              </span>

                              <span className="text33  me-3 me-sm-3 me-md-0 me-lg-4  ">
                                ₹ {item?.data[0]?.MRP}
                              </span>
                            </div>
                            <div className="d-flex justify-content-between justify-content-md-between  justify-content-sm-between">

                              <span className="quantiy me-sm-0 me-md-2 me-lg-0 ">
                                <b>Quantity</b>
                              </span>

                              <span className="quality_border d-flex align-items-center p-0 m-0">

                                {item?.quantity === 1 ? (
                                  <span className="text s1-box pointer spanBgCol">
                                    -
                                  </span>
                                ) : (
                                  <span
                                    className="text s1-box pointer "
                                    onClick={() => {
                                      item?.quantity === 1
                                        ? deleteFromCart(item?.product_id)
                                        : decreaseQuantity({
                                          productId: item?._id,
                                          quantity: item?.quantity,
                                        });
                                    }}
                                  >
                                    -
                                  </span>
                                )}

                                <span className="text s2-box">
                                  {item?.quantity}
                                </span>
                                <span
                                  className="text s3-box pointer"
                                  onClick={() => {
                                    increaseQuantity({
                                      productId: item?._id,
                                      quantity: item?.quantity,
                                    });
                                    console.log("clicked in increase");
                                  }}
                                >
                                  +
                                </span>
                              </span>
                            </div>



                            <div className="removeItemBtnDiv removeItemBtnDivDim  text-center mt-3 ">
                              <button
                                class="removeItemBtn"
                                onClick={() => deleteFromCart(item?.product_id)}
                              >
                                class Remove Item
                              </button>

                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Grid>
                )} */}

                {/* {matches1 ? ( */}
                <div className="col-lg-4 col-12 p-sm-4 p-0">
                  <div
                    className={`${matches ? " cart_border" : ""} w-100 p-0 m-0`}
                  >
                    <div className="text-center  mx-3 pt-3 ">
                      {matches ? <h5>ORDER SUMMARY</h5> : ""}
                    </div>
                    {matches ? <hr className="mx-4" /> : ""}
                    <div className="container-fluid px-4">
                      {matches
                        ? cartItems.map((item, index) => (
                            <div className="row my-2" key={index}>
                              <div className="col-md-3 col-12 p-2 ">
                                <div className="">
                                  <img
                                    src={item?.data[0]?.image[0]}
                                    alt=""
                                    className="img"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-12 px-0 ">
                                <h6 className="text-1 text-capitalize pt-3">
                                  {item?.data[0]?.product_name}
                                </h6>
                                {/* <span className="text-1  negPadd"> */}
                                <div className="text-1 negPadd">
                                  {" "}
                                  Quantity - {item?.quantity}
                                </div>
                                {/* </span> */}
                              </div>
                              <div className="col-md-3 p-3 col-12">
                                <div className="">
                                  <h6 className="text-2">{`₹${
                                    item?.data[0]?.MRP * item?.quantity
                                  }`}</h6>
                                </div>
                              </div>
                            </div>
                          ))
                        : ""}

                      {matches ? <hr className="" /> : ""}
                      {!applyCoupon ? (
                        <div className="promo-apply-input-div">
                          {" "}
                          <input
                            id="promoInput"
                            type="text"
                            placeholder="Type promo code here"
                            className="promo-apply-input-div-input"
                            onChange={handlePromo}
                            value={couponInpValue}
                          />{" "}
                          <span>
                            {" "}
                            <span
                              className="apply-promo-btn"
                              id="promo-btn"
                              onClick={promoApplyhandel}
                            >
                              Apply
                            </span>
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className={`apply_coupon p-2`}>
                        {applyCoupon ? (
                          <>
                            <div>
                              <div
                                className="ml-2 mt-1"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                {/* <div style={{ width: "50%" }}></div> */}
                                <div
                                  onClick={handleClickOpen}
                                  className="pointer applied_coupon_con"
                                >
                                  <img
                                    src="/images/discount1.png"
                                    alt=""
                                    style={{ width: "24px", height: "24px" }}
                                    className="me-2"
                                  />
                                  {applyCoupon.discount_percentage}% Off on min.
                                  order ₹{applyCoupon.minimum_order}
                                </div>
                                {/* <div style={{ width: "50%" }}> */}
                                <div
                                  style={{
                                    color: "#DA7B02",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    // width: "80%",
                                  }}
                                  onClick={() => {
                                    setApplyCoupon("");
                                    localStorage.removeItem("couponMongoId");
                                  }}
                                  className="pointer pb-1"
                                >
                                  <span
                                    style={{
                                      color: "#DA7B02",
                                      fontSize: "16px",
                                    }}
                                  >
                                    | Remove
                                  </span>
                                </div>
                                {/* </div> */}
                              </div>
                            </div>
                            <p
                              className="m-0"
                              style={{
                                textAlign: "center",
                                color: "rgba(0, 0, 0, 0.4)",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Applied
                            </p>
                          </>
                        ) : (
                          <>
                            <div className="apply-coupon-div p-0">
                              <div className="">
                                <img src="images/applycoupon.png" />
                              </div>
                              <div className="ml-2">
                                <span
                                  onClick={handleClickOpen}
                                  className="pointer"
                                >
                                  {" "}
                                  Apply Coupon
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <br />
                      {applyCoupon ? (
                        <>
                          <div className="d-flex justify-content-between px-md-2">
                            <h6>Sub-Total</h6>
                            <h6>{total}</h6>
                          </div>
                          <div
                            className="d-flex justify-content-between px-md-2"
                            style={{ color: "#da7b02" }}
                          >
                            <h6>Coupon Applied</h6>
                            <h6>{percentage(total).toFixed(0) - total}</h6>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <hr className="mx-4" />
                      <div className="d-flex justify-content-between px-md-2 ">
                        <h6>Net Payable</h6>
                        <h6> ₹{percentage(total).toFixed(0)}</h6>
                      </div>

                      <button
                        className="w-100 btn btn-cart mt-2 mb-4"
                        onClick={() => {
                          navigate(
                            props.userAddress === "true"
                              ? "/checkOut"
                              : "/checkoutaddress"
                          );
                          localStorage.setItem("totalPrice", percentage(total));
                          if (applyCoupon) {
                            localStorage.setItem(
                              "couponMongoId",
                              applyCoupon._id
                            );
                          } else {
                            localStorage.removeItem("couponMongoId");
                          }
                        }}
                      >
                        CHECKOUT
                      </button>
                    </div>
                  </div>
                </div>
                {/* ) : (
                  ""
                )} */}
              </div>

              {/* <Grid container p={0}>
                {matches1 ? (
                  ""
                ) : (
                  <Grid item md={12} sm={12} xs={12} className="px-md-2">
                    <div
                      className={`${matches ? " cart_border" : ""
                        } w-100 p-0 m-0`}
                    >
                      <div className="text-center  mx-3 pt-3 ">
                        {matches ? <h5>ORDER SUMMARY</h5> : ""}
                      </div>
                      {matches ? <hr className="mx-4" /> : ""}
                      <div className="container-fluid px-4">
                        {matches
                          ? cartItems.map((item, index) => (
                            <div className="row my-2" key={index}>
                              <div className="col-md-3 col-12 p-2 ">
                                <div className="">
                                  <img
                                    src={item?.data[0]?.image[0]}
                                    alt=""
                                    className="img"
                                  />
                                </div>
                              </div>
                              <div
                                className="col-md-6 col-12 px-0 product_quantity"
                                style={{ textAlign: "center" }}
                              >
                                <h3 className=" text-capitalize">
                                  {item?.data[0]?.product_name}
                                </h3>
                                <h3 className=" negPadd">
                                  {" "}
                                  Quantity - {item?.quantity}
                                </h3>
                              </div>
                              <div className="col-md-3 p-3 col-12">
                                <div
                                  className=""
                                  style={{ textAlign: "center" }}
                                >
                                  <h3 className="ngpadd1">{`₹${item?.data[0]?.MRP * item?.quantity
                                    }`}</h3>
                                </div>
                              </div>
                            </div>
                          ))
                          : ""}
                        {matches ? <hr className="" /> : ""}

                        <div className="apply_coupon">
                          {applyCoupon ? (
                            <>
                              <div>
                                <div
                                  className="ml-2 mt-1 "
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <h6
                                    onClick={handleClickOpen}
                                    className="pointer"
                                  >
                                    {applyCoupon.discount_percentage} OFF up to{" "}
                                    {applyCoupon.minimum_order}
                                  </h6>
                                  <h6
                                    style={{ color: "#DA7B02" }}
                                    onClick={() => {
                                      setApplyCoupon("");
                                    }}
                                    className="pointer"
                                  >
                                    Remove
                                  </h6>
                                </div>
                              </div>
                              <p style={{ textAlign: "center" }}>Applied</p>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingBottom: "5px",
                                }}
                              >
                                <div className="mt-0">
                                  <img src="images/applycoupon.png" />
                                </div>
                                <div className="ml-2 text-light">
                                  <span
                                    onClick={handleClickOpen}
                                    className="pointer "
                                  >
                                    Apply Coupon
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <br />
                        <div className="d-flex justify-content-between px-md-2">
                          <h6>TOTAL</h6>
                          <h6>{percentage(total).toFixed(0)}</h6>
                        </div>

                        <button
                          className="w-100 btn btn-cart mt-2 mb-4"
                          onClick={() => {
                            navigate(
                              props.userAddress === "true"
                                ? "/checkOut"
                                : "/checkoutaddress"
                            );
                            localStorage.setItem(
                              "totalPrice",
                              percentage(total)
                            );
                          }}
                        >
                          CHECKOUT
                        </button>
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid> */}
            </div>
          </>
        ) : (
          <Box
            className="d-flex  justify-content-center align-items-center "
            sx={{
              height: "400px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </>
    );
  }
}
