import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import { Home } from "./componants/Home/Home";
import { Header } from "./componants/Header/Header";
import { Footer } from "./componants/Footer/Footer";
import Products from "./componants/Pages/Products";
import Product from "./componants/Pages/Product";
import Brand from "./componants/Pages/Brand";
import Model from "./componants/Pages/Model";
import AccountDetails from "./componants/AccountDetails/AccountDetails";
import { MyOrder } from "./componants/MyOrders/MyOrder";
import { About } from "./componants/About/About";
import { ContactUs } from "./componants/ContactUs/ContactUs";
import { Privacy } from "./componants/Privacy/Privacy";
import { TermCondition } from "./componants/TermConditions/TermCondition";
import Cart from "./componants/Cart/Cart";
import { CheckOut } from "./componants/CheckOut/CheckOut";
import Address from "./componants/Address/Address";
import Sidebar from "./componants/Sidebarr/Sidebar";
import Newaddress from "./componants/Address/Newaddress";
import ResetPassword from "./componants/ResetPassword/ResetPassword";
import EditAddress from "./componants/Address/EditAddress";
import { getCartLen } from "./Services/AllAPIs";
import CheckOutAddress from "./componants/CheckOut/CheckOutAddress";
import CheckOutEditAddress from "./componants/CheckOut/CheckOutEditAddress";
import CheckoutAnother from "./componants/CheckOut/CheckoutAnother";
import ApplyCoupon from "./componants/Cart/ApplyCoupon";
import { CheckLoginAuth } from "./Auth/CheckLoginAuth";
import ForgotPassword from "./componants/ForgotPassword/ForgotPassword";
import ConfirmationPage from "./componants/ForgotPassword/ConfirmationPage";
import  Loadingg  from "./componants/Loader/Loadingg";


export const Router = () => {

  // for cart length
  const [cartLen, setCartLen] = useState(0)
  const [open, setOpen] = useState(false)
  const [logginCheck, setLoginCheck] = useState(false)
  const [headerColor, setHeaderColor] = useState(true)
  const [showButton, setShowButton] = useState(false);
  const [userAddress, setUserAddress] = useState(localStorage.getItem("userAddress"));



  const fetchCartLen = () => {
    getCartLen()
      .then((res) => {
        
        setCartLen(res.data.data);

      })
      .catch((error) => console.log("error is", error));
  };
  useEffect(() => {
    fetchCartLen()
  
    sessionStorage.removeItem("filter");
  }, [])

  //
  return (
    <>
      <BrowserRouter>
      {window.location.pathname.includes('/forgot-password/') || window.location.pathname.includes('/confirmation') ?null:
        <Header
          cartLength={cartLen}
          setOpen={setOpen}
          open={open}
          setLoginCheck={setLoginCheck}
          headerColor={headerColor}
          setUserAddress={setUserAddress}
        />}
        <Routes>
          <Route exact path="/loading" element={<Loadingg/>}></Route> 


          <Route path="/" element={<Home setHeaderColor={setHeaderColor} />} />
          <Route
            path="/products/:id"
            element={<Products setHeaderColor={setHeaderColor} />}
          />
          <Route
            path="/product/:id"
            element={
              <Product
                setHeaderColor={setHeaderColor}
                changeCartLen={setCartLen}
                setOpen={setOpen}
                logginCheck={logginCheck}
              />
            }
          />
          <Route
            path="/brand/:id"
            element={<Brand setHeaderColor={setHeaderColor} />}
          />
          <Route
            path="/model/:id"
            element={<Model setHeaderColor={setHeaderColor} />}
          />

          {/* all routes which needs to logged in  */}
          <Route element={<CheckLoginAuth />} path='/'>
            <Route
              path="/accountDetails"
              element={
                <AccountDetails
                  setHeaderColor={setHeaderColor}
                  userAddress={userAddress}
                />
              }
            />
            <Route
              path="/address"
              element={
                <Address
                  userAddress={userAddress}
                  setUserAddress={setUserAddress}
                  setHeaderColor={setHeaderColor}
                  setShowButton={setShowButton}
                />
              }
            />
            <Route
              path="/myorder"
              element={
                <MyOrder
                  setHeaderColor={setHeaderColor}
                  userAddress={userAddress}
                />
              }
            />
            <Route
              path="/new-address"
              element={
                <Newaddress
                  userAddress={userAddress}
                  setHeaderColor={setHeaderColor}
                  showButton={showButton}
                  setUserAddress={setUserAddress}
                />
              }
            />

          </Route>

          <Route
            path="/edit-address"
            element={
              <EditAddress
                setHeaderColor={setHeaderColor}
                userAddress={userAddress}
              />
            }
          />

          <Route
            path="/cart"
            element={
              <Cart
                userAddress={userAddress}
                setHeaderColor={setHeaderColor}
                changeCartLen={setCartLen}
              />
            }
          />
          <Route path="/apply_coupon_page" element={<ApplyCoupon />} />
          <Route
            path="/about"
            element={<About setHeaderColor={setHeaderColor} />}
          />
          <Route
            path="/contactUs"
            element={<ContactUs setHeaderColor={setHeaderColor} />}
          />
          <Route
            path="/privacy"
            element={<Privacy setHeaderColor={setHeaderColor} />}
          />
          <Route
            path="/termCondition"
            element={<TermCondition setHeaderColor={setHeaderColor} />}
          />
          <Route
            path="/checkOut"
            element={<CheckOut setHeaderColor={setHeaderColor} />}
          />
          <Route
            path="/sidebar"
            element={
              <Sidebar
                setHeaderColor={setHeaderColor}
                userAddress={userAddress}
              />
            }
          />
          <Route
            path="/reset-password/:email/:token/:id"
            element={<ResetPassword setHeaderColor={setHeaderColor} />}
          />
          <Route
            path="/forgot-password/:email/:token/:id"
            element={<ForgotPassword setHeaderColor={setHeaderColor} />}
          />
          <Route
            path="/confirmation"
            element={<ConfirmationPage setHeaderColor={setHeaderColor} />}
          />
          <Route
            path="/checkoutaddress"
            element={
              <CheckOutAddress
                setHeaderColor={setHeaderColor}
                setUserAddress={setUserAddress}
              />
            }
          />
          <Route
            path="/edit-checkout"
            element={<CheckOutEditAddress setHeaderColor={setHeaderColor} />}
          />
          <Route
            path="/another-checkout"
            element={<CheckoutAnother setHeaderColor={setHeaderColor} />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}