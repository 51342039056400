import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebarr/Sidebar";
import styles from "./Address.module.css";
import { useNavigate } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { getAddress, deleteAddress } from "../../Services/AllAPIs";
import { Backdrop, Box, CircularProgress, Radio } from "@mui/material";
import { Link } from "react-router-dom";
import Newaddress from "./Newaddress";
function Address(props) {
  const navigate = useNavigate();
  // const isAddressAvailable = true;
  const [userAdd, setUserAdd] = useState(props.userAddress);
  const [addressLen, setAddressLen] = useState();
  const [addresses, setAddresses] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isAddressDeleted, setIsAddressDeleted] = useState(false);
  const [selectAddress, setSelectAddress] = useState("");

  // const [addressCheck, setAddressCheck] = useState(localStorage.Item(""));

  const handleChangeToggle = (event) => {
    setSelectAddress(event._id);
  };

  // console.log(addresses);
  // console.log(addressLen);

  function getAddressList() {
    getAddress()
      .then((res) => {
        // console.log(" addresses from backend ", res);
        setAddresses(res.data);
        setLoader(false);
        localStorage.setItem("adssLen", res.data.length);
        setAddressLen(res.data.length);
      })
      .catch((error) => setLoader(false));
  }

  const deleteAddressHandle = (dataa, index) => {
    setLoader(true);
    deleteAddress({
      address_id: dataa,
    })
      .then((res) => {
        // console.log("deleted from ", res);
        setIsAddressDeleted(true);
        setLoader(false);
        if (addressLen == 1) {
          localStorage.setItem("userAddress", false);
          localStorage.setItem("adssLen", localStorage.getItem("adssLen") - 1);
          props.setUserAddress("false");
          navigate("/new-address");
        }
      })
      .catch((error) => console.log("error is", error));
  };

  useEffect(() => {
    props.setHeaderColor(false);
    if (setAddressLen === 0) {
      props.setShowButton(false);
      // props.SetShowAdd(false);
    } else {
      props.setShowButton(true);
      // props.SetShowAdd(true);
    }
    getAddressList();
    return () => {
      setIsAddressDeleted(false);
    };
  }, [isAddressDeleted]);

  const obj = {
    bg: "#f1f1f1",
    text: "#2A2381",
  };

  return (
    <Sidebar two={obj} userAddress={userAdd}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* main div */}
      {!loader ? (
        <div className="pt-2">
          <div className="border border-2 p-sm-5 p-2">
            <div>{/* <h3>Saved Address</h3> */}</div>
            {/* no address */}
            {/* {!isAddressAvailable ? ( */}
            {addressLen === 0 ? (
              // <Newaddress/>
              <>
                <div className="pt-2">
                  <h5>No Address Yet!</h5>
                </div>
              </>
            ) : (
              ""
            )}
            {/* address list */}
            {/* {isAddressAvailable ? ( */}

            {addressLen !== 0 ? (
              <>
                {addresses.map((item, index) => (
                  <>
                    <div className="bg-light p-2">
                      <div className="d-flex pb-2">
                        {/* <div className=" my-auto ps-3">
                  <CircleIcon/></div> */}
                        <div className="  ">
                          <h5>
                            <Radio
                              value={item?._id}
                              checked={selectAddress === item?._id}
                              name="radio-buttons"
                              onChange={() => {
                                handleChangeToggle(item);
                              }}
                            ></Radio>
                            DELIVERY ADDRESS {index ? index + 1 : "1"}
                          </h5>
                          <h6
                            className={`${styles.addresss}`}
                            style={{ border: "" }}
                          >
                            {`${item?.name},${item?.mobile_number},`}
                          </h6>
                          <h6
                            className={`${styles.addresss}`}
                            style={{ border: "" }}
                          >
                            {`${item?.house_number} ,${item?.city},${item?.state} ,${item?.pincode}, ${item?.country} `}
                          </h6>
                          <h6>{``}</h6>
                        </div>
                      </div>
                      <div
                        className={` w-100  px-md-2 px-sm-0 px-3 ${styles.mainbtndiv}`}
                      >
                        <div className={`d-sm-flex justify-content-end mb-3`}>
                          <div className="">
                            <Link
                              to="/new-address"
                              className=""
                              state={{ item }}
                            >
                              {" "}
                              <button className={`${styles.saveChanges1} `}>
                                <b>ADD ANOTHER ADDRESS</b>
                              </button>
                            </Link>
                          </div>
                          <div className="mx-md-4 mx-sm-2 mx-0 my-sm-0 my-3">
                            <Link to="/edit-address" state={{ item }}>
                              {" "}
                              <button className={`${styles.saveChanges1}`}>
                                <b>EDIT ADDRESS</b>
                              </button>
                            </Link>
                          </div>
                          <div className="">
                            <button
                              className={` ${styles.saveChanges1}`}
                              onClick={() =>
                                deleteAddressHandle(item?._id, index)
                              }
                            >
                              <b>DELETE ADDRESS</b>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br></br>
                  </>
                ))}
              </>
            ) : (
              ""
            )}

            {/* <div
              className={`${styles.divBtn} d-flex justify-content-xl-end  x justify-content-md-start pe-5 `}
            >
              <button
                onClick={() => navigate("/new-address")}
                className={styles.bttn}
              >
                ADD NEW ADDRESS
              </button>
            </div> */}
          </div>
          {addressLen === 0 ? (
            // <Newaddress/>
            <>
              <div className="pt-2 d-flex justify-content-end">
                <Link to="/new-address">
                  <button className={`${styles.saveChanges1}`}>
                    ADD ADDRESS
                  </button>
                </Link>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="customH"></div>
      )}

      {/* old code */}
      {/* main div */}
      {/* <div
        className={`${styles.cont} 
 
   flex-fill px-5 mb-5 bg-info `}
      >
        <div
          className="
 
    mt-4"
        >
          <label>DELIVERY ADDRESS</label>
          <input className={`${styles.addInp} form-control`} />
        </div>

        <div className="d-md-flex gap-5 mt-4">
          <div className="w-md-50 w-100">
            <label>CITY</label>
            <input className="form-control" />
          </div>
          <div className="w-md-50 w-100">
            <label>STATE</label>
            <input className="form-control" />
          </div>
        </div>
        <div className="d-md-flex gap-5 mt-4 ">
          <div className="w-md-50 w-100">
            <label>COUNTRY</label>
            <input className="form-control" />
          </div>
          <div className="w-md-50 w-100">
            <label>PIN CODE</label>
            <input className="form-control" />
          </div>
        </div>

        <div className={`${styles.divBtn} d-flex justify-content-end`}>
          <button
            className={styles.firstbtn}
            onClick={() => navigate("/new-address")}
          >
            ADD ADDRESS
          </button>
          <button className={styles.secondbtn}>SAVE CHANGES</button>
        </div>
      </div> */}
    </Sidebar>
  );
}

export default Address;
