import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Dialog,
  Grid,
  useMediaQuery,
  Badge,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./Header.css";
import CloseIcon from "@mui/icons-material/Close";
import "./Form.css";
import { useNavigate } from "react-router";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Fade from "@mui/material/Fade";
import {
  OtpVerify,
  Userlogin,
  signUpUser,
  userOTPP,
  forgetPassword,
  signoutUser,
  searchData,
  addOtp,
  getBymobileNumber,
} from "../../Services/AllAPIs";
import Otpinput from "./OTPinput";
import Loadingg from "../Loader/Loadingg";
const style = {
  position: "absolute",
  top: "23%",
  right: "0",
  transform: "translate(-50%, -50%)",
  width: 160,
  background: "white",
  // bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0 0 10px 10px",
  p: 4,
  border: "none",
  outline: "none",
};
export const Header = (props) => {
  //for cart length
  // const [cartLength, setCartLength] = useState(0)
  // const handleCartLength = (newValue) => {
  //   setCartLength(newValue);
  // };
  // console.log(cartLength,"cart len in header")
  //..
  const [open1, setOpen1] = React.useState(false);
  const [text, setText] = React.useState("");
  const [OTP, setOTP] = useState("");
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [loginCheck, setLoginCheck] = useState(
    localStorage.getItem("IsLoggin")
  );

  const matches = useMediaQuery("(min-width:900px)");
  // const [open, setOpen] = useState(false);
  const [showSendMail, setShowSendMail] = useState(true);
  const [toggleBar, setToggleBar] = useState(false);
  const [Form, setForm] = useState(1);
  const [button1, setButton1] = useState(false);
  const [button2, setButton2] = useState(true);
  const [button3, setButton3] = useState(true);
  const [button4, setButton4] = useState(true);
  const [pass1, setPass1] = useState(true);
  const [emailCheck, setEmailCheck] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isVisible1, setVisible1] = useState(false);
  const [isVisible2, setVisible2] = useState(false);
  
  const toggle = () => {
    setVisible(!isVisible);
  };
  const toggle1 = () => {
    setVisible1(!isVisible1);
  };
  const toggle2 = () => {
    setVisible2(!isVisible2);
  };
  let otps = [];
  const passCheck1 = () => {
    if (loginSignupData.current.password?.length >= 8) {
      setPass1(false);
    } else {
      setPass1(true);
    }
  };
  const searching = async () => {
    setText("");
    if (text.length !== 0) {
      setLoader(true);
      await searchData().then((res) => {
        if (res.data.data.length !== 0) {
          const data = res.data.data;
          const search = data.filter((e) =>
            e.keyWords.includes(text.toLowerCase())
          );
          const id = [...new Set(search.map((e) => e.product_model_aaray[0]))];
          sessionStorage.setItem("search", text);

          navigate(`/products/${id.length !== 0 ? id.join() : "noData"}`, {
            state: { array: search },
          });
          setLoader(false);
          window.location.reload();
        } else {
          setLoader(false);
        }
      });
    }
  };
  const passCheckOnEnter1 = () => {
    if (loginSignupData.current.password?.length === 8) {
      setPass1(false);
    }
  };

  let userEmail;
  const loginSignupData = useRef({
    mobile_number: "",
    fullName: "",
    email: "",
    password: "",
    login_password: "",
    login_mobile_number: "",
    otpss: [],
    cPass: "",
  });

  let signUpButton = (e, name) => {
    if (
      loginSignupData.current.fullName &&
      loginSignupData.current.email.length !== 0 &&
      loginSignupData.current.cPass.length >= 8 &&
      loginSignupData.current.password.length >= 8
    ) {
      setButton4(false);
    } else {
      setButton4(true);
    }

    if (name == "name") {
      if (e.target.value == "") {
        setButton4(true);
      }
    } else if (name == "email") {
      if (e.target.value == "") {
        setButton4(true);
      }
    } else if (name == "pass") {
      if (e.target.value == "") {
        setButton4(true);
      }
    } else if (name == "cPass") {
      if (e.target.value == "") {
        setButton4(true);
      }
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();
    // setForm(12);
    // console.log(userEmail.toLowerCase())
    if (userEmail.length !== 0) {
      await forgetPassword({ email: userEmail.toLowerCase(), role: "user" })
        .then((e) => {
          setForm(5);
          setTimeout(() => {
            setForm(1);
            setEmailCheck(false);
          }, 3000);
        })
        .catch((s) => {
          setEmailCheck(true);
          // console.log(s);
        });
    } else {
      setForm(11);
    }
  };

  const [wrongOtpError, setWrongOtp] = useState("");
  const getUserOTP = async (e) => {
    e.preventDefault();
    
    let mobileData = await getBymobileNumber({
      mobile_number: loginSignupData.current.mobile_number,
    });
   
  setLoader(true);
    if (mobileData.data.length === 0) {
      await userOTPP({ mobile_number: loginSignupData.current.mobile_number })
        .then((res) => {
          console.log(res);
         
          setTimeout(()=>{
            setLoader(false);
            setForm(7);
          },5000)
        })
        .catch((error) => console.log(error));
      console.log("hi");
    } else {
      setShow1(true);
      setLoader(false);
    }
  };

  const VerifyUserOTP = (e, otpV) => {
    // console.log(otpV)
    setWrongOtp("");
    e.preventDefault();
    let merzOTP = "0000";
    setPass1(false);
    // setPass2(false)
    setButton3(true);
    OtpVerify({
      mobile_number: loginSignupData.current.mobile_number,
      otp: otpV,
    })
      .then((res) => {
        setWrongOtp("");
        // console.log(res.data);
        setForm(8);
        // loginSignupData.current.otpss = []
        setOTP("");
        // loginSignupData.current.mobile_number = ''
      })
      .catch((error) => {
        setOTP("");
        setWrongOtp("Please Enter Valid Otp");
        console.log("error error error");
        // loginSignupData.current.mobile_number=''
        console.log(error);
      });
  };
  const signUp = (e) => {
    e.preventDefault();

    if (loginSignupData.current.password !== loginSignupData.current.cPass) {
      setShow(true);
    } else {
      signUpUser({
        fullName: loginSignupData.current.fullName,
        mobile_number: loginSignupData.current.mobile_number,
        email: loginSignupData.current.email,
        password: loginSignupData.current.password,
        role: "Customer",
      })
        .then((res) => {
          setForm(9);
          setTimeout(() => {
            localStorage.setItem("userId", res.data.data._id);
            localStorage.setItem("IsLoggin", true);
            onClickCloseAll();
            localStorage.removeItem("pop");
            window.location.reload();
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
          setShow2(true);
        });
    }
  };

  const login = (e) => {
    e.preventDefault();

    // setLoader(true)
    Userlogin({
      mobile_number: loginSignupData.current.login_mobile_number,
      password: loginSignupData.current.login_password,
    })
      .then((res) => {
        props.setOpen(!props.open);
        onClickCloseAll();

        if (res.data.data.address.length !== 0) {
          localStorage.setItem("userAddress", true);
          props.setUserAddress("true");
        } else {
          localStorage.setItem("userAddress", false);
          props.setUserAddress("false");
        }
        localStorage.setItem("userId", res.data.data._id);
        localStorage.setItem("IsLoggin", true);
        localStorage.removeItem("pop");
        props.setLoginCheck(true);
        window.location.reload();
        // setLoader(false)
        loginSignupData.current.login_mobile_number = "";
        loginSignupData.current.login_password = "";
      })
      .catch((error) => {
        console.log(error);
        // setLoader(false)
        setButton1(true);
      });
  };

  const onClickCloseAll = () => {
    setWrongOtp("");
    loginSignupData.current.fullName = "";
    loginSignupData.current.mobile_number = "";
    loginSignupData.current.email = "";
    loginSignupData.current.password = "";
    loginSignupData.current.login_mobile_number = "";
    loginSignupData.current.login_password = "";
    loginSignupData.current.otpss = [];
    setButton1(false);
    props.setOpen(false);
    setShow2(false);
    /* setForm(1); */
  };
  useEffect(() => {
    if (localStorage.getItem("pop") === "1") {
      props.setOpen(!props.open);
      setForm(1);
    }
    return () => {
      setWrongOtp("");
    };
  }, [localStorage.getItem("pop")]);
  const signOut = () => {
    setLoader(true);
    setOpen1(false);
    signoutUser(localStorage.getItem("userId"))
      .then((res) => {
        localStorage.removeItem("userId");
        // localStorage.removeItem("userAddress");
        localStorage.setItem("IsLoggin", false);
        props.setLoginCheck(false);
        navigate("/");
        setLoader(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {}, []);
  const ValUse = () => {
    switch (Form) {
      case 1:
        return (
          <div class="container-fluid main_container p-3 w-100">
            <div class="row justify-content-center my-5 ">
              <div class="form_container col-md-8">
                <h1 class="text-center">Log In</h1>
                <p class="text-center sub_heading mb-5">
                  Hey there, welcome back!
                </p>
                <form onSubmit={login}>
                  <div class="form-group mb-4">
                    <input
                      type="number"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 10))
                      }
                      required
                      class="form-control input_feild py-2 otpStyle"
                      id="phone"
                      defaultValue={loginSignupData.current.login_mobile_number}
                      placeholder="Enter your number"
                      name="phone"
                      onChange={(e) => {
                        loginSignupData.current.login_mobile_number =
                          e.target.value.toLocaleLowerCase();
                        // logFuction();
                      }}
                     
                    />
                  </div>
                  <div class="form-group  logininput1 ">
                  <loading></loading>
                    <input
                      type={isVisible ? "text" : "password"}
                      required
                      class="form-control input_feild py-2"
                      defaultValue={loginSignupData.current.login_password}
                      id="phone"
                      placeholder="Enter your password"
                      name="phone"
                      // maxLength={8}
                      onChange={(e) => {
                        loginSignupData.current.login_password = e.target.value;
                        // logFuction(e);
                      }}
                    />
                    <span
                      class="icon1 d-flex justify-content-center "
                      onClick={toggle}
                    >
                      {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </span>
                  </div>
                  <div>
                    {button1 ? (
                      <span className="fs-12 red">
                        Please Check Your Phone Number or Password
                      </span>
                    ) : (
                      <spna className="fs-12 trans">test</spna>
                    )}
                  </div>
                  <div class="form-group">
                    <button type="submit" name="" class=" btn w-100">
                      Login
                    </button>
                    <a
                      class="text-center last_para ms-auto"
                      onClick={() => setForm(4)}
                    >
                      Forgot Password
                    </a>
                  </div>
                </form>
                <br />
                <br />
              </div>
            </div>
            <div class="last_section d-flex justify-content-center align-items-center">
              <span class="last_para text-white">Don't have an account?</span>
              <a class="signUp_link ps-2" onClick={() => setForm(6)}>
                Sign Up
              </a>
            </div>
          </div>
        );
        break;
      case 4:
        return (
          <div class="container-fluid d-grid" style={{ placeItems: "center" }}>
            <div class=" col-sm-8 col-12">
              <form onSubmit={changePassword} class="form_width">
                <div class="form_container">
                  <h2 class="text-center forget_heading text-white display-6">
                    Forgot Password?
                  </h2>
                  <div class="text-center para mb-5">
                    <p>Confirm your mail ID</p>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="form-group ">
                      <input
                        type="text"
                        class="form-control py-2 pass_feild"
                        placeholder="Email ID"
                        onChange={(e) => {
                          userEmail = e.target.value;
                        }}
                        required="required"
                      />
                    </div>
                    <div>
                      {emailCheck ? (
                        <span className="fs-12 red">
                          Email is Not Registered
                        </span>
                      ) : (
                        <spna className="fs-12 trans">test</spna>
                      )}
                    </div>
                  </div>

                  <div class="row d-flex justify-content-center">
                    <div class="form-group d-flex justify-content-center mb-3">
                      <button type="submit" class="btn btn-1 mb-5 w-100">
                        <div
                          class="v_login"
                          // onClick={(e) => {
                          //   changePassword(e);
                          // }}
                        >
                          Continue
                        </div>
                      </button>
                    </div>
                  </div>

                  <div class="row d-flex justify-content-center">
                    <div class="form-group back_login  px-md-5 d-flex justify-content-center my-2">
                      <span
                        class="text-center mt-5 w-100 user_link"
                        onClick={() => {
                          loginSignupData.current.fullName = "";
                          loginSignupData.current.mobile_number = "";
                          loginSignupData.current.email = "";
                          loginSignupData.current.password = "";
                          loginSignupData.current.login_mobile_number = "";
                          loginSignupData.current.login_password = "";
                          loginSignupData.current.otpss = [];
                          setForm(1);
                        }}
                      >
                        Back to LogIn page
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        );
        break;
      case 5:
        return (
          <div
            class="container-fluid d-grid pt-5"
            style={{ placeItems: "center" }}
          >
            <div class="py-xs-2mt-5 col-sm-9 col-12">
              <div class="py-5">
                <p class="text-center text-white my-5">
                  We have sent a verification mail, containing the change
                  password link on the given E-mail address.
                </p>
              </div>
              <div class="text-center resend_mail_link pt-5 pb-2">
                {/* <span
                  class="w-100"
                  onClick={(e) => {
                    changePassword(e);
                    setShowSendMail(!showSendMail);
                  }}
                >
                  Resend mail
                </span> */}
              </div>
            </div>
          </div>
        );
        break;
      case 6:
        return (
          <div class="container-fluid main_container p-3">
            <div class="row justify-content-center my-5">
              <div class="form_container col-md-8">
                <h1 class="text-center">Sign Up</h1>
                <p class="text-center sub_heading mb-5">
                  Create an account with us
                </p>
                <form onSubmit={getUserOTP}>
                {loader ? (
                    <>
                    <div class="text-center">
                    <CircularProgress></CircularProgress>
                    </div>
                     
                    </>
                  ) : (
                    <>
                  
                    <div class="form-group mb-4">
                      
                    <input
                      type="number"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 10))
                      }
                      class="form-control input_feild py-2 otpStyle"
                      id="phone"
                      placeholder="Enter your number"
                      defaultValue={loginSignupData.current.mobile_number}
                      required
                      name="phone"
                      onChange={(e) => {
                        setShow1(false);
                        loginSignupData.current.mobile_number =
                          e.target.value.toLocaleLowerCase();
                        if (e.target.value.length === 10) {
                          setButton2(false);
                        } else {
                          setButton2(true);
                        }
                      }}
                    
                    />
                    
                     
                  </div>
                  
                    </>
                  )}
                  
                  {show1 ? (
                    <>
                      <div style={{ textAlign: "center", color: "red" }}>
                        <h5>Number already exist</h5>
                      </div>
                      
                    </>
                  ) : (
                    <></>
                  )}
                  {!loader?(<>
                    <div class="form-group">
                        
                        <button
                          type="submit"
                          disabled={button2}
                          class=" btn w-100"
                          onClick={() => {
                            // setForm(7)
                          }}
                        >
                          {" "}
                          Continue
                        </button>
                      </div>
                      </>):<></>}
                 
                          
                </form>
                <br />
                <br />
              </div>
            </div>
            <a
              class="d-flex justify-content-center last_para"
              onClick={() => {
                loginSignupData.current.fullName = "";
                loginSignupData.current.mobile_number = "";
                loginSignupData.current.email = "";
                loginSignupData.current.password = "";
                loginSignupData.current.login_mobile_number = "";
                loginSignupData.current.login_password = "";
                loginSignupData.current.otpss = [];
                setForm(1);
              }}
            >
              Back to LogIn page
            </a>
          </div>
        );
        break;
      case 7:
        return (
          <div class="container-fluid main_container p-3">
            <div class="row justify-content-center my-5">
              <div class="form_container col-md-8">
                <h1 class="text-center">Sign Up</h1>
                <p class="text-center sub_heading mb-5">
                  Create an account with us
                </p>
                <p class="text-center sub_heading2">
                  Enter the 4 digit OTP sent on your number
                </p>
                <div class="my-4">
                  <Otpinput OtpValue={setOTP} VerifyUserOTP={VerifyUserOTP} />
                  <span style={{ color: "red", fontSize: "15px" }}>
                    {wrongOtpError}
                  </span>
                </div>
                <div class="d-flex justify-content-center align-items-center request_otp">
                  <p class="text-white m-0">Didn't receive otp?</p>
                  <a class="ps-2 request_otp" onClick={getUserOTP}>
                    Request again
                  </a>
                </div>
                <br />
                <br />
              </div>
            </div>
            <div class="last_section d-flex justify-content-center align-items-center">
              <a
                class="d-flex justify-content-center last_para"
                onClick={() => {
                  loginSignupData.current.fullName = "";
                  loginSignupData.current.mobile_number = "";
                  loginSignupData.current.email = "";
                  loginSignupData.current.password = "";
                  loginSignupData.current.login_mobile_number = "";
                  loginSignupData.current.login_password = "";
                  loginSignupData.current.otpss = [];
                  setForm(1);
                }}
              >
                Back to LogIn page
              </a>
            </div>
          </div>
        );
        break;
      case 8:
        return (
          <div class="container-fluid main_container p-3">
            <div class="row justify-content-center mb-5">
              <div class="form_container col-md-8">
                <h1 class="text-center">Sign Up</h1>
                <p class="text-center sub_heading mb-2">Welcome!</p>
                <form onSubmit={signUp}>
                  <div class="form-group mb-4">
                    <input
                      type="text"
                      class="form-control input_feild py-2"
                      id="text"
                      defaultValue={loginSignupData.current.fullName}
                      placeholder="Name"
                      required
                      name="text"
                      onChange={(e) => {
                        setShow(false);
                        loginSignupData.current.fullName = e.target.value;
                        signUpButton(e, "name");
                      }}
                    />
                  </div>
                  <div class="form-group mb-4">
                    <input
                      type="email"
                      class="form-control input_feild py-2"
                      id="email"
                      placeholder="E Mail ID"
                      defaultValue={loginSignupData.current.email}
                      required
                      name="email"
                      onChange={(e) => {
                        setShow(false);
                        signUpButton(e, "email");
                        setShow2(false);
                        loginSignupData.current.email = e.target.value;
                      }}
                    />
                  </div>
                  <div
                    class="form-group  logininput1 "
                    style={{ marginBottom: "2px" }}
                  >
                    <input
                      type={isVisible1 ? "text" : "password"}
                      // maxLength={8}
                      required
                      class="form-control input_feild py-3 "
                      id="password"
                      onBlur={passCheck1}
                      defaultValue={loginSignupData.current.password}
                      placeholder="Create Password"
                      // onFocus={passCheckOnEnter1}
                      name="password"
                      onChange={(e) => {
                        setShow(false);
                        loginSignupData.current.password = e.target.value;
                        signUpButton(e, "pass");
                        passCheckOnEnter1();
                      }}
                    />
                    <span class="icon1" onClick={toggle1}>
                      {isVisible1 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </span>
                  </div>
                  {pass1 ? (
                    <span className="fs-12 red">
                      *Minimum lenght of Password should be 8 character
                    </span>
                  ) : (
                    <span className="fs-12 trans">test</span>
                  )}
                  <div class="form-group  logininput1">
                    <input
                      type={isVisible2 ? "text" : "password"}
                      // maxLength={8}
                      required
                      class="form-control input_feild py-2"
                      id="password2"
                      defaultValue={loginSignupData.current.cPass}
                      placeholder="Confirm Password"
                      name="password2"
                      // onFocus={passCheckOnEnter2}
                      // onBlur={passCheck2}
                      onChange={(e) => {
                        setShow(false);
                        loginSignupData.current.cPass = e.target.value;
                        signUpButton(e, "cPass");
                        // passCheckOnEnter2()
                      }}
                    />
                    <span class="icon1" onClick={toggle2}>
                      {isVisible2 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </span>
                  </div>

                  {show ? (
                    <>
                      <div style={{ textAlign: "center", color: "red" }}>
                        <h5 className="pt-2">Password does not match</h5>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {show2 ? (
                    <>
                      <div style={{ textAlign: "center", color: "red" }}>
                        <h5>Email already exist</h5>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <br />
                  <div class="form-group">
                    <button
                      type="submit"
                      name=""
                      class="btn w-100"
                      disabled={button4}
                    >
                      Done
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <a
              class="d-flex justify-content-center last_para"
              onClick={() => {
                loginSignupData.current.fullName = "";
                loginSignupData.current.mobile_number = "";
                loginSignupData.current.email = "";
                loginSignupData.current.password = "";
                loginSignupData.current.login_mobile_number = "";
                loginSignupData.current.login_password = "";
                loginSignupData.current.otpss = [];
                setForm(1);
              }}
            >
              Back to LogIn page
            </a>
          </div>
        );
        break;
      case 9:
        return (
          <div class="container-fluid main_container p-3">
            <div class="row justify-content-center my-5">
              <div class="form_container col-md-8 my-5">
                <div class="tick mb-5 text-center">
                  {/* <img src="/images/charm_circle.png" /> */}
                  <TaskAltIcon sx={{ fontSize: "100px", color: "white" }} />
                </div>
                <h1 class="text-center">Thank You</h1>
                <h1 class="text-center">for signing up with us</h1>
              </div>
            </div>
          </div>
        );
        break;
      case 10:
        return "";
        break;
      case 11:
        return (
          <div
            class="container-fluid d-grid pt-5"
            style={{ placeItems: "center" }}
          >
            <div class="py-xs-2mt-5 col-sm-9 col-12">
              <div class="py-5">
                <p class="text-center text-white my-5">
                  Enter Correct email for change you Password.
                </p>
              </div>
              <div class="text-center resend_mail_link pt-5 pb-2">
                <span class="w-100" onClick={() => setForm(4)}>
                  Back to forget Page
                </span>
              </div>
            </div>
          </div>
        );
        break;
      case 12:
        return (
          <div
            class="container-fluid d-grid pt-5"
            style={{ placeItems: "center" }}
          >
            <div class="py-xs-2mt-5 col-sm-9 col-12">
              <div class="py-5">
                <p class="text-center text-white my-5">Please wait......</p>
              </div>
            </div>
          </div>
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog maxWidth={"sm"} m={0} p={5} fullWidth={true} open={props.open}>
        <div
          className="container-fluid"
          style={{
            background:
              "linear-gradient(90.22deg, #DA7B02 -1.57%, #EEBB2E 99.81%)",
            zIndex: "999999999999999",
          }}
        >
          <div>
            <CloseIcon
              className="close-btn"
              onClick={() => onClickCloseAll()}
            />
          </div>
          <ValUse />
        </div>
      </Dialog>

      <nav
        className="sticky-top py-3 px-md-5 px-sm-1 "
        style={{ background: props.headerColor ? "#0c0c0c" : "#2A2381" }}
      >
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div className="ps-md-4 ps-sm-2 pe-5">
            <img
              src="/images/navbar_logo1.png"
              alt=""
              className="imglogo cursor-pointer"
              onClick={() => {
                sessionStorage.removeItem("filter");
                navigate("/");
              }}
            />
          </div>
          {matches ? (
            <div className="w-75 d-flex justify-content-between ">
              <Grid item className="search_box">
                <input
                  id="search-bar-input"
                  className={`w-100 form-control h-75 ${
                    props.headerColor ? "search_input1" : "search_input2"
                  }`}
                  placeholder="What are you looking for?"
                  onChange={(e) => setText(e.target.value)}
                  // defaultValue={sessionStorage.getItem("search") ? sessionStorage.getItem("search") : text}
                  value={text}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (text.length !== 0) searching();
                    }
                  }}
                />
                <SearchIcon
                  className={
                    props.headerColor ? "search_icon1" : "search_icon2"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => searching()}
                />
              </Grid>
              {props.headerColor ? (
                <div className="w-50 d-flex pe-3 justify-content-end">
                  <div className="text-white d-flex justify-content-end w-75">
                    <span
                      className="nav_text"
                      onClick={() => navigate("/about")}
                    >
                      About Us
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span
                      className="nav_text"
                      onClick={() => navigate("/contactUs")}
                    >
                      Contact Us
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {localStorage.getItem("userId") !== null ? (
            <div
              className="d-flex justify-content-end align-items-center"
              style={{ width: "8%" }}
            >
              {/* cart icon div */}
              <div className="pe-1">
                {/* new code */}
                <Badge badgeContent={props.cartLength} color="primary">
                  <ShoppingCartOutlinedIcon
                    onClick={() => {
                      navigate("/cart");
                      setToggleBar(false);
                    }}
                    // color="action"
                    className="text-white cursor-pointer"
                  />
                </Badge>
              </div>
              &nbsp;&nbsp;
              <AccountCircleIcon
                // sx={{ height: 28, width: 28, color:props.headerColor?'#B5B5B5':'#F1F1F1'  }}
                onClick={() => {
                  handleOpen();
                  setToggleBar("");
                }}
                className={`cursor-pointer ${
                  props.headerColor ? "avatar1" : "avatar2 me-2"
                }`}
                src="https://icons8.com/icon/7820/male-user"
              />
              <div>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open1}
                  onClose={handleClose}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <Box sx={style}>
                    <div
                      className="text-center main-color"
                      onClick={() => {
                        navigate("/accountDetails");
                        handleClose();
                      }}
                    >
                      Profile{" "}
                    </div>
                    <div className="hr"></div>
                    <div
                      className="text-center main-color"
                      onClick={() => {
                        signOut();
                        handleClose();
                      }}
                    >
                      Sign Out
                    </div>
                  </Box>
                </Modal>
              </div>
              {props.headerColor ? (
                !matches ? (
                  <span
                    className="text-white mx-2 border menu_btn"
                    onClick={() => setToggleBar(!toggleBar)}
                  >
                    <MenuIcon />
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="d-flex">
              <button
                className={`btn ${
                  props.headerColor ? "login_btn" : "login_btn1"
                }`}
                onClick={() => {
                  setForm(1);
                  props.setOpen(!props.open);
                }}
              >
                LogIn
              </button>
              <div>
                <ShoppingCartIcon
                  onClick={() => {
                    setForm(1);
                    props.setOpen(!props.open);
                  }}
                  className="text-white cursor-pointer ms-2 mt-2"
                />
              </div>
              {!matches ? (
                <span
                  className="text-white mx-2 border menu_btn"
                  onClick={() => setToggleBar(!toggleBar)}
                >
                  <MenuIcon />
                </span>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        {!matches ? (
          <Grid item px={2} py={1} className="search_box w-100">
            <input
              className={`w-100 form-control h-75 ${
                props.headerColor ? "search_input1" : "search_input2"
              }`}
              placeholder="What are you looking for?"
              onChange={(e) => setText(e.target.value)}
            />
            <SearchIcon
              className={props.headerColor ? "search_icon" : "search_icon3"}
              style={{ cursor: "pointer" }}
              onClick={() => searching()}
            />
          </Grid>
        ) : (
          ""
        )}
        <div
          style={{
            transition: "0.3s",
            overflow: "hidden",
            height: toggleBar ? "100px" : "0px",
          }}
        >
          <div className="text-white px-3 py-2">
            <span
              className="nav_text"
              onClick={() => {
                setToggleBar(false);
                navigate("/about");
              }}
            >
              About Us
            </span>
            <br />
            <br />
            <span
              className="nav_text"
              onClick={() => {
                setToggleBar(false);
                navigate("/contactUs");
              }}
            >
              Contact Us
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};
