import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import style from "./style.module.css";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Carousel from "react-bootstrap/Carousel";
import { useParams, useNavigate } from "react-router";
import { getSingleProduct, addToCart, getCart } from "../../Services/AllAPIs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import capilitizeFirstLetter from "../../Helpers/CapitilizeFirstLetter";
import capitalizeFLOEW from "../../Helpers/CapitalizeFLOEW";
export default function Product(props) {
           

  const [addTooCart, setaddTooCart] = useState(false);

  const [isCartVisible, setisCartVisible] = useState(false);
  const [loader, setLoader] = useState(true);
  const [Products, setProducts] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [loggin, setLoggin] = useState(localStorage.getItem("IsLoggin"));
  console.log(localStorage.getItem("IsLoggin"));
  const { id } = useParams();
  console.log(props.logginCheck);

  let navigate = useNavigate();
  const getProducts = () => {
    getSingleProduct(id)
      .then((res) => {
        console.log("one product detail", res.data.data[0]);
        setProducts(res.data.data[0]);
        setLoader(false);
      })
      .catch((error) => console.log(error));
  };

  const addToCartt = (e) => {
    e.preventDefault();
    localStorage.removeItem("couponMongoId")
    if (loggin === "true" || props.logginCheck) {
      if (productIds.indexOf(Products._id) === -1) {
        setLoader(true);
        addToCart({
          // product_id: "6454a41f0c1904d5fbab95fe",
          product_id: Products._id,
          quantity: 1,
        })
          .then((res) => {
            setisCartVisible(true);
            setLoader(false);
            console.log("product added to cart", res);
            props.changeCartLen((prevVal) => prevVal + 1);
          })
          .catch((error) => {
            console.log(error);
            setLoader(false);
          });
      } else {
        setisCartVisible(true);
      }
    } else {
      console.log("notLoggin");
      props.setOpen(true);
    }
  };
  console.log(productIds, "okoko");
  useEffect(() => {
    getProducts();
    window.scrollTo(0, 0);
    props.setHeaderColor(false);
    setLoggin(localStorage.getItem("IsLoggin"));
    getCart().then((res) => {
      const a = res.data;
      console.log(res.data);
      if (a !== undefined) {
        let result = Array.from(a).map((e) => e.product_id);
        setProductIds(result);
      }
    });
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loader ? (
        // main div
        <div className="container pt-3 pb-4 ">
          {/* home-breadcrumb div */}
          <span className="">
            {" "}
            <span onClick={() => navigate("/")} className={style.text_nav}>
              Home
            </span>{" "}
            <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
            <span
              onClick={() => navigate(`/products/${Products.Model[0]?._id}`)}
              className={style.text_nav}
            >
              {Products.Model[0].model_name}
            </span>
          </span>
          {/* product header div */}
          <Grid container py={2} className="">
            <span className="d-flex my-3">
              <Typography variant="h5" className={style.product_heading}>
                <p className={style.font_family}>{Products.product_name}</p>
                {/* <b>{capitalizeFLOEW (Products.product_name)}</b> */}
              </Typography>
            </span>
          </Grid>
          {/* product detail div */}
          <Grid
            container
            className=""
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* image div */}
            <Grid className="" item md={6} sm={12} xs={12}>
              <div className={style.sliderbox}>
                <Carousel
                  variant="dark"
                  prevIcon={
                    <NavigateBeforeIcon
                      fontSize="large"
                      sx={{ backgroundColor: "black" }}
                    />
                  }
                  nextIcon={
                    <NavigateNextIcon
                      fontSize="large"
                      sx={{ backgroundColor: "black" }}
                    />
                  }
                >
                  {Products.image.map((res) => {
                    return (
                      <Carousel.Item>
                        <img
                          src={res}
                          alt="First slide"
                          className={style.slider_img}
                        />
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            </Grid>
            {/* image-details div */}
            <Grid className=" " item md={6} xs={12} px={4}>
              <Box className=" ">
              <Typography
                  className="pt-1"
                  sx={{ textAlign: "justify", color: "black" }}
                >
                 <p className={style.font_family}>Product Description</p>
                 
                </Typography>
                <Typography
                  className="pt-1"
                  sx={{ textAlign: "justify", color: "black" }}
                >
                  {" "}
                  {Products.product_description}
                </Typography>
              </Box>
              <div className={style.table}>
                <table className="w-100">
                  <tr className=" " style={{ paddingTop: "80px" }}>
                    <td className={style.gray}>Brand:</td>
                    <td className={style.data_table}>
                      {capilitizeFirstLetter(Products.Brand[0].brand_name)}
                    </td>
                  </tr>

                  <tr>
                    <td className={style.gray}>OE Reference No.:</td>
                    <td className={style.data_table}>
                      {Products.oe_reference_number}
                    </td>
                  </tr>
                  <tr>
                    <td className={style.gray}>Part No.:</td>
                    <td className={style.data_table}>
                      {Products.ke_partNumber}
                    </td>
                  </tr>
                  <tr>
                    <td className={style.gray}>MRP:</td>
                    <td className={style.data_table}>₹ {Products.MRP}</td>
                  </tr>
                </table>
              </div>
              {/* button div */}
              <Grid container className="d-flex  pb-5 pt-4 ">
                <Grid className=" " item md={12} xs={12} pb={1}>
                  {isCartVisible && (
                    <button
                      onClick={() => navigate("/cart")}
                      className="btn p-2 w-100"
                      style={{
                        border: "2px solid #DA7B02",
                        borderRadius: "13px",
                      }}
                    >
                      Go to Cart &nbsp;&nbsp;&nbsp;
                      <ShoppingCartIcon />
                    </button>
                  )}
                  {!isCartVisible && (
                    <button
                      className="btn p-2 w-100"
                      style={{
                        border: "2px solid #DA7B02",
                        borderRadius: "13px",
                      }}
                      onClick={addToCartt}
                    >
                      Add to Cart &nbsp;&nbsp;&nbsp;
                      <ShoppingCartIcon />
                    </button>
                  )}
                  {/* old code */}
                  {/* <button
                    className="btn p-2 w-100"
                    style={{
                      border: "2px solid #DA7B02",
                      borderRadius: "13px",
                    }}
                    // onClick={() => setaddTooCart(!addTooCart)}
                    onClick={addToCartt }
                  >
                    {addTooCart ? "Go to Cart" : "Add to Cart"}{" "}
                    <ShoppingCartIcon />
                  </button> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={style.customH}></div>
      )}
    </>
  );
}
