import React, { useEffect, useState } from "react";
import "./Newaddress.css";
import Sidebar from "../Sidebarr/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { editAddress } from "../../Services/AllAPIs";
import { useLocation } from "react-router-dom";
import styles from "./Address.module.css";
import { Backdrop, CircularProgress, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const EditAddress = (props) => {
  const navigate = useNavigate();
  let address = useLocation();

  const obj = {
    bg: "#f1f1f1",
    text: "#2A2381",
  };
  const addressId = address?.state.item._id;
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);


  const [houseNum, setHouseNum] = useState(address?.state.item.house_number);
  const [city, setCity] = useState(address?.state.item.city);
  const [state, setState] = useState(address?.state.item.state);
  const [pincode, setPincode] = useState(address?.state.item.pincode);
  const [country, setCountry] = useState(address?.state.item.country);
  const [username, setUserName] = useState(address?.state.item.name);
  const [loader, setLoader] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [mobilNumber, setMobileNumber] = useState(
    address?.state.item.mobile_number
  );
  const [userAdd, setUserAdd] = useState(props.userAddress);

  const handleEditAddress = (e) => {
    e.preventDefault();
    // setFormSubmitted(true);
    setLoader(true)
    const enteredPin = pincode.trim();
    const isValidPin = /^\d{6}$/.test(enteredPin);
    const isValidnumber = /^\d{10}$/.test(mobilNumber);
    console.log(isValidnumber)

    if (isValidPin && isValidnumber) {
      editAddress({
        address_id: addressId,
        house_number: houseNum,
        city,
        state,
        pincode: enteredPin,
        country,
        name: username,
        mobile_number: mobilNumber,
      })
        .then((res) => {
          console.log("res from edit address", res);
          setLoader(false);
          navigate("/address");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (!isValidnumber) {
        setOpen(true)
        // alert("Please enter 10-digit mobile number");

      } if (!isValidPin) {
        setOpen1(true)
        // 
        // alert("Please enter 6-digit pin code");
      }
    }
  };

  useEffect(() => {
    props.setHeaderColor(false);
  }, []);

  return (
    <>
      <Sidebar two={obj} userAddress={userAdd}>
        {/* <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
         
        >
          <CircularProgress color="inherit" />


        </Backdrop> */}



        {loader ? (
          <div className=" fontFamily container-fluid cont ">
            <div className="row">
              <div className="col">
                <div className="px-5 py-4">
                  <form className="m-5" onSubmit={handleEditAddress}>
                    <div className="row mb-3">
                      <div class="form-group col-md-6 pe-md-3">
                        <label for="inputName" className="pb-1">
                          NAME
                        </label>
                        <input
                          required
                          onChange={(e) => {
                            if (e.target.value == " ") {
                              e.target.value = "";
                            } else {
                              setUserName(e.target.value);
                            }
                          }}
                          type="text"
                          className="form-control px-2 mb-2"
                          id="inputName"
                          placeholder="Enter User Name"
                          defaultValue={address.state.item.name}
                        />
                      </div>
                      <div class="form-group col-md-6 pe-md-3">
                        <label for="inputNumber" className="pb-1">
                          Mobile Number
                        </label>
                        <input

                          required
                          type="number"
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 10))
                          }
                          onChange={(e) => {
                            if (e.target.value == " ") {
                              e.target.value = "";
                            } else {
                              setMobileNumber(e.target.value);
                              setOpen(false)

                            }
                          }}
                          className={open ? "form-control px-2 border border-danger" : "form-control px-2"}
                          id="inputNumber"
                          placeholder="Enter Mobile Number"
                          defaultValue={address.state.item.mobile_number}
                        />
                        {open && <p className="text-danger fs-6"> Please enter 10-digit mobile number</p>}


                      </div>
                    </div>
                    <div className="form-group mb-3 ">
                      <label for="inputAddress" className="pb-1">
                        DELIVERY ADDRESS
                      </label>
                      <input
                        required
                        type="text"
                        onChange={(e) => {
                          if (e.target.value == " ") {
                            e.target.value = "";
                          } else {
                            setHouseNum(e.target.value);
                          }
                        }}
                        className="form-control px-2"
                        id="inputAddress"
                        defaultValue={address.state.item.house_number}
                      />
                    </div>
                    <div className="row mb-3">
                      <div class="form-group col-md-6 pe-md-3">
                        <label for="inputcity" className="pb-1">
                          CITY
                        </label>
                        <input
                          required
                          onChange={(e) => {
                            if (e.target.value == " ") {
                              e.target.value = "";
                            } else {
                              setCity(e.target.value);
                            }
                          }}
                          type="text"
                          className="form-control px-2 mb-2"
                          id="inputcity"
                          defaultValue={address.state.item.city}
                        />
                      </div>
                      <div className="form-group col-md-6 ps-md-3">
                        <label for="inputState" className="pb-1">
                          STATE
                        </label>
                        <input
                          required
                          onChange={(e) => {
                            if (e.target.value == " ") {
                              e.target.value = "";
                            } else {
                              setState(e.target.value);
                            }
                          }}
                          type="text"
                          className="form-control px-2"
                          id="inputState"
                          defaultValue={address.state.item.state}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="form-group col-md-6 pe-md-3">
                        <label for="inputCountry" className="pb-1">
                          COUNTRY
                        </label>
                        <input
                          required
                          onChange={(e) => {
                            if (e.target.value == " ") {
                              e.target.value = "";
                            } else {
                              setCountry(e.target.value);
                            }
                          }}
                          type="text"
                          className="form-control px-2 mb-2"
                          id="inputCountry"
                          defaultValue={address.state.item.country}
                        />
                      </div>
                      <div className="form-group col-md-6 ps-md-3 mb-5">
                        <label for="inputPin" className="pb-1">
                          PIN CODE
                        </label>
                        <input
                          required
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 6))
                          }

                          onChange={(e) => { setPincode(e.target.value); setOpen1(false); }}
                          type="number"
                          className={open1 ? "form-control px-2 border border-danger" : "form-control px-2"}

                          // id="inputPin"
                          defaultValue={address.state.item.pincode}
                        />
                        {open1 && <p className="text-danger fs-6"> Please enter 6-digit pin code</p>}
                      </div>
                    </div>
                    <div
                      className={`d-flex justify-content-end ${styles.editpagebtns}`}
                    >
                      {/* <Link to="/address"> */}
                      <div className={`ms-2 ${styles.calcelbtn}`}>
                        <div className="">

                          <button
                            type="button"
                            className={` ${styles.saveChanges3} px-3 `}
                            onClick={() => navigate("/address")}
                          >
                            CANCEL
                          </button>

                        </div>
                      </div>
                      {/* </Link> */}
                      <div className=" ms-2">
                        <div className="">
                          <button
                            type="submit"
                            className={` ${styles.saveChanges2} px-3`}
                          // onClick={handleEditAddress}
                          >
                            SAVE ADDRESS
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </Sidebar>
    </>
  );
};

export default EditAddress;
