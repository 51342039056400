

import { configureStore } from '@reduxjs/toolkit'
import { ProductFilterSlice } from './Slices/ProductFilterSlice'


export const Store = configureStore({
    reducer: {
        ProductFilter: ProductFilterSlice.reducer
    },
})