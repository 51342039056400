

export const apis = {
  baseUrl: "https://automobile-a6cba9797d33.herokuapp.com/",
    //   baseUrl: "http://localhost:3001/",

    vehicle: {
        add: "vehicle/addVehicle",
        getAll: 'vehicle/get/getAllVehicle',
        get: 'vehicle',
        update: 'vehicle/updateVehicle',
        delete: 'vehicle/deleteVehicle'
    },
    product: {
        add: "category/addCategory",
        getAll: 'category/getAllCategory',
        get: 'category',
        delete: 'category/deleteCategory',
        update: 'category/updateCategory',
    },
    company: {
        add: "brand/addBrand",
        getAll: 'brand/getAllBrand',
        get: 'brand',
        delete: 'brand/deleteBrand',
        update: 'brand/updateBrand',
    },

    model: {
        add: "model/addModel",
        getAll: 'model/getAllModel',
        get: 'model',
        delete: 'model/deleteModel',
        update: 'model/updateModel',
    },
    items: {
        add: 'product/addProduct',
        getAll: 'product/get/All',
        get: 'product/',
        delete:'product/delete/',
        edit:'product/updateProduct/',
        searchData:"product/data/searchProduct"
    },
    manufacture: {
        add: 'manufacturer/addManufacturer/',
        getAll: 'manufacturer/getAllManufacturer',
        get: 'manufacturer/',
        delete:'manufacturer/deleteManufacturer/',
        edit:'manufacturer/updateManufacturer/'
    },
    getAllData:'vehicle/getSegmentBrandModel/all',
    getOnlyProduct:'model/onlyProductData/',
    getSingleProducts:'product/',
    getSegmentBrand:'vehicle/',
    getSegmentModel:'brand/',
    getonlyCategoryManufacturerD:'model/onlyCategoryManufacturerData/',
    applyFil:'model/filter/ProductByModelCatManu',
    applyFil2:'model/filter/Product',


    userOTP:'user/otpp',
    getbymobileNumber:'user/userdetails',
    addOtp:'otp/addOtp',
    verifyOtp:'user/verifyOtp',
    shippingAddress:'payment/shippingAddress',
    signUp:'user/signupUser',
    login:'user/loginWithMobAndPass',
    userDetail:'user/getById/',
    signout:'user/logout/',
    updateUser:'user/updateUser/',
    aboutUs:'aboutus/getAll',
    contact:'admin/contactus',
    privacy:'privacypolicy/getAll',
    term:'termConditon/getAll',
    forget:'user/forgotUserPassword',
    changePass:'user/verifyAndAddCred',
    cart:{
        add:"user/addToCart",
        get:"user/getUserCartData",
        productQuantity:"user/productQuantity",
        removeFromCart:"user/removeToCart",
        getCartLen:"user/getCartLength"

    },
    orderHistory:{
        get:"user/getProductHistory"
    },
    address:{
        add:"user/addAddress",
        get:"user/getAddress",
        delete:"user/deleteAddress",
        edit:"user/editAddress"
    },
    getProfileupdate:"user/updateUser/"
    


}
