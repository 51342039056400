import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./myOrder.css";
import { useLocation, useNavigate } from "react-router";
import { getAddress, getCart, deleteAddress ,addshippingAddress,getSingleProduct} from "../../Services/AllAPIs";

import {
  Backdrop,
  Box,
  CircularProgress,
  Radio,
  Modal,
  Typography,
} from "@mui/material";
import axios from "axios";
import { apis } from "../../Auth/Apis";
import { Link } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";

const style = {
  position: "absolute",
  justifyContent: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  background: "",

  // border:"2px solid red"
};

export const CheckOut = (props) => {
  const [selectedValue, setSelectedValue] = useState("");

  // console.log(selectedValue)

  const handleChangeToggle = (event) => {
    // console.log("radio log")
    console.log("address",event)
    setSelectedAdd(event);
    setSelectedValue(event._id);
  };

  const [payment, setPayment] = useState("");

  const navigate = useNavigate();
  if (localStorage.getItem("IsLoggin") === "false") navigate("/");
  const [addresses, setAddresses] = useState([]);
  const [open, setOpen] = useState(false);

  const [addressLen, setAddressLen] = useState();
  const [cartItems, setCartItems] = useState([]);
  const [selectedAdd, setSelectedAdd] = useState({});
  const [datalist, setDataList] = useState([]);
  const [cod, setCod] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState({});
  const [total, setTotal] = useState(0);

  // console.log(selectedAdd)
  const [isAddressDeleted, setIsAddressDeleted] = useState(false);
  // const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(true);

  // console.log(payment.length)
  function getAddressList() {
    getAddress()
      .then((res) => {
        // console.log(res.data)
        setAddresses(res.data);
        setDataList(res.data);
        setAddressLen(res.data.length);
      })
      .catch((error) => console.log("error is", error));
  }

  function calculateTotal() {
    var gg = 0;
    cartItems.map((res) => {
      gg += res.quantity * res.data[0].MRP;
    });

    if (appliedCoupon) {
      if (gg > appliedCoupon.minimum_order) {
        let per = gg * (appliedCoupon.discount_percentage / 100);
        return Math.round(gg - per);
      } else {
        return Math.round(gg);
      }
    } else {
      return Math.round(gg);
    }
  }

  const deleteAddressHandle = (dataa) => {
    deleteAddress({
      address_id: dataa,
    })
      .then((res) => {
        // console.log("deleted from ", res);
        setIsAddressDeleted(true);
      })
      .catch((error) => console.log("error is", error));
  };

  async function buy() {
    setLoader(true);
    var gg = 0;
    cartItems.map((res) => {
      gg += res.quantity * res.data[0].MRP;
      setQuantity(res.quantity);
    });

    if (appliedCoupon) {
      if (gg > appliedCoupon.minimum_order) {
        let per = gg * (appliedCoupon.discount_percentage / 100);
        gg -= per;
      }
    }

    let obj = {
      paymentMode: payment,
      user: {
        userID: localStorage.getItem("userId"),
      },
      shippingDetails: {
        house_number: selectedAdd?.house_number,
        city: selectedAdd?.city,
        state: selectedAdd?.state,
        country: selectedAdd?.country,
        pincode: selectedAdd?.pincode,
        mobile_number: selectedAdd?.mobile_number,
        name: selectedAdd?.name,
      },
      orderDetails: {
        amount: Math.round(gg) * 100,
        currency: "INR",

        productArray: cartItems.map((res) => {
          return {
            product_id: res.product_id,
            quantity: res.quantity,
            // date: new Date(),
          };
        }),
      },
    };

    if (payment === "online") {
      try {
        let res = await axios.post(
          `${apis.baseUrl}payment/phonepe_payment`,
          obj
        );
        // console.log(res);
        // console.log(amount, order_id)
        window.location.href = res.data.data.url;
        // setLoader(false);
      } catch (err) {
        console.log(err);
      }
    } else {
      await axios
        .post(`${apis.baseUrl}payment/create-order`, obj)
        .then(async (res) => {
          console.log("resPayment",res);
          let cashData = {
            user: {
              userID: localStorage.getItem("userId"),
            },
            paymentDetails: {
              ...res.data.data.order.paymentDetails,
              id: res.data.data.order._id,
            },
            subscriptionData: res.data.data.order.subscriptionData,
          };
          const result = await axios.post(
            `${apis.baseUrl}payment/verifyCash`,
            cashData
          );

{console.log(cashData)}
  

          setLoader(false);
          setOpen(true);
          let array = [
            {
              Name: "xyz",
              SKU: "xrv_dr",
              QTY: "1",
              Amount : "300",
          },
          ]
         let productArray =cartItems.map((item) => {
            return {
              Name: item?.data[0]?.product_name,
              QTY:JSON.stringify(item?.quantity),
              Qty:JSON.stringify(item?.quantity),
              SKU:"xrv_dr",
              Amount: item?.data[0]?.MRP,
              // date: new Date(),
            };
          })
          console.log("Product array Amount"+productArray[0].Amount);
          console.log("Product array Quantity"+productArray[0].QTY);
          console.log("api output",typeof(selectedAdd.name));
          console.log("api output array",typeof(JSON.stringify(array)));
            let shippingDetails1 = {
              paymentStatus:payment,
              customerName:selectedAdd.name,
              addressLine1:`${selectedAdd.house_number} ,${selectedAdd.city},${selectedAdd.state}-${selectedAdd.pincode}`,
              addressLine2:`${selectedAdd.house_number} ,${selectedAdd.city},${selectedAdd.state}-${selectedAdd.pincode}`,
              city: selectedAdd.city,
              state: selectedAdd.state,
              country: selectedAdd.country,
              pinCode: "110018",
              contactCode: "91",
              contact: selectedAdd.mobile_number.toString(),
              productDetails:JSON.stringify(productArray),
              invoiceAmount: gg
             } 


            let shippingDetails=   {
              "paymentStatus":"approved",
             "customerName":"Kuldeep",
             "addressLine1":"12/4 Janakpuri",
             "addressLine2":"12/4 Janakpuri",
             "city": "New York",
             "state": "New York",
             "country": "U.S.A",
             "pinCode": "110018",
             "contactCode": "91",
             "contact": "9717719793",
             "productDetails": [
                 
                       {
                             "Name": "xyz",
                             "SKU": "xrv_dr",
                             "QTY": "12",
                             "Amount" : "1200"
                         }
                 
             ],
             "invoiceAmount": "200"
         
         
         
         
         }
             
             await addshippingAddress(shippingDetails1)
             .then((res) => {
               console.log(res);
               
             }).catch((error) => console.log(error));

          const objj = {
            data: cartItems.map((res) => res.product_id),
          };
          await axios.put(
            `${apis.baseUrl}user/removeToCart/${localStorage.getItem(
              "userId"
            )}`,
            objj
          );
          localStorage.removeItem("couponMongoId");
          setTimeout(() => {
            navigate("/");
            setOpen(false);
            window.location.reload();
          }, 5000);
        });
    }
  }

  const fetchCart = () => {
    getCart()
      .then((res) => {
        // console.log("cart Items from backend ", res.data);
        console.log("cart data",res);
        setCartItems(res.data);

        // console.log(res.data)
        let data = res.data.map((e) => e.data[0]);
        let fill = data.filter(
          (e) => e.cash_on_delivery === undefined || e.cash_on_delivery === "no"
        );
        if (fill.length === 0) {
          setCod(true);
        }
        setLoader(false);
      })
      .catch((error) => console.log("error is", error));
  };
  async function getAppliedCoupon() {
    let couponId = localStorage.getItem("couponMongoId");
    if (couponId) {
      try {
        let res3 = await axios.get(apis.baseUrl + "promo/" + couponId);

        setAppliedCoupon(res3.data.data);
      } catch (er) {
        console.log(er);
      }
    }
  }

  useEffect(() => {
    props.setHeaderColor(false);
    getAddressList();
    window.scrollTo(0, 0);
    fetchCart();
    getAppliedCoupon();
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* main div */}
      {!loader ? (
        <div class=" mContainer container pb-5 ">
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ width: "100%" }}
          >
            {/* <div style={{justifyContent:"center"}}>

              </div> */}
            <Box sx={style}>
              <div
                style={{
                  background: "rgba(23, 25, 73, 1)",
                  height: "50vh",
                  textAlign: "center",
                  width: "100%",
                  padding: "50px",
                }}
              >
                <img
                  src="images/doneOrder.png"
                  alt=""
                  style={{ textAlign: "center" }}
                />
                {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                Text in a modal
              </Typography> */}
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 1, textAlign: "center" }}
                >
                  <h4 style={{ color: "rgba(255, 255, 255, 1)" }}>
                    {" "}
                    Congratulations
                  </h4>
                  <h4 style={{ color: "rgba(255, 255, 255, 1)" }}>
                    Your Order is Placed!
                  </h4>
                </Typography>
              </div>
              <Typography style={{ marginTop: "15px" }}>
                <h5>You can view your order in order history.</h5>
                {/* <h5 >Your order is successfully placed.</h5>  */}
              </Typography>
            </Box>
          </Modal>
          <span className="mt-2">
            <span
              className=" "
              onClick={() => navigate("/cart")}
              style={{ cursor: "pointer" }}
            >
              Cart
            </span>{" "}
            <ArrowForwardIos sx={{ fontSize: "16px" }} />
            <span className="">Checkout</span>
          </span>
          {/* heading */}
          <div class=" pt-5 pb-3 checkout_heading">
            <h2>CHECKOUT</h2>
          </div>
          {/* flex div */}
          <div class="  row checkoutPage">
            {/* order summary div 1 */}
            <div class="order_summary col-lg-4  order-md-2 mb-4 ">
              <ul class="list-group mb-3 border border-dark">
                <h5 class="d-flex justify-content-center align-items-center mt-3">
                  ORDER SUMMARY
                </h5>
                <div class="d-flex justify-content-center align-items-center px-4">
                  <hr class="w-100 my-2" />
                </div>
                {/* cart item details */}
                {cartItems.map((item) => (
                  <li class="list-group-item d-flex justify-content-between px-2 ">
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="d-flex justify-content-center align-items-center  rounded mx-3">
                        <img src={item?.data[0]?.image[0]}></img>
                      </div>
                      <div class="small_para">
                        <small class="my-0 d-block px-2 mb-2  small_para1">
                          {item?.data[0]?.product_name}
                        </small>
                        <small class="d-block px-2 mt-2 text-1">{`Quantity - ${item?.quantity}`}</small>
                      </div>
                    </div>
                    <div class="mx-3">
                      <h6>{`₹ ${item?.data[0]?.MRP * item?.quantity}`}</h6>
                    </div>
                  </li>
                ))}

                {/* <div class="d-flex justify-content-center align-items-center px-4">
                  <hr class="w-100 my-2" />
                </div> */}

                {/* <li class="list-group-item d-flex justify-content-between px-2">
                  <div class="mx-3">
                    <h6 class="mb-3">Subtotal</h6>
                  </div>
                  <div class="mx-3">
                    <h6 class="d-block mb-2">{`₹ ${localStorage.getItem(
                      "totalPrice"
                    )}`}</h6>
                  </div>
                </li> */}
                <div class="d-flex justify-content-center align-items-center px-4">
                  <hr class="w-100 my-2" />
                </div>
                <li class="list-group-item d-flex justify-content-between px-2 no-border">
                  <h5 class="mx-3">TOTAL</h5>
                  <h5 class="mx-3">
                    {" "}
                    {/* {`₹ ${localStorage.getItem("totalPrice")}`} */}₹{" "}
                    {calculateTotal()}
                  </h5>
                </li>
                <div class="d-flex justify-content-center align-items-center px-4">
                  <hr class="w-100 my-2" />
                </div>
                <h5 class="mx-4">Payment Method</h5>
                <div className="d-flex justify-content-around">
                  {cod && (
                    <>
                      <div>
                        <Radio
                          checked={payment === "cash"}
                          onChange={(e) => setPayment(e.target.value)}
                          value={"cash"}
                          name="size-radio-button-demo"
                          size="small"
                        ></Radio>
                        <span style={{ fontSize: "15px" }}>
                          Cash on delivery
                        </span>
                      </div>
                    </>
                  )}
                  <div>
                    <Radio
                      checked={payment === "online"}
                      onChange={(e) => setPayment(e.target.value)}
                      value={"online"}
                      name="size-radio-button-demo"
                      size="small"
                    ></Radio>
                    <span style={{ fontSize: "15px" }}>Online Payment</span>
                  </div>
                </div>
                <br />
                <div class="px-3 mb-4">
                  <button
                    class="btn w-100"
                    disabled={
                      selectedAdd.house_number == undefined ||
                      payment.length === 0
                    }
                    onClick={buy}
                  >
                    PLACE ORDER
                  </button>
                </div>
              </ul>
            </div>
            {/* address div 2 */}
            <div class="col-lg-8 order-md-1  save_address">
              <div className="border border-dark rounded p-5">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="text-uppercase">Saved Address</h3>
                  </div>
                  <div>
                    <Link to="/edit-checkout" state={selectedAdd}>
                      <button
                        disabled={selectedAdd.house_number == undefined}
                        style={{
                          background:
                            selectedAdd.house_number == undefined
                              ? "#f4d377"
                              : "",
                        }}
                        className="bttn"
                      >
                        {" "}
                        Edit
                      </button>
                    </Link>
                  </div>
                </div>
                {/* no address */}
                {addressLen === 0 ? (
                  <div className="pt-2">
                    <h5>No Address Yet!</h5>
                  </div>
                ) : (
                  ""
                )}
                {/* address list */}

                {addressLen !== 0 ? (
                  <>
                    {addresses.map((item) => (
                      <>
                        <div className=" w-200 ">
                          <div className="d-flex  pt-3 pb-2 ">
                            <div className=" my-auto ps-3">
                              
                              <Radio
                                checked={selectedValue === item?._id}
                                onChange={() => {
                                  console.log("address",item)
                                  handleChangeToggle(item)}}
                                value={item?._id}
                                name="radio-buttons"
                              />
                            </div>
                            <div className=" ps-4">
                              <h6 className="text-capitalize">{`${item?.name} ,${item?.mobile_number}`}</h6>
                              <h6 className="text-capitalize">{`${item?.house_number} ,${item?.city}`}</h6>
                              <h6 className="text-capitalize">{`${item?.state} ,${item?.pincode}, ${item?.country} `}</h6>
                            </div>
                          </div>
                        </div>
                        <br></br>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
                {/* add new address */}
                <div className=" d-flex justify-content-xl-end justify-content-center  justify-content-md-end justify-content-sm-center justify-content-col-center  ">
                  <button
                    onClick={() => navigate("/another-checkout", { state: 1 })}
                    className="bttn"
                  >
                    ADD ANOTHER ADDRESS
                  </button>
                  {/* <div className=" ps-2 ">
                    <button
                      className={` ${styles.saveChanges1}`}
                      onClick={() => deleteAddressHandle(item?._id)}
                    >
                      DELETE ADDRESS
                    </button>
                  </div> */}
                </div>
              </div>
            </div>

            {/* address div */}
            {/* old code */}
            {/* <div class="col-lg-8 order-md-1  ">
   
    </div> */}
          </div>
        </div>
      ) : (
        <div className="customH"></div>
      )}
    </>
  );
};
