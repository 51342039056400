import React, { useEffect, useState } from "react";
import "./myOrder.css";
import Sidebar from "../Sidebarr/Sidebar";
import { getOrderHistory } from "../../Services/AllAPIs";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";
import SearchIcon from "@mui/icons-material/Search";
import { Height } from "@mui/icons-material";
import moment from "moment";
export const MyOrder = (props) => {
  const navigate = useNavigate();
  const obj = {
    bg: "#f1f1f1",
    text: "#2A2381",
  };
  const [userAdd, setUserAdd] = useState(props.userAddress);

  const [orderHistory, setorderHistory] = useState([]);
  const [loader, setLoader] = useState(true);
  // console.log("order history in state", orderHistory);

  const fetchOrderHistory = () => {
    getOrderHistory()
      .then((res) => {
        // console.log("res from backend", res);
        setorderHistory(res.data.reverse());
        console.log(res.data);
        setLoader(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchOrderHistory();
    console.log(userAdd);
    // console.log(props.userAddress)
    window.scrollTo(0, 0);
    props.setHeaderColor(false);
  }, []);

  // for no orders
  if (orderHistory.length === 0 || orderHistory.length === undefined) {
    return (
      <>
        <Sidebar three={obj} userAddress={userAdd}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {/* main div */}
          {/* new code */}

          {!loader ? (
            <div className="container pt-3 pb-4 main-container-css ">
              <Box class="d-flex justify-content-center  ">
                <SearchIcon sx={{ fontSize: "90px", color: "#2A2381" }} />
              </Box>
              <Box className="d-flex justify-content-center  ">
                <p className="text fs-4 ">
                  No Order
                  <span className="text fs-4 " style={{ color: "red" }}>
                    {" "}
                    Found!
                  </span>
                </p>
              </Box>
              <Box className=" mx-auto btnn" sx={{ width: "25%" }}>
                <button
                  className=" text btn  goto-home-btn"
                  onClick={() => navigate("/")}
                >
                  Go to Home
                </button>
              </Box>
            </div>
          ) : (
            ""
          )}
        </Sidebar>
      </>
    );
  }
  // for having orders
  else {
    return (
      <>
        <Sidebar three={obj} userAddress={userAdd}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {/* main div */}
          {!loader ? (
            <div className="border1 mb-5 pb-5  ">
              {/* order history header div */}
              <div class="container-fluid py-sm-4 ">
                <div class="row mx-md-5 ">
                  <div class="col product_container">
                    <div class="py-4">
                      <h3 className="order_history">ORDER HISTORY</h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* order details month div */}
              <div class="container-fluid ">
                <div class="row items_container p-2 mx-md-5 ">
                  {/* order history date header */}
                  {/* <h4 >April 2023</h4> */}

                  {/* using map */}
                  {orderHistory.map((order) => (
                    <>
                      <div class="px-3  ">
                        <hr class="hr" />
                      </div>
                      <br />
                      {order.cart.map((item) => (
                        <>
                          {/* order details Inner div */}
                          {/* order details Inner horizontal Line div */}
                          {/* order details Inner cart item div */}
                          <div class="col-md-12 product_details d-flex align-items-center mt-3">
                            <div class="img_box1 d-flex justify-content-center align-items-center ">
                              <img
                                src={
                                  item.ProductData.image
                                    ? item?.ProductData?.image[0]
                                    : ""
                                }
                              ></img>
                            </div>
                            <div class="w-75 d-flex   align-items-center px-3  ">
                              <div class="details">
                                <div class="items_details py-1">Order Id:</div>
                                <div class="items_details">Product Name:</div>
                                <div class="items_details py-1">
                                  Quantity Ordered:
                                </div>

                                <div class="items_details py-1">
                                  Order Placed on:
                                </div>
                                <div class="items_details py-1">
                                  Order Timing:
                                </div>
                                <div class="items_details py-1">
                                  Payment Mode
                                </div>
                                <div class="items_details py-1">Status</div>

                                <div class="items_details">Amount:</div>
                              </div>
                              <div class="ms-md-5">
                                <div class="items_details py-1">
                                  {order?.payment?.paymentDetails?.orderId}

                                  {console.log(order, "check22222")}
                                </div>
                                <div
                                  class="items_details"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {item?.ProductData?.product_name}
                                </div>
                                <div class="items_details py-1">
                                  {item?.Quantity[0]?.quantity}
                                </div>

                                <div class="items_details py-1">
                                  {moment(item?.Quantity[0]?.date).format("LL")}
                                </div>
                                <div class="items_details py-1">
                                  {moment(item?.Quantity[0]?.date).format(
                                    "LT "
                                  )}
                                  {console.log(item?.Quantity[0])}
                                </div>

                                <div class="items_details py-1">
                                  {order?.payment?.paymentMode === "cash"
                                    ? "COD"
                                    : "Online"}
                                </div>

                                <div class="items_details py-1 text-capitalize ">
                                  {
                                    order?.payment?.paymentDetails
                                      ?.paymentStatus
                                  }
                                </div>

                                <div class="items_details">
                                  ₹{" "}
                                  {item?.Quantity[0]?.quantity *
                                    item?.ProductData?.MRP}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                      <div
                        class="items_details"
                        style={{ textAlign: "right", marginTop: "" }}
                      >
                        {/* {console.log(order.payment.paymentDetails.paymentStatus,"ccc")} */}

                        {order?.payment?.paymentDetails?.paymentStatus ===
                        "Pending"
                          ? `Amount Payable :₹ ${order.netprice}`
                          : ` Net Payment :₹ ${order.netprice}`}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="customH"></div>
          )}
        </Sidebar>
      </>
    );
  }
};

{
  /* old code static */
}
{
  /* first item */
}
{
  /* order details Inner div */
}
{
  /* order details Inner horizontal Line div */
}
{
  /* <div class="px-3  ">
                <hr class="hr" />
              </div> */
}
{
  /* order details Inner cart item div */
}
{
  /* <div class="col-md-12 ps-sm-5 product_details d-flex align-items-center ">
                <div class="img_box d-flex justify-content-center align-items-center">
                  <img src="images/img1.png"></img>
                </div>
                <div class="d-flex w-100 justify-content-between align-items-center">
                  <div class="details ms-md-5">
                    <div class="items_details">Product Name:</div>
                    <div class="items_details py-1">Quantity Ordered:</div>
                    <div class="items_details py-1">Order Placed on:</div>
                    <div class="items_details py-1">Order Status:</div>
                    <div class="items_details">Total Amount:</div>
                  </div>
                  <div class="ms-md-5">
                    <div class="items_details">
                      Front Shock Absorber for MG Hector
                    </div>
                    <div class="items_details py-1">02</div>
                    <div class="items_details py-1">10-04-2023</div>
                    <div class="items_details py-1">Dispatched</div>
                    <div class="items_details">₹ 6216.00/-</div>
                  </div>
                </div>
              </div> */
}
{
  /* second item */
}
{
  /* <hr className="mt-3" />
              <div class="col-md-12 ps-sm-5 product_details d-flex align-items-center ">
                <div class="img_box d-flex justify-content-center align-items-center">
                  <img src="images/img1.png"></img>
                </div>
                <div class="d-flex w-100 justify-content-between align-items-center">
                  <div class="details ms-md-5">
                    <div class="items_details">Product Name:</div>
                    <div class="items_details py-1">Quantity Ordered:</div>
                    <div class="items_details py-1">Order Placed on:</div>
                    <div class="items_details py-1">Order Status:</div>
                    <div class="items_details">Total Amount:</div>
                  </div>
                  <div class="ms-md-5">
                    <div class="items_details">
                      Front Shock Absorber for MG Hector
                    </div>
                    <div class="items_details py-1">02</div>
                    <div class="items_details py-1">10-04-2023</div>
                    <div class="items_details py-1">Dispatched</div>
                    <div class="items_details">₹ 6216.00/-</div>
                  </div>
                </div>
              </div> */
}
