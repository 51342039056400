import { useContext, useEffect, useRef, useState } from "react";
import "./ResetPassword.css";
import React from "react";

import { useParams, useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { changePass } from "../../Services/AllAPIs";
import { Dialog, Grid } from "@mui/material";
import { Context } from "../../Services/ProductAPI";
function ResetPassword(props) {
  const { email, token, id } = useParams();
  const navigate = useNavigate();
  const contextData = useContext(Context);
  const [show, setShow] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isVisible1, setVisible1] = useState(false);
  const [btn, setbtn] = useState(true);
  const [checkEmail, setCheckEmail] = useState(false);

  const credentials = useRef({
    pass: "",
    cPass: "",
  });

  const toggle = () => {
    setVisible(!isVisible);
  };
  const toggle1 = () => {
    setVisible1(!isVisible1);
  };

  const checkPass = (e) => {
    if (
      credentials.current.pass.length >= 8 &&
      credentials.current.cPass.length >= 8
    ) {
      setbtn(false);
    } else {
      setbtn(true);
    }
  };
  const changePassAPI = async (e) => {
    e.preventDefault();
    if (credentials.current.pass === credentials.current.cPass) {
      let body = {
        token,
        id,
        password: credentials.current.pass,
        email,
        role: "user",
      };
      await changePass(body)
        .then(() => {
          navigate("/");
        })
        .catch((e) => {
          console.log(e);
          alert(" Token is expair ");
        });
    } else {
      setShow(true);
    }
  };

  const passCheck = () => {
    if (credentials.current.pass.length >= 8) {
      setCheckEmail(false);
    } else {
      setCheckEmail(true);
    }
  };
  const passCheckOn = () => {
    if (credentials.current.pass.length >= 8) {
      setCheckEmail(false);
    }
  };

  useEffect(() => {
    props.setHeaderColor(true);
  }, []);

  return (
    <>
      <Dialog maxWidth={"sm"} m={0} p={5} fullWidth={true} open={true}>
        <div
          className="container-fluid"
          style={{
            overflowY: "hidden",
            background:
              "linear-gradient(90.22deg, #DA7B02 -1.57%, #EEBB2E 99.81%)",
            zIndex: "999999999999999",
          }}
        >
          <div
            class="container-fluid main_container d-grid"
            style={{ placeItems: "center" }}
          >
            <div class=" col-sm-8 col-12">
              <form class="form_width">
                <div class="form_container">
                  <h2 class="text-center forget_heading text-white display-6">
                    Reset Password?
                  </h2>
                  <div class="text-center para mb-5">
                    <p>Confirm your mail ID</p>
                  </div>
                  <div class="row d-flex justify-content-center ">
                    <div class="form-group logininput2 ">
                      <input
                        type={isVisible ? "text" : "password"}
                        // defaultValue={pass}
                        class="form-control py-2 pass_feild"
                        placeholder="New Password"
                        // maxLength={8}
                        onBlur={passCheck}
                        onChange={(e) => {
                          credentials.current.pass = e.target.value;
                          setShow(false);
                          checkPass(e);
                          passCheckOn();
                        }}
                        required="required"
                      />
                      <span class="icon2" onClick={toggle}>
                        {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </span>
                    </div>
                  </div>
                  <div>
                    {checkEmail ? (
                      <span className="fs-12 red">
                        *Minimum lenght of Password should be 8 character
                      </span>
                    ) : (
                      <spna className="fs-12 trans">test</spna>
                    )}
                  </div>
                  <div class="row d-flex mb-3  justify-content-center">
                    <div class="form-group logininput2 ">
                      <input
                        type={isVisible1 ? "text" : "password"}
                        // defaultValue={cPass}

                        // maxLength={8}
                        class="form-control  py-2 pass_feild"
                        placeholder="Confirm Password"
                        onChange={(e) => {
                          credentials.current.cPass = e.target.value;
                          setShow(false);
                          checkPass(e);
                        }}
                        required="required"
                      />
                      <span class="icon2" onClick={toggle1}>
                        {isVisible1 ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </span>
                    </div>
                  </div>
                  {show ? (
                    <>
                      <div style={{ textAlign: "center", color: "red" }}>
                        <h5>Password does not match</h5>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div class="row d-flex justify-content-center">
                    <div class="form-group d-flex justify-content-center mb-3">
                      <button
                        class="btn btn-1 mb-5 w-100"
                        disabled={btn}
                        onClick={(e) => changePassAPI(e)}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>

                  <div class="row d-flex justify-content-center">
                    <div class="form-group back_login  px-md-5 d-flex justify-content-center my-2">
                      <span
                        class="text-center mt-3 w-100 user_link"
                        onClick={() => navigate("/")}
                      >
                        Back to Home Page
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>
      <Grid
        container
        className="first_page  "
        sx={{
          background: `url(${"/images/image2.png"})`,
        }}
      >
        <div className="d-flex flex-column justify-content-between w-100 ">
          <div className="d-flex mt-5  pt-5 justify-content-between">
            <div className="w-50"></div>
            <div className="top_text_box">
              <p className="top_text1">
                Pretium dictum est <br /> sed amet parturient.
              </p>
              <p className="top_text2">
                Pretium dictum est ac sed amet parturient.
              </p>
              <div style={{ color: "white" }}>{contextData.allData.d}</div>
            </div>
          </div>

          <div className="pt-5 px-md-5 ">
            {/* <div className="container px-md-5 ">
              <Grid container className="filter_bg mb-4 pt-2 pb-4 px-5">
                <Typography className="res_text  py-2 text-Ivy">
                  Find the best spare part now
                </Typography>
                <Grid item md={11} xs={12}>
                  <Grid container>
                    <Grid item md={4} xs={12} className="pr_4 pb_1 pb-sm-2">
                      <select
                        onChange={(e) => { filterd(e.target.value.split(",")[0]); setStateData([e.target.value.split(",")[1]]) }}
                        className="select_style"
                      >
                        <option disabled selected className="option_style">
                          Select Vehicle Type
                        </option>
                        {contextData.allData.all?.segmentData.map(
                          (item, index) => (
                            <>
                              <option
                                className="option_style"
                                key={index}
                                value={item._id + "," + item.vehicle_name}
                              >
                                {item.vehicle_name}
                              </option>
                              <hr />
                            </>
                          )
                        )}
                      </select>
                    </Grid>
                    <Grid item md={4} xs={12} className="pr_4 pb_1 pb-sm-2">
                      <select
                        // onClick={()=>setSelectBrand(ss)}
                        onChange={(e) => { filteredModel(e.target.value.split(",")[0]); setSs(1); setStateData([stateDate[0], e.target.value.split(",")[1]]) }}
                        className="select_style"
                        placeholder="Select Company"
                        value={ss !== 1 ? "ss" : ""}
                      >
                        <option value="ss" disabled selected className="option_style">
                          Select Company
                        </option>

                        {selectBrand.map((brnd, index) => (
                          <>
                            <option
                              className="option_style"

                              value={brnd._id + "," + brnd.brand_name}
                              key={index}
                            >
                              {brnd.brand_name}
                            </option>
                            <hr />
                          </>
                        ))}
                      </select>
                    </Grid>
                    <Grid item md={4} xs={12} className="pr_4 pb_1 pb-sm-2">
                      <select
                        className="select_style"
                        onChange={(e) => {
                          setIsbtnDisable(true);
                          setModelId(e.target.value.split(",")[0]);
                          setAa(1);
                          setStateData([stateDate[0], stateDate[1], e.target.value.split(",")[1]])
                        }}
                        value={aa !== 1 ? "aa" : ""}
                      >
                        <option value="aa" disabled selected className="option_style">
                          Select Model Name
                        </option>
                        {selectModel.map((mod, index) => (
                          <>
                            <option
                              className="option_style"
                              key={index}
                              value={mod._id + "," + mod.model_name}
                            >
                              {mod.model_name}
                            </option>
                            <hr />
                          </>
                        ))}
                      </select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={1} xs={12} className="text-center">
                  <Button
                    variant="contained"
                    disabled={!isbtnDisable}
                    onClick={() => {
                      // if (loginCheck === "false") {
                      //   localStorage.setItem("pop", 1)
                      //   window.location.reload()
                      // } else {
                        localStorage.removeItem("pop")

                        navigate(`/products/${ModelId}`,{state:stateDate})
                      // }
                    }}
                    className="search_btn"
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </div> */}
            {/* <div className="text-center">
              <KeyboardDoubleArrowDownIcon
                sx={{ color: "white", height: "20px" }}
              />
            </div> */}
          </div>
        </div>
      </Grid>
    </>
  );
}

export default ResetPassword;
