import "./ForgotPassword.css"
import React from 'react'
import { Dialog, Grid } from "@mui/material";
function ConfirmationPage() {

  return (
    <>
      <Dialog maxWidth={"md"} m={0} p={5} fullWidth={true} open={true}>
        <div
          className="container-fluid"
          style={{
            overflowY:'hidden',
            background:
              "linear-gradient(90.22deg, #DA7B02 -1.57%, #EEBB2E 99.81%)",
            zIndex: "999999999999999",
          }}
        >
         
          <div class="container-fluid main_container d-grid" style={{ placeItems: "center" }}>
            <div class=" col-sm-8 col-12">
            <p className="text-center">Password Changed !</p>
            <p className="text-center">Login With Updated Password</p>
            </div>
          </div>
        </div>
      </Dialog>
      <Grid
        container
        className="first_page  "
        sx={{
          background: `url(${"/images/image2.png"})`,
        }}
      >
        
      </Grid>
    </>
  )
}

export default ConfirmationPage